<div class="bs-virtualcare-services">
  <div class="bs-virtualcare-services-header">
    {{'VIRTUALCARE.HOME.SERVICES_HEADER' | translate}}
  </div>
  <!-- <div class="bs-virtualcare-services-container"> -->
  <div class="bs-virtualcare-services-content d-none d-md-flex">
    <div class="bs-virtualcare-services-content-sidebar" *ngFor="let service of serviceList; let i=index"
      [ngClass]="{'panel-active': service.isActive, 'panel-inactive': !service.isActive}">
      <div id="{{i}}" (click)="onServiceClick(service)" style="height: 100%; width: 100%;">
        <div [ngClass]="{'panel-active__title': service.isActive, 'panel-inactive__title': !service.isActive}">
          {{service.bookingSystemName}}
        </div>
        <div class="panel-active__description" *ngIf="service.isActive">
          <p>{{service.description[0].value}}</p>
        </div>
        <div class="panel-active__button" *ngIf="service.isActive && service.isAvailable">
          <dxp-link [greedy]="false" [inverted]="true" [routerLink]="[service.bookingPath]"
            [queryParams]="{internal : true}">
            {{'VIRTUALCARE.HOME.BOOK_APPOINTMENT' | translate}}
          </dxp-link>
        </div>
        <div *ngIf="service.isActive && !service.isAvailable">
          <img src='/assets/images/coming_soon.png' width="150px">
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- mobile -->
  <div class="bs-virtualcare-services-content d-sm-flex d-md-none">
    <div class="scrolling-wrapper-flexbox">
      <div class="bs-virtualcare-services-content-sidebar" *ngFor="let service of serviceList; let i=index"
        [ngClass]="{'card-active': service.isActive, 'card-inactive': !service.isActive}"
        (click)="onServiceClick(service)">
        {{service.bookingSystemName}}
      </div>
    </div>
    <div class="bs-virtualcare-services-content-service" *ngIf="selectedService">
      <div class="bs-virtualcare-services-content-service__title">
        {{selectedService.bookingSystemName}}
      </div>
      <div class="bs-virtualcare-services-content-service__description">
        {{selectedService.description[0].value}}
      </div>
      <div class="bs-virtualcare-services-content-service__button" *ngIf="selectedService.isAvailable">
        <dxp-link [greedy]="false" [inverted]="true" [routerLink]="[selectedService.bookingPath]"
          [queryParams]="{internal : true}">
          {{'VIRTUALCARE.HOME.BOOK_APPOINTMENT' | translate}}
        </dxp-link>
      </div>
      <div *ngIf="!selectedService.isAvailable">
        <img src='/assets/images/coming_soon.png' width="150px">
      </div>
    </div>
  </div>
</div>
