import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtualcare-services',
  templateUrl: './virtualcare-services.component.html',
  styleUrls: ['./virtualcare-services.component.sass']
})
export class VirtualcareServicesComponent implements OnInit {

  public virtualservices: any[] = [];
  public serviceList: any[] = [];
  public selectedService: any;
  public bookingURL: string;
  public isLoading: boolean = false;
  public program: string;

  constructor(

  ) { }

  ngOnInit(): void {
    this.serviceList = [
      {
        bookingSystemName: "Pharmacy",
        description: [{
          lang: 'en',
          value: "One to one interview between you and the pharmacist, to review current prescription and non-prescription medications, medication interactions, side effect management, and life style modification. You can also book a consult to discuss care options for diabetes management, rheumatology care and more."
        }],
        isAvailable: true,
        isActive: true,
        bookingPath: "/pharmacy/schedule-appointment"
      },
      {
        bookingSystemName: "Bayshore Express",
        description: [{
          lang: 'en',
          value: "Sensors, test strips, glucose meters, readers… so many devices available for checking your glucose levels! Need a refresher on how to use your device, or perhaps it’s your first time and you would like a demonstration? In this live one-on-one video consultation with our Pharmacist or Pharmacy Technician, you will get a demonstration of how your new device works, discuss some key features of the system and have the opportunity to ask questions."
        }],
        isAvailable: true,
        isActive: false,
        bookingPath: "/public/bayshore-express/schedule-appointment"
      },
      {
        bookingSystemName: "Home Care",
        description: [{
          lang: 'en',
          value: "Home Care Over a Phone Call."
        }],
        isAvailable: true,
        isActive: false,
        bookingPath: "/public/home-care/schedule-appointment"
      },
      {
        bookingSystemName: "COVID Assessments",
        description: [{
          lang: 'en',
          value: "COVID Assessments"
        }],
        isAvailable: false,
        isActive: false,
        bookingPath: "/bayshore-express/schedule-appointment"
      },
      {
        bookingSystemName: "Medication Reminders",
        description: [{
          lang: 'en',
          value: "Telephone or Video eVisit from a Bayshore Care Team member to check in on you or your loved one, provide medication reminders, meal inquiries, safety checks and more"
        }],
        isAvailable: false,
        isActive: false,
        bookingPath: "/bayshore-express/schedule-appointment"
      }
    ]

    this.selectedService = this.serviceList[0];
  }


  onServiceClick(service: any) {
    const currentService = this.selectedService;
    currentService.isActive = false;
    service.isActive = true;
    this.selectedService = service
  }

}
