<div class="bs-virtualcare">
  <div class="bs-virtualcare-header">
    <div class="bs-virtualcare-header-logo">
      <img src="/assets/images/wave_logo.svg" class="header-logo-img">
    </div>
    <div class="bs-virtualcare-header-language">
      <app-language-toggle [inverted]="true"></app-language-toggle>
    </div>
  </div>
  <div class="bs-virtualcare-content" >
    <div class="terms-and-condition-content">
      <h1 class="terms-and-condition-h1">{{'VIRTUALCARE.TERMS_AND_CONDITION.TITLE'|translate}}</h1>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_1'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_2'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_3'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_4'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_5'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_6'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_7'|translate">
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_8'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_9'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_10'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_11'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_12'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_13'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_14'|translate"></p>
      <p [innerHTML]="'VIRTUALCARE.TERMS_AND_CONDITION.TERMS_15'|translate"></p>

    </div>
  </div>

  <app-footer></app-footer>
</div>

<div class="bs-virtualcare-floating">
  <div class="bs-virtualcare-floating-banner">
    <dxp-text class="bs-virtualcare-floating-banner__text">{{'VIRTUALCARE.BANNER.BANNER'|translate}}
    </dxp-text>&nbsp;
    <dxp-link [routerLink]="['/virtualcare']" [fragment]="'covid19'" [underline]="true" [greedy]="false"
      [inverted]="true" class="bs-virtualcare-floating-banner__text">
      {{'VIRTUALCARE.BANNER.BANNER_LINK'|translate}}
    </dxp-link>
  </div>

</div>