import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { aws } from '../../../shared/constants/constant';
import { UserService } from '../../../services/user.service';
import { VirtualcareService } from '../../../services/virtualcare.service';
import { UserBookingInformation } from '../../../model/user-booking-information';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, OnChanges {
  @Output()
  public onSwitchHasAccount: EventEmitter<boolean> = new EventEmitter();
  @Output()
  public onPreviousBookingStep: EventEmitter<any> = new EventEmitter();
  @Output()
  public onBookingStepCompleted: EventEmitter<any> = new EventEmitter();
  @Input() logo: string;
  @Input()
  public hasConsent: boolean = false;
  @Input()
  public allowLogin: boolean = true;

  @ViewChild('form', { static: true })
  private form: ElementRef;
  public togglePassword: boolean = false;

  // For appointment and user info store
  appointmentList = {
    "appointmentTypeId": 0,
    "appointmentStartDate": "",
    "notes": ""
  }

  subList = {
    "user": {},
    "appointment": {}
  }

  userList = {
    "firstName": "",
    "lastName": "",
    "email": ""
  }

  public loginInfo = new LoginInfo();
  public usernameError = '';
  public passwordError = '';
  recaptcha: any[];
  selectedTime = "";
  selectedDay = "";
  serviceName = "";

  @Input() selectedProvider: any;
  @Input() selectedService: any;
  @Input() selectedTimeslot: any;
  @Input() instructions: any;
  @Input() isLoading: boolean = false;

  @Input()
  public isFrenchProgram: boolean = false;
  
  public isError: boolean = false;
  public visibilityValue: string = 'password';
  public consentChecked: boolean = false;
  public termsChecked: boolean = true;
  validate: boolean = false;
  public timezone = moment.tz.guess();

  @Output("next") next: EventEmitter<any> = new EventEmitter();
  // @Output("back") back: EventEmitter<any> = new EventEmitter();

  public userRegistration: UserBookingInformation;

  constructor(private authService: AuthService,
    private spinner: NgxSpinnerService, private userService: UserService, private _virtualservice: VirtualcareService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading && changes.isLoading.currentValue) {
      if (this.isLoading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    }
    if (!this.hasConsent) {
      this.consentChecked = true;
    }
  }

  ngOnInit(): void {
    
  }

  registerClick() {
    if (!this.isLoading) {
      this.next.emit(3);
      this.onSwitchHasAccount.emit(false);
    }
  }


  backClick() {
    this.onPreviousBookingStep.emit();
    this.next.emit(2)
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    this.validate = true;
  }

  onLogin(event?) {

    if (event) {
      event.preventDefault();
    }

    sessionStorage.setItem("successMessage", "your booking is confirmed")
    sessionStorage.setItem("confirmation", "We sent you a confirmation email to your email id and you can find your booking details in\n")
    this.appointmentList = {
      "appointmentTypeId": Number(this.selectedService.bookingSystemId),
      "appointmentStartDate": this.selectedTimeslot.format("LL"),
      "notes": this.instructions
    }

    if (this.loginInfo.username && this.loginInfo.password) {
      // this.spinner.show();
      this.isLoading = true;
      this.authService
        .signIn(
          this.loginInfo.username.trim().toLowerCase(),
          this.loginInfo.password
        )
        .subscribe(
          ([user]) => {
            console.log(`User: ${JSON.stringify(user)}`);

            const doesUserHaveCareReceiver = this.userService.doesUserHaveCareReceiver();

            const family = this.userService.getCurrentFamily();
            this.userRegistration = new UserBookingInformation(
              user.attributes.given_name,
              user.attributes.family_name,
              user.attributes.email,
              "",
              "",
              this.timezone,
              doesUserHaveCareReceiver ? family.systems[0].name : undefined,
              doesUserHaveCareReceiver ? family.systems[0].id : undefined,
              this.authService.getCognitoId(),
              this.termsChecked,
              this.consentChecked
            );
            this.onBookingStepCompleted.emit(this.userRegistration);

          },
          error => {

            this.spinner.hide();
            this.isLoading = false;
            switch (error.code) {
              case aws.USER_NOT_FOUND_EXCEPTION:
                this.usernameError = '';
                this.passwordError = 'VIRTUALCARE.LOGIN.WRONG_PASSWORD';
                break;
              case aws.NOT_AUTHORIZED_EXCEPTION:
              case aws.UNEXPECTED_LAMBDA_EXCEPTION:
                this.usernameError = '';
                this.passwordError = 'VIRTUALCARE.LOGIN.WRONG_PASSWORD';
                break;
              default:
                this.usernameError = '';
                this.passwordError = 'VIRTUALCARE.LOGIN.WRONG_PASSWORD';
            }
          })
    } else {
      this.usernameError = this.loginInfo.username ? '' : 'VIRTUALCARE.LOGIN.NO_EMAIL';
      this.passwordError = this.loginInfo.password ? '' : 'VIRTUALCARE.LOGIN.NO_PASSWORD';
      if (this.usernameError) {
        this.goToUsername();
      } else if (this.passwordError) {
        this.goToPassword();
      }
    }

  }

  visibilityPassword() {
    (this.visibilityValue == 'text') ? this.visibilityValue = 'password' : this.visibilityValue = 'text';
  }


  private goToUsername() {
    this.form.nativeElement.email.focus();
  }

  private goToPassword() {
    this.form.nativeElement.password.focus();
  }

  getConsentValue(event:boolean){
    this.consentChecked = event;
  }
  
}
class LoginInfo {
  constructor(public username?: string, public password?: string) { }
}
