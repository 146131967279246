import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.sass']
})
export class AccessibilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
