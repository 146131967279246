import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

    static validateEmails(c: FormControl) {
        const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        let inValid = null;
        c.value.forEach((item) => {
            if (!EMAIL_REGEXP.test(item)) {
                inValid = { email: true };
            }
        });
        return inValid;
    }

    static validateRequired(control: FormControl) {
        const isWhitespace = (control.value || "").trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { "isRequired": true };
    }

    public static dateRangeValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        const form = control.root;

        const startDate = form.get('startDateTime');
        const endDate = form.get('endDateTime');

        if (startDate && endDate && startDate.value && endDate.value) {
            const startMoment = moment(new Date(startDate.value));
            const endMoment = moment(new Date(endDate.value));

            if (endMoment.isBefore(startMoment)) {
                endDate.setErrors({ invalidDateRange: true })
                return { invalidDateRange: true };
            } else {
                return null;
            }
        }

        return null;
    }

    public static phoneNumberValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        //const form = control.root;
        //console.log(control.value);
        const invalid = { invalidPhoneNumber: true };
        const val = control.value as string;
        console.log(val.length);
        if (val.includes("_")) {
            return invalid;
        }

        return null;
    }
}