import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProviderScheduleAppointmentComponent } from './intake-pathways/provider-initiated/provider-schedule-appointment/provider-schedule-appointment.component';
import { PharmacyScheduleAppointmentComponent } from './intake-pathways/pharmacy/pharmacy-schedule-appointment/pharmacy-schedule-appointment.component';
import { PublicScheduleAppointmentComponent } from './intake-pathways/public/public-schedule-appointment/public-schedule-appointment.component';
import { VirtualCareComponent } from './virtual-care.component';
import { ServiceListComponent } from './intake-pathways/service-list/service-list.component';
import { RescheduleComponent } from './intake-pathways/reschedule/reschedule.component'

const routes: Routes = [
  // { path: '', component: VirtualCareComponent },
  {
    path: '',
    component: VirtualCareComponent,
    children: [
      { path: 'public', redirectTo: 'public/schedule-appointment', pathMatch: 'full' },
      { path: 'public/schedule-appointment', component: PublicScheduleAppointmentComponent },
      { path: 'public/:programRoute', redirectTo: 'public/:programRoute/schedule-appointment', pathMatch: 'full' },
      { path: 'public/:programRoute/schedule-appointment', component: PublicScheduleAppointmentComponent },
      { path: 'public/:programRoute/schedule-appointment/:bookingUrl', component: PublicScheduleAppointmentComponent },
      { path: 'provider', redirectTo: 'provider/schedule-appointment', pathMatch: 'full' },
      { path: 'provider/schedule-appointment', component: ProviderScheduleAppointmentComponent },
      { path: 'provider/:programRoute', redirectTo: 'provider/:programRoute/schedule-appointment', pathMatch: 'full' },
      { path: 'provider/:programRoute/schedule-appointment', component: ProviderScheduleAppointmentComponent },

      { path: 'pharmacy', redirectTo: 'pharmacy/schedule-appointment', pathMatch: 'full' },
      { path: 'pharmacy/schedule-appointment', component: PharmacyScheduleAppointmentComponent },
      { path: 'pharmacy/:programRoute', redirectTo: 'pharmacy/:programRoute/schedule-appointment', pathMatch: 'full' },
      { path: 'pharmacy/:programRoute/schedule-appointment', component: PharmacyScheduleAppointmentComponent },
      { path: 'services', component: ServiceListComponent },
      { path: 'reschedule-appointment', component: RescheduleComponent }


    ]
  }
];


// { path: 'schedule-appointment/:bookingUrl', component: ScheduleAppointmentComponent },

// { path: 'pharmacy', redirectTo: 'pharmacy/schedule-appointment', pathMatch: 'full' },
// { path: 'pharmacy/schedule-appointment', component: PharmacyScheduleAppointmentComponent },

// { path: 'provider', redirectTo: 'provider/schedule-appointment', pathMatch: 'full' },
// { path: 'provider/schedule-appointment', component: ProviderScheduleAppointmentComponent },// keep this above :program or angular will take that as a param


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VirtualCareRoutingModule { }
