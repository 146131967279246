import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy, isDevMode } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { VirtualcareService } from '../../../services/virtualcare.service';
import { ReCaptchaConfig } from '../../../shared/constants/constant';
import { UserBookingInformation } from '../../../model/user-booking-information';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment, environmentConfig, blacklisted } from '../../../../environments/environment';
import { TimezoneService } from '../../../services/timezone.service';
import * as moment from 'moment-timezone';
import { CustomValidators } from '../../../shared/validators/custom-validators';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass']
})
export class RegistrationComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public allowLogin: boolean = true;
  @Input()
  public hasConsent: boolean = false;
  @Input()
  public isDuplicate: boolean = false;
  @Input()
  public isLoading: boolean = false;
  @Input() selectedProvider: any;
  @Input() selectedService: any;
  @Input() selectedTimeslot: Moment;
  @Input() logo: string;

  @Input()
  public isFrenchProgram: boolean = false;
  
  @Output("back") back: EventEmitter<any> = new EventEmitter();

  @Output("next")
  next: EventEmitter<any> = new EventEmitter();
  @Output()
  public onPreviousBookingStep: EventEmitter<any> = new EventEmitter();
  @Output()
  public onBookingStepCompleted: EventEmitter<any> = new EventEmitter();

  @Output()
  public onSwitchHasAccount: EventEmitter<boolean> = new EventEmitter();

  public appointmentList = {
    "appointmentTypeId": 0,
    "appointmentStartDate": "",
    "notes": ""
  }

  public subList = {
    "user": {},
    "appointment": {}
  }

  public recaptchaConfig: ReCaptchaConfig = {
    theme: "light",
    type: "image",
    size: "compact"
  };
  public recaptcha: any[];
  public isRecaptchaVisible: boolean = true;

  public submitList: any = [];
  public startDate = "";
  //public validate: boolean = false;
  public selectedTime = "";
  public selectedDay = "";
  public serviceName = "";
  public termsChecked: boolean = false;
  public consentChecked: boolean = false;
  public termsUrl:string = 'https://wave.mybayshorecare.ca/terms-and-conditions';

  public phoneMask: Array<string | RegExp>;

  public userRegistration: UserBookingInformation;
  private timezone = moment.tz.guess();
  public timezones = [];

  form = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern("[a-zA-Z][a-zA-Z ]*")]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern("[a-zA-Z][a-zA-Z ]*")]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
    phoneNumber: new FormControl('', [Validators.required, CustomValidators.phoneNumberValidator]),
    languagePreference: new FormControl('en'),
    recaptchaReactive: new FormControl(null, Validators.required),
    timezone: new FormControl(this.timezone, Validators.required)
  });

  get f() {
    return this.form.controls;
  }


  constructor(
    private _virtualservice: VirtualcareService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private timezoneService: TimezoneService
  ) { }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //if (changes.isLoading && changes.isLoading.currentValue) {
    if (this.isLoading) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    //}
    if (!this.allowLogin) {
      this.termsChecked = true;
    }
    if (!this.hasConsent) {
      this.consentChecked = true;
    }
  }

  ngOnInit(): void {
    this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const automationParam = this.route.snapshot.queryParamMap.get('automation');
    if (automationParam && environment.enableAutomation) {
      this.isRecaptchaVisible = false;
      this.form = new FormGroup({
        firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern("[a-zA-Z][a-zA-Z ]*")]),
        lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern("[a-zA-Z][a-zA-Z ]*")]),
        email: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
        phoneNumber: new FormControl('', [Validators.required, CustomValidators.phoneNumberValidator]),
        languagePreference: new FormControl('en'),
        timezone: new FormControl(this.timezone, Validators.required)
      });

    }

    this.timezones = this.getAllTimezones();
    this.termsUrl = environmentConfig.termsUrl;
  }

  public getAllTimezones(): { name: string, value: string }[] {
    const timezones = this.timezoneService.getAllTimezones();
    if(this.allowLogin){
      this.form.get('timezone').setValue(this.timezone);
    } else{
      this.form.get('timezone').setValue(timezones[0].value);
    }
    this.form.get('timezone').updateValueAndValidity();
    return timezones;
  }

  Submit() {
    if (this.validateEmail(this.form.value.email)) {
      this.form.controls['email'].setErrors({'pattern': true});
      return;
    }
    this.userRegistration = UserBookingInformation.fromFormValue(
      this.form.value
    );
    this.userRegistration.termsChecked = this.termsChecked;
    this.userRegistration.consentChecked = this.consentChecked;
    this.onBookingStepCompleted.emit(this.userRegistration);

  }

  checkValue() {
    this.termsChecked = !this.termsChecked;
  }

  loginClick() {
    this.next.emit(4)
    this.onSwitchHasAccount.emit(true);
  }

  backClick() {
    this.onPreviousBookingStep.emit();
    this.back.emit(2);
  }

  getConsentValue(event:boolean){
    this.consentChecked = event;
  }

  getTermsValue(event:boolean){
    this.termsChecked = event;
  }
  validateEmail(email: any) {
    const spamDomains = blacklisted.domain || [];
    const emailDomain = email.split('@')[1];
    return spamDomains.includes(emailDomain);
  }
}
