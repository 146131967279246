import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import timezoneConfig from '../config/timezone.config';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService extends BaseService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  //  public getTimezoneFromCity(city: string): string {
  //   let timezone: string;
  //   const url = ""
  //   this.http.get(`https://api.ipgeolocation.io/timezone?apiKey=${TIMEZONE_API_KEY}&location=${city},CA`)

  //   return timezone;
  //  }

  public getAllTimezones(): { name: string, value: string }[] {
    return timezoneConfig.zones.map(tz => {
      return {
        name: `${tz.abbreviation} (UTC ${tz.gmtOffset})`,
        value: tz.zoneName
      };
    });
  }
}

