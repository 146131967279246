<div class="provider-services-mobile-instruction" *ngIf="!selectedService">
  <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</p>
  <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</p>
  <img src="/assets/images/component.png">
</div>
<div class="provider-services-container" *ngIf="!selectedService">
  <div class="provider-services-container-content">
    <div class="provider-services-container-content-providers">
      <div class="provider-services-container-content-providers-header">
        <div class="loader" *ngIf="isLoading">
          <img src="/assets/images/custom-spinner.svg">
        </div>
        <div class="provider-services-container-content-providers-header-title">
          {{'VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER' | translate}}
        </div>
        <div id="wave_provider_{{i}}" *ngFor="let provider of providerList; let i = index"
          (click)="showServicesForProvider(provider)" class="provider-services-container-content-providers-row"
          [ngClass]="{'active': provider.id==selectedProvider?.id}">
          <div class="provider-services-container-content-providers-row-title">
            <div class="provider-services-container-content-providers-row-title__icon">
              {{provider.firstName?.toUpperCase().slice(0, 1)}}{{provider.lastName?.toUpperCase().slice(0, 1)}}
            </div>
            <div class="provider-services-container-content-providers-row-title__text">
              <div class="provider-services-container-content-providers-row-title__text-name">
                {{provider.firstName}}
              </div>
              <div class="provider-services-container-content-providers-row-title__text-specialty">
                {{provider.specialty}}</div>
            </div>
          </div>
          <div class="provider-services-container-content-providers-mobile__desc" *ngIf="provider === selectedProvider">
            <h3>{{virtualServiceList[0]?.displayName[0].value}}</h3>
            <p> {{virtualServiceList[0]?.description[0].value || defaultServiceDescription}}</p>
            <button id="mob_btn_select_provider" class="btn schedule-btn float-left"
              (click)="completeBookingStep()">{{'VIRTUALCARE.TIME_SELECTION.CHOOSE_TIME' | translate}}</button>
          </div>
        </div>
      </div>
      <div class="provider-services-container-content-providers-description">
        <div class="{{displayFirstSection}}">
          <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</p>
          <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</p>
          <img src="/assets/images/component.png">
        </div>
        <div class="{{displaySecondSection}}">
          <h3>{{virtualServiceList[0]?.displayName[0].value}}</h3>
          <p> {{virtualServiceList[0]?.description[0].value || defaultServiceDescription}}</p>
          <button id="btn_select_provider" class="btn schedule-btn float-right"
            (click)="completeBookingStepProvider()">{{'VIRTUALCARE.TIME_SELECTION.CHOOSE_TIME' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Provider list without sub category -->
<div class="provider-container" *ngIf="selectedService">
  <div class="provider-container-content">
    <div class="loader" *ngIf="isLoading">
      <img src="/assets/images/custom-spinner.svg">
    </div>
    <div class="provider-container-content-header">
      <h3>{{'VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER' | translate}}</h3>
      <div class="provider-container-content-header-logo" *ngIf="logo">
        <img src="{{logo}}">
      </div>
    </div>
    <div class="provider-container-content-providers">
      <div id="wave_provider_{{i}}" *ngFor="let provider of providerList; let i = index"
        class="provider-container-content-providers-row" (click)="selectProvider(provider)"
        [ngClass]="{'active': provider.id==selectedProvider?.id}">
        <div class="provider-container-content-providers-row-title">
          <div class="provider-container-content-providers-row-title__icon">
            {{provider.firstName.slice(0, 1)}}{{provider.lastName.slice(0, 1)}}
          </div>
          <div class="provider-container-content-providers-row-title__text">
            <div class="provider-container-content-providers-row-title__text-name">{{provider.firstName}}</div>
            <div class="provider-container-content-providers-row-title__text-specialty">{{provider.specialty}}</div>
            <div class="provider-container-content-providers-row-title__text-desc">{{provider.description}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="provider-container-nextstep">
    <div class="provider-container-nextstep-service">
      Service : <span>{{selectedService.bookingSystemName}}</span>
    </div>
    <div class="provider-container-nextstep-buttons">
      <button id="btn_select_provider" class="btn schedule-btn float-right" [disabled]="!selectedProvider"
        (click)="completeBookingStep()">{{'VIRTUALCARE.TIME_SELECTION.CHOOSE_TIME' | translate}}</button>
      <button id="btn_back" class="back float-right"
        (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
    </div>
  </div>
</div>
