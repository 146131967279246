<div class="bs-virtualcare-covid19-awareness">
  <!-- <div style="width: 100%">
    <div class="bs-virtualcare-covid19-awareness-banner">
      <app-text class="bs-virtualcare-covid19-awareness-banner__text">{{'VIRTUALCARE.HOME.BANNER'|translate}}</app-text>
      &nbsp;
      <app-link href="www.google.com" [underline]="true" [greedy]="false"
        class="bs-virtualcare-covid19-awareness-banner__text">
        {{'VIRTUALCARE.HOME.BANNER_LINK'|translate}}
      </app-link>
    </div>
  </div> -->
  <div class="bs-virtualcare-covid19-awareness-header">
    {{'VIRTUALCARE.COVID19_AWARENESS.HEADER' | translate}}
  </div>
  <div class="bs-virtualcare-covid19-awareness-content">
    <div class="bs-virtualcare-covid19-awareness-content-panel">
      <div class="bs-virtualcare-covid19-awareness-content-panel__virtualcare">
        {{'VIRTUALCARE.COVID19_AWARENESS.HEALTH_AND_SAFETY' | translate}}
      </div>
      <div class="bs-virtualcare-covid19-awareness-content-panel__virtualcare">
        {{'VIRTUALCARE.COVID19_AWARENESS.SELF_ASSESSMENT_TOOL' | translate}}
      </div>
      <div class="bs-virtualcare-covid19-awareness-content-panel__link">
        <dxp-link href="{{'VIRTUALCARE.COVID19_AWARENESS.COVID19_LINK' | translate}}" [underline]="true"
          [greedy]="false" class="bs-virtualcare-covid19-awareness-content-panel__link-text">
          {{'VIRTUALCARE.COVID19_AWARENESS.COVID19_LINK' | translate}}
        </dxp-link>

      </div>
    </div>
    <div class="bs-virtualcare-covid19-awareness-content-logo d-none d-lg-block">
      &nbsp;
    </div>
  </div>
</div>