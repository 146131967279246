import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VirtualcareService } from '../../../services/virtualcare.service';
import { VirtualServiceType } from '../../../enum/virtual-service-type.enum';
import { Location } from '@angular/common';
import { IGroupedCategory, IGroupedVirtualService, IVirtualservice } from '../../../model/virtualservice';
import { ActivatedRoute, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-bayshore-services',
  templateUrl: './bayshore-services.component.html',
  styleUrls: ['./bayshore-services.component.sass']
})
export class BayshoreServicesComponent implements OnInit, OnChanges {
  @Input()
  public serviceType: VirtualServiceType;
  @Input()
  virtualServices: IVirtualservice[] = [];
  @Input()
  public isLoading: boolean = false;
  @Input()
  public displayCost: boolean = true;
  @Input()
  public nextStepName: string;
  @Input()
  public serviceGroup: IVirtualservice;
  @Input()
  public logo: string;
  @Input()
  public isInternal: boolean = false;
  @Input()
  public isIcs: boolean = false;

  public groupedVirtualServices: IGroupedVirtualService[] = [];
  public selectedServiceGroup: IGroupedVirtualService;
  public stepperList: any[] = [];
  public selectedService: IVirtualservice;

  public hasCategory: Boolean = false;

  public showProvince: Boolean = false;
  public selectedProvince: any;

  openToggle: boolean = false;
  serviceId: string;
  description = "";
  serviceName = "";
  testDate = "";
  duration = "";
  bookingSystemId = "";

  //For Mobile Screen
  displayHeading = "d-block";
  displayTitle = "d-none";
  spinnerStatus = "d-block";
  displayFirstSection = "first-section";
  displaySecondSection = "second-section d-none";
  displaySecondSectionGroup = "second-section-group d-none";

  @Output("next") next: EventEmitter<any> = new EventEmitter();
  @Output()
  onBookingStepCompleted: EventEmitter<any> = new EventEmitter();
  @Output() onSelectionComplete: EventEmitter<any> = new EventEmitter();

  constructor(
    private _virtualservice: VirtualcareService,
    private location: Location


    // private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if (!this.nextStepName) {
      this.nextStepName = 'VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER';
    }


  }

  ngOnChanges(changes: SimpleChanges) {

    this.spinnerStatus = "d-none";
    if (this.virtualServices?.length > 0) {
      this.hasCategory = this.determineCategory(this.virtualServices[0]);
      if (this.hasCategory) {
        this.groupedVirtualServices = this.groupServicesByCategory(this.virtualServices);
      }
    }

    if (this.serviceGroup) {
      this.fromLandingPage(this.serviceGroup);
    }

  }


  previousPage() {
    this.location.back()
  }

  fromLandingPage(service: IVirtualservice) {
    this.selectedServiceGroup = this.groupedVirtualServices.find(v => v.bookingSystemName === service.bookingSystemName);
    this.displayFirstSection = "first-section d-none";
    this.displaySecondSection = "second-section";
    this.displaySecondSectionGroup = "second-section-group";
  }

  openProvince() {
    this.showProvince = true;
  }

  backClick() {
    this.showProvince = false;
  }

  private determineCategory(service: IVirtualservice): Boolean {
    if (service.categoryType) {
      return true;
    } else {
      return false;
    }
  }

  private groupServicesByCategory(services: IVirtualservice[]) {
    let groupedServices: IGroupedVirtualService[] = [];

    //groupedServices = groupBy(services, s => s.bookingSystemName);
    let serviceNames: Set<string> = new Set([...services.map(s => s.bookingSystemName)]);
    serviceNames.forEach(name => {
      const tempServices = services.filter(s => s.bookingSystemName === name);
      const tempService = tempServices[0];

      const final: IGroupedVirtualService = {
        bookingSystemName: tempService.bookingSystemName,
        displayName: tempService.displayName,
        description: tempService.description,
        duration: tempService.duration,
        price: tempService.price,
        categoryType: tempService.categoryType,
        categories: tempServices.map(temp => {
          const cat: IGroupedCategory = {
            bookingSystemId: temp.bookingSystemId, category: temp.category
          };
          return cat;
        })
      };
      groupedServices = groupedServices.concat(final);
    });

    return groupedServices;
  }

  public selectServiceGroup(virtual: IGroupedVirtualService) {
    this.selectedServiceGroup = virtual;
    this.displayFirstSection = "first-section d-none";
    this.displaySecondSection = "second-section";
    this.displaySecondSectionGroup = "second-section-group";
    if (this.selectedProvince) {
      this.selectedProvince = null;
    }
    this.stepperList.length === 0 ? this.stepperList.push(virtual.bookingSystemName) : this.stepperList.splice(0, 1, virtual.bookingSystemName);
  }

  public serviceSelection(virtual: IVirtualservice) {

    this.displayFirstSection = "first-section d-none";
    this.displaySecondSection = "second-section";
    this.displaySecondSectionGroup = "second-section-group";
    this.serviceId = virtual.bookingSystemId;
    this.description = virtual.description[0].value;
    this.serviceName = virtual.displayName[0].value;
    this.duration = virtual.duration;
    this.bookingSystemId = virtual.bookingSystemId.toString();

    this.selectedService = virtual;
    this.stepperList.length === 0 ? this.stepperList.push(virtual.bookingSystemName) : this.stepperList.splice(0, 1, virtual.bookingSystemName);
  }

  changeServiceInMobile(virtual) {
    this.displayHeading = "d-none"
    this.displayTitle = "d-block"
    this.serviceId = virtual.bookingSystemId;
    this.description = virtual.description[0].value;
    this.serviceName = virtual.displayName[0].value;
    this.duration = virtual.duration;
  }

  completeBookingStep() {

    if (this.selectedService) {
      this.onSelectionComplete.emit(this.stepperList);
      this.onBookingStepCompleted.emit(this.selectedService)
    } else {
      alert('Please Choose a Service');
    }
  }

  public onCategoryClick(category: IGroupedCategory) {
    this.stepperList.length === 1 ? this.stepperList.push(category.category) : this.stepperList.splice(1, 1, category.category);
    this.selectedProvince = category.category;
    this.selectedService = this.virtualServices.find(s => s.bookingSystemId === category.bookingSystemId);

    this.serviceId = this.selectedService.bookingSystemId;
    this.description = this.selectedService.description[0].value;
    this.serviceName = this.selectedService.displayName[0].value;
    this.duration = this.selectedService.duration;
    this.bookingSystemId = this.selectedService.bookingSystemId.toString();
  }
}

