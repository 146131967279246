<div class="bs-language-toggle">
  <ng-container>
    <i class="material-icons bs-language-toggle-icon" [class.bs-inverted]="inverted">
      language
    </i>
    <dxp-button [underline]="true" [link]="true" (click)="changeLanguage()" [class.bs-inverted]="inverted" class="bs-language-toggle-link">
      {{(language === Language.English ?
      'VIRTUALCARE.FRENCH' :
      'VIRTUALCARE.ENGLISH') | translate}}
    </dxp-button>
  </ng-container>
</div>
