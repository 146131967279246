import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../shared/services/language.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.sass']
})
export class TermsAndConditionsComponent implements OnInit {

  public languageString:string;

  constructor(private languageService: LanguageService) { 
    this.languageString = languageService.getLanguage();
    this.languageService.onLanguageChanged.subscribe(
      language => {
        this.languageString = language;
      }
    );
  }

  ngOnDestroy() {  
    this.languageService.onLanguageChanged.unsubscribe();  
  } 

  ngOnInit(): void {
  }

}
