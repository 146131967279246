<div class="row ">
  <div class="col-md-12">
    <div class="success-container mt-3">
      <div class="success-inner text-center" *ngIf="!providerFlow && !isOtherFlow">
        <img src="/assets/images/done.png" class="">
        <h3>{{'VIRTUALCARE.SUCCESS.THANK'|translate}} {{userName}}, {{successMessage | translate}}</h3>

        <div class="hr"> </div>

        <p>{{confirmation | translate}}
          <a class="home-link" (click)="toHomePage()"> MyBayshoreCare.ca</a>
        </p>
      </div>
      <div class="success-inner text-center" *ngIf="providerFlow">
        <img src="/assets/images/done.png" class="">
        <h3 *ngIf="!isOtherFlow">{{'VIRTUALCARE.SUCCESS.THANK'|translate}} {{providerName}}, {{userName}}'s {{successMessage | translate}}
        </h3>
        <div class="hr"> </div>

        <p *ngIf="!isOtherFlow">{{confirmation | translate}} {{providerName}} & {{userName}}'s email id.
          {{'VIRTUALCARE.SUCCESS.PROVIDER_END'|translate}}
          <a class="home-link" (click)="toHomePage()"> MyBayshoreCare.ca</a>
        </p>
      </div>
      <div class="success-inner text-center" *ngIf="isOtherFlow">
        <img src="/assets/images/done.png" class="">
        <h3 > {{successMessage}}
        </h3>
        <div class="hr"> </div>

        <p >
          <a class="home-link" (click)="toHomePage()"> MyBayshoreCare.ca</a>
        </p>
      </div>
    </div>
  </div>
</div>
