export class Provider {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public specialty: string,
        public description: string
    ) { }

    public static fromJson(obj: any): Provider {
        if (!obj) {
            return undefined;
        }
        return new Provider(
            obj.id,
            obj.firstName,
            obj.lastName,
            obj.specialty,
            obj.description
        );
    }
};
