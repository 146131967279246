import { Contact } from './contact';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Systems } from '../enums/systems.enum';

export class Account {
  constructor(
    public accountId?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public phone?: string,
    public enabled?: boolean,
    public language?: string,
    public postalCode?: string,
    public birthday?: Moment,
    public contacts?: Array<Contact>,
    public systems?: Systems[]
  ) { }

  static fromJson(json: any) {
    if (!json) {
      return undefined;
    }
    return new Account(
      json.accountId,
      json.firstName,
      json.lastName,
      json.email,
      json.phone,
      json.familyPortalEnabled,
      json.language,
      json.postalCode,
      moment(json.birthday),
      json.familyAccounts ? json.familyAccounts.map(c => Contact.fromJson(c)) : [],
      json.system
    );
  }

  public static fromAccountCheckJson(json: any) {
    if (!json) {
      return undefined;
    }

    return new Account(
      undefined,
      undefined,
      undefined,
      json.accountName
    );
  }
}
