import { FormControl } from "@angular/forms";
import { Moment } from "moment-timezone";

export const validateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const postalFilter = (postalCode: string) => {
  if (!postalCode) {
    return null;
  }

  postalCode = postalCode.toString().trim();

  // const us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
  const ca = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);

  // if (us.test(postalCode.toString())) {
  //   return postalCode;
  // }

  if (ca.test(postalCode.replace(/\W+/g, ''))) {
    return postalCode;
  }
  return null;
};

export const checkUppercase = (password: string) => {
  const regex = /[A-Z]+/;
  return regex.test(password);
};

export const checkLowercase = (password: string) => {
  const regex = /[a-z]+/;
  return regex.test(password);
};

export const checkNumbersAndSymbols = (password: string) => {
  const symbolRegex = /[!-/:-@[-`{-~!"^_`\[\]]+/;
  const numberRegex = /[0-9]+/;
  return symbolRegex.test(password) && numberRegex.test(password);
};

export const getErrorMessageByReactiveFormError = (formControl: FormControl, errorMessages: string[]) => {
  if (!formControl.touched || !formControl.errors || !errorMessages) {
    return '';
  }
  const keys = Object.keys(formControl.errors);
  const errorMessagesKeys = Object.keys(errorMessages);
  const firstError = keys.find(key => formControl.errors[key] && errorMessagesKeys.indexOf(key) > -1);
  if (firstError) {
    return errorMessages[firstError];
  }
  return '';
};

export const doesDateSpanOverlapDate = (start: Moment, end: Moment, date: Moment) => {
  return start.isSameOrBefore(date, 'day') && end.isSameOrAfter(date, 'day');
};

export function getLocalStorage<T>(key: string): T | undefined {
  const value = localStorage.getItem(key);
  if (!value) {
    return undefined;
  }
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    localStorage.removeItem(key);
    console.warn(`LocalStorage under key ${key} cannot be parsed, removed item`);
    return undefined;
  }
}

export function setLocalStorage(key: string, item: any) {
  localStorage.setItem(key, JSON.stringify(item));
}

export function deleteLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function clearSessionStorage() {
  sessionStorage.clear();
}

export function setSessionStorage(key: string, item: any) {
  sessionStorage.setItem(key, JSON.stringify(item));
}

export function getSessionStorage<T>(key: string): T | undefined {
  const value = sessionStorage.getItem(key);
  if (!value) {
    return undefined;
  }
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    sessionStorage.removeItem(key);
    console.warn(`SessionStorage under key ${key} cannot be parsed, removed item`);
    return undefined;
  }
}

export function deleteSessionStorage(key: string) {
  sessionStorage.removeItem(key);
}

// export function validPostalCodeInProvince(postalCode: string, province: Province) {
//   if (!postalCode || !province) {
//     return false;
//   }
//   const initial = postalCode.substring(0, 1);
//   return province.postalCodes.some(p => p.toUpperCase() === initial.toUpperCase());
// }

export function generateInitials(firstName: string, lastName: string, lastNameInitial = '') {
  if (firstName) {
    if (lastNameInitial) {
      lastNameInitial = lastNameInitial.trim().slice(0, 1);
    } else if (lastName) {
      lastNameInitial = lastName.trim().slice(0, 1);
    }
    return firstName.trim().slice(0, 1).concat(lastNameInitial).toUpperCase();
  }
  return '';
}
