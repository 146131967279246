<div class="bs-virtualcare">
  <div class="bs-virtualcare-header">
    <div class="bs-virtualcare-header-logo">
      <img src="/assets/images/wavelogo.svg" class="pt-4 logo" />
    </div>
    <div class="bs-virtualcare-header-language">
      <app-language-toggle></app-language-toggle>
    </div>
  </div>
  <div class="bs-virtualcare-content" [style.backgroundColor]="envColor">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>

<div class="bs-virtualcare-floating">
  <div class="bs-virtualcare-floating-banner">
    <dxp-text class="bs-virtualcare-floating-banner__text">{{'VIRTUALCARE.BANNER.BANNER'|translate}}
    </dxp-text>&nbsp;
    <dxp-link [routerLink]="['/virtualcare']" [fragment]="'covid19'" [underline]="true" [greedy]="false"
      [inverted]="true" class="bs-virtualcare-floating-banner__text">
      {{'VIRTUALCARE.BANNER.BANNER_LINK'|translate}}
    </dxp-link>
  </div>

</div>
