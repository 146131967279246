import { Component, OnInit, Output, EventEmitter, Input, } from '@angular/core';

@Component({
  selector: 'app-user-consent',
  templateUrl: './user-consent.component.html',
  styleUrls: ['./user-consent.component.sass']
})
export class UserConsentComponent implements OnInit {

  public isScrollCompleted:boolean = false;
  public termsValue:boolean;
  public consentValue:boolean;
  @Output()
  public onConsent: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public onTerms: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public hasTerms: boolean = false;

  @Input()
  public isFrenchProgram: boolean = false;

  constructor() { }

  ngOnInit(): void {
    console.log('this.isFrenchProgram from consent',this.isFrenchProgram)

  }
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight -2)) {
      this.isScrollCompleted = true;
    }
  }
  onConsentChange() {
    this.onConsent.emit(this.consentValue);
  }
  onTermsChange() {
    this.onTerms.emit(this.termsValue);
  }

}
