//abbott
// Secret link to be kept internally - Nurse/Provider initiated flow
// Wave flow similar to the Pharmacy flow UI
// One Service
// 2 Providers across all provinces
// Remove the check box in register page as its a Nurse/Provider initiated flow and they don't want to consent on behalf of the patient to create MBC account

// biogen
// The UI flow is similar to the Pharmacy flow where we list out the services (Avonex and Plegridy) and the nurses will be based on the provinces.
export default [
    {
        intakePathway: "provider-initiated",
        program: "abbott", // will have province grouping
        route: "abbott",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "biogen",
        route: "biogen",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "zejula",
        route: "zejula",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : true,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "manulife-comprehensive",
        route: "manulife-comprehensive",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : true,
            isProvider : false,
            hasConsent : true,
            frenchPrograms:[
                'Examen planifié des Médicaments de Manuvie – Bayshore'
            ]
        }
    },
    {
        intakePathway: "provider-initiated",
        program: "manulife",
        route: "manulife",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : true,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "avonex",
        route: "avonex",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : true,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "nurse-visit",
        route: "nurse-visit",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "physiotherapy",
        route: "physiotherapy",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "physiotherapy-ics",
        route: "physiotherapy-ics",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false,
            isIcs:true,

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "occupational",
        route: "occupational",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "occupational-ics",
        route: "occupational-ics",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false,
            isIcs:true,

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "speech",
        route: "speech",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "speech-ics",
        route: "speech-ics",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false,
            isIcs:true,

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "social-worker-ics",
        route: "social-worker-ics",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false,
            isIcs:true,

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "dietitian-ics",
        route: "dietitian-ics",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false,
            isIcs:true,

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "bimzelx",
        route: "bimzelx",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "cimzia",
        route: "cimzia",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "cabenuva",
        route: "cabenuva",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : false,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "hizentra",
        route: "hizentra",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : false,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "berinert",
        route: "berinert",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : false,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "haegarda",
        route: "haegarda",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : false,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "humate-p",
        route: "humate-p",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : false,
            isProvider : false

        }
    },
    {
        intakePathway: "provider-initiated",
        program: "teva",
        route: "teva",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            hasTerms : false,
            isProvider : false

        }
    },
    {
      intakePathway: "provider-initiated",
      program: "nucala",
      route: "nucala",
      paymentConfig: {
          collectPayment: false
      },
      bookingStepsConfig: {
          stepOrder: [
              { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
              { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
              { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
              { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
              { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
              { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
          ],
          hasTerms : false,
          isProvider : false

      }
    },
    {
        intakePathway: "public",
        program: "bayshore-express",
        route: "bayshore-express",
        paymentConfig: {
            collectPayment: false
        },
        logo: '',
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "public",
        program: "home-care",
        route: "home-care",
        paymentConfig: {
            collectPayment: false
        },
        logo: '',
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "pharmacy",
        program: "kevzara",
        route: "kevzara",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    },
    {
        intakePathway: "pharmacy",
        program: "jivi",
        route: "jivi",
        paymentConfig: {
            collectPayment: false
        },
        bookingStepsConfig: {
            stepOrder: [
                { step: 'choose-service', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", shortTitle: "Select Service", class: "current" },
                { step: 'choose-provider', title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: "Select Provider", class: "notdone" },
                { step: 'select-time', title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: "Select Timeslot", class: "notdone" },
                { step: 'special-instructions', title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: "Next", class: "notdone" },
                { step: 'login-register', title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: "Next", class: "notdone" },
                { step: 'completed', title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: "Complete", class: "notdone" }
            ],
            isProvider : false

        }
    }
];
