<div class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
  <div class="row ">
    <div class="col-md-12">
      <div class="booking-details-container">
        <div class="booking-details">
          <div class="row">
            <div class="col-md-6 order-last order-sm-first order-md-first order-lg-first order-xl-first">
              <h3 class="head mb-3">{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.BOOKING_DETAILS'|translate}}
              </h3>
              <div class="row">
                <div class="col-md-12">
                  <app-booking-details [selectedProvider]="selectedProvider" [selectedService]="selectedService"
                    [selectedTimeslot]="selectedTimeslot" [logo]="logo"></app-booking-details>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-first order-sm-last order-md-last order-lg-last order-xl-last">
              <div class="specialinstruction">
                <h3 class="head mb-4">{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.INSTRUCTIONS'|translate}}
                </h3>
                <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.INSTRUCTION_CONTENT'|translate}}</p>
                <textarea class="form-control text-area" placeholder="Notes(Optional)" [(ngModel)]="notes"></textarea>
                <br>
                <!-- <button class=" btn-attach float-right float-sm-left float-md-left float-lg-left float-sm-left"> <img src="/assets/images/attachment.svg"> Attach File</button> -->
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="bottom-buttons">
                <button class="back" (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
                <button class="next" (click)="nextClick()" [disabled]="isLoading">{{'VIRTUALCARE.BAYSHORE_SERVICE.NEXT'|translate}}
                  <img src="/assets/images/spinner-small.svg" *ngIf="isLoading" style="width:32px; height:32px" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-block d-sm-none d-md-none d-lg-none d-xl-none">
  <div class="card  mobile-instruction">
    <div class="card-header">
      <div class="row">
        <div class="col-6">
          <h3>Instructions</h3>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row ">
        <div class="col-md-12">
          <div class="booking-details-container">
            <div class="booking-details">
              <div class="row">

                <div class="col-md-6 order-first order-sm-last order-md-last order-lg-last order-xl-last">
                  <div class="specialinstruction">
                    <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.INSTRUCTION_CONTENT'|translate}}</p>
                    <textarea class="form-control text-area" placeholder="Notes(Optional)"
                      [(ngModel)]="notes"></textarea>
                    <br>
                    <!-- <button class=" btn-attach float-right float-sm-left float-md-left float-lg-left float-sm-left"> <img src="/assets/images/attachment.svg"> Attach File</button> -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer t mob-footer">
      <div class="row">
        <div class="col-md-12">
          <div class="bottom-buttons">
            <button class="next float-right"
              (click)="nextClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.NEXT'|translate}} 
              <img src="/assets/images/spinner-small.svg"  style="width:32px; height:32px" />
            </button>
            <button class="back float-right"
              (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
