import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { VirtualServiceType } from '../enum/virtual-service-type.enum';
import { Provider } from '../model/provider';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends BaseService {
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  public getProvidersByService(serviceId: string): Observable<Provider[]> {
    return this.http.get(
      `${this.apiBase}/virtual-services/${serviceId}/providers`
    ).pipe(
      map((json: any) => json.map(p => Provider.fromJson(p))),
      tap({ error: (error) => this.handleError(error) }),
      shareReplay(1)
    );
  }

  public getProvidersByServiceType(virtualServiceType: VirtualServiceType, program?: string): Observable<Provider[]> {
    let params: any;
    if (program) {
      params = new HttpParams()
        .set('virtualServiceType', virtualServiceType).set('program', program);
    } else {
      params = new HttpParams().set('virtualServiceType', virtualServiceType);
    }


    return this.http.get(
      `${this.apiBase}/virtual-providers`, { params }
    ).pipe(
      map((json: any) => json.map(p => Provider.fromJson(p))),
      tap({ error: (error) => this.handleError(error) }),
      shareReplay(1)
    );
  }

}
