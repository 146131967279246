import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.sass']
})
export class BookingDetailsComponent implements OnInit {

  selectedDay: any = '';

  @Input() selectedProvider: any;
  @Input() selectedService: any;
  @Input() selectedTimeslot: any;
  @Input() stepsCompleted: any[];
  @Input() logo: string;
  isPublicFlow: boolean;

  constructor() { }

  ngOnInit(): void {
    if (this.selectedProvider) {
      this.isPublicFlow = false;
    } else {
      this.isPublicFlow = true;
    }

    if (this.selectedTimeslot) {
      this.selectedDay = this.selectedTimeslot.format("LL")
      this.selectedTimeslot = `${this.selectedTimeslot.format("LT")} - ${this.selectedTimeslot.clone().add(this.selectedService.duration, 'minutes').format("LT")}`;
    }

  }

}

