export class UserBookingInformation {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public phoneNumber: string,
    public languagePreference: string,
    public timezone: string,
    public system?: string,
    public clientId?: string,
    public cognitoId?: string,
    public termsChecked?: boolean,
    public consentChecked?: boolean,
  ) { }

  public static fromFormValue(form: any) {
    return new UserBookingInformation(
      form.firstName,
      form.lastName,
      form.email,
      form.phoneNumber,
      form.languagePreference,
      form.timezone
    );
  }
};
