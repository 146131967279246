import * as moment from 'moment';
import { Moment } from 'moment';
import { Family } from './family';


export class User {
  constructor(
    public cognitoId: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public clientIds: string[],
    public stripeCustomerId: string,
    public alexaUserIds: string[],
    public families: Family[]
  ) {}

  static fromJson(json: any) {
    if (!json) {
      return undefined;
    }
    return new User(
      json.cognitoId,
      json.firstName,
      json.lastName,
      json.email,
      json.clientIds,
      json.stripeCustomerId,
      json.alexaUserIds,
      json.families ? json.families.map((f: any) => Family.fromJson(f)) : []
    );
  }
}
