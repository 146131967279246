<div class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
  <div class="row">
    <div class="col-md-12">
      <div class="booking-confirmed-container">
        <div class="booking-confirmed">
          <div class="row">
            <div class="col-md-5 order-last order-sm-first order-md-first order-lg-first order-xl-first">
              <h3 class="head mb-4">{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.BOOKING_DETAILS'|translate}}
              </h3>

              <app-booking-details [selectedProvider]="selectedProvider" [selectedService]="selectedService"
                [selectedTimeslot]="selectedTimeslot" [logo]="logo"></app-booking-details>
            </div>
            <div class="col-md-7">
              <form [formGroup]="form" (ngSubmit)="Submit()">
                <div class="complete-registration">
                  <h3 class="head mb-4">
                    {{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.COMPLETE_REGISTRATION'|translate}}</h3>
                  <p>{{'VIRTUALCARE.REGISTRATION.HEADER_1' | translate}}</p>
                  <p>{{'VIRTUALCARE.REGISTRATION.HEADER_2' | translate}}</p>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form">
                        <div class="form-group">
                          <input type="text" formControlName="firstName" id="firstName" class=" reg-input user-icon"
                            placeholder="First Name" />
                          <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger">
                            <div *ngIf="f.firstName.errors.required">First Name is required.</div>
                            <div *ngIf="f.firstName.errors.minlength">First Name should be 2 character.</div>
                            <div *ngIf="f.firstName.errors.pattern">Invalid First Name.</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <input type="text" formControlName="lastName" id="lastName" class=" reg-input user-icon"
                            placeholder="Last Name" />
                          <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger">
                            <div *ngIf="f.lastName.errors.required">Last Name is required.</div>
                            <div *ngIf="f.lastName.errors.minlength">Last Name should be 2 character.</div>
                            <div *ngIf="f.lastName.errors.pattern">Invalid Last Name.</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <input type="text" formControlName="email" id="email" class=" reg-input email-icon"
                            placeholder="Email ID" />
                          <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                            <div *ngIf="f.email.errors.required">Email is required.</div>
                            <div *ngIf="f.email.errors.pattern">Please, enter valid email address.</div>
                          </div>
                        </div>
                        <div class="form-group">
                          <input formControlName="phoneNumber" id="phoneNumber" class=" reg-input phone-icon"
                            [textMask]="{mask: phoneMask}" placeholder="(416) 412-2929" />
                          <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid" class="alert alert-danger">
                            <div *ngIf="f.phoneNumber.errors.required">Phone is required.</div>
                            <div *ngIf="f.phoneNumber.errors.invalidPhoneNumber">Phone number should be 10 digits</div>
                          </div>
                        </div>
                        <div class="form-group" *ngIf="!allowLogin">
                          <div style="color: #1b99e9; font-size: small;">
                            Please enter the client's timezone below:
                          </div>
                          <select id="timezone" class="reg-input user-icon" formControlName="timezone">
                            <option *ngFor="let tz of timezones" [value]="tz.value">{{tz.name}}
                            </option>
                          </select>

                          <div *ngIf="f.timezone.touched && f.timezone.invalid" class="alert alert-danger">
                            <div *ngIf="f.timezone.errors.required">Time Zone is required.</div>
                          </div>
                        </div>

                        <div *ngIf="isDuplicate" class="alert alert-danger">
                          <div>Email id is already registerd. Please login</div>
                        </div>
                        
                        <app-user-consent *ngIf="hasConsent" (onConsent)="getConsentValue($event)" (onTerms)="getTermsValue($event)" [hasTerms]="true" [isFrenchProgram]="isFrenchProgram"></app-user-consent>


                        <div class="recaptcha mt-4" *ngIf="isRecaptchaVisible">
                          <!-- <recaptcha [size]="'normal'" [hide]="false" (captchaResponse)="onRecaptchaResponse($event)">
                            </recaptcha> -->
                          <re-captcha id="recaptchaReactive" formControlName="recaptchaReactive"></re-captcha>
                          <div *ngIf="f.recaptchaReactive.touched && f.recaptchaReactive.invalid"
                            class="alert alert-danger">
                            Please complete the re-captcha.
                          </div>
                        </div>

                        <div class="form-check" *ngIf="allowLogin && !hasConsent">
                          <input class="form-check-input" type="checkbox" (change)="checkValue()" id="gridCheck" />
                          <label class="form-check-label" for="gridCheck">
                            {{'VIRTUALCARE.REGISTRATION.TERMS_1' | translate}}
                            <a [href]="termsUrl"
                              target="_blank">{{'VIRTUALCARE.REGISTRATION.TERMS_2' | translate}}</a>
                          </label>
                        </div>
                        <label class="form-check-label" for="gridCheck" >
                          {{'VIRTUALCARE.REGISTRATION.LOGIN_MSG' | translate}}
                          <span class="link" (click)="loginClick()">{{'VIRTUALCARE.REGISTRATION.LOGIN' |
                              translate}}</span>

                          <!-- <a>REGISTER </a>  -->
                          now
                        </label>
                        <!-- <p>I have read and agree to Terms & Conditions</p> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <img src="/assets/images/mbc.png" class="w-100 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="back" type="button"
                        (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
                      <button class="register" type="submit"
                        [class.disabled]="!form.valid  || !termsChecked ||!consentChecked || isLoading"
                        [disabled]="!form.valid  || !termsChecked ||!consentChecked || isLoading">
                        <img src="/assets/images/spinner-small.svg" *ngIf="isLoading" style="width:32px; height:32px" />
                        {{'VIRTUALCARE.REGISTRATION.REGISTER' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!--mob---->
<form [formGroup]="form" (ngSubmit)="Submit()">
  <div class="d-block d-sm-none d-md-none d-lg-none d-xl-none">
    <div class="card mob-register-card">
      <div class="card-header">
        <div class="row">
          <div class="col-6">
            <h3>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.COMPLETE_REGISTRATION'|translate}}</h3>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-confirmed-container">
              <div class="booking-confirmed">
                <div class="row">
                  <div class="col-md-7">
                    <div class="complete-registration">
                      <p>{{'VIRTUALCARE.REGISTRATION.HEADER_1' | translate}}</p>
                      <p>{{'VIRTUALCARE.REGISTRATION.HEADER_2' | translate}}</p>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form">
                            <div class="form-group">
                              <input type="text" formControlName="firstName" id="firstName" class=" reg-input user-icon"
                                placeholder="First Name" />
                              <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger">
                                <div *ngIf="f.firstName.errors.required">First Name is required.</div>
                                <div *ngIf="f.firstName.errors.minlength">First Name should be 3 character.</div>
                              </div>
                            </div>
                            <div class="form-group">
                              <input type="text" formControlName="lastName" id="lastName" class=" reg-input user-icon"
                                placeholder="Last Name" />
                              <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger">
                                <div *ngIf="f.lastName.errors.required">Last Name is required.</div>
                                <div *ngIf="f.lastName.errors.minlength">Last Name should be 3 character.</div>
                              </div>
                            </div>
                            <div class="form-group">
                              <input type="text" formControlName="email" id="email" class=" reg-input email-icon"
                                placeholder="Email ID" />
                              <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                                <div *ngIf="f.email.errors.required">Email is required.</div>
                                <div *ngIf="f.email.errors.pattern">Please, enter valid email address.</div>
                              </div>
                            </div>
                            <div class="form-group mb-3">
                              <input formControlName="phoneNumber" id="phoneNumber" class=" reg-input phone-icon"
                                [textMask]="{mask: phoneMask}" placeholder="(416) 412-2929" />
                              <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid" class="alert alert-danger">
                                <div *ngIf="f.phoneNumber.errors.required">Phone is required.</div>
                                <div *ngIf="f.phoneNumber.errors.invalidPhoneNumber">Phone number should be 10 digits
                                </div>
                              </div>
                            </div>
                            <div *ngIf="isDuplicate" class="alert alert-danger">
                              <div>{{'VIRTUALCARE.REGISTRATION.EMAIL_VALIDATION' | translate}}</div>

                            </div>
                            <app-user-consent *ngIf="hasConsent" (onConsent)="getConsentValue($event)" (onTerms)="getTermsValue($event)" [hasTerms]="true" [isFrenchProgram]="isFrenchProgram"></app-user-consent>

                            <div class="recaptcha mt-3 mb-3" *ngIf="isRecaptchaVisible">
                              <re-captcha formControlName="recaptchaReactive"></re-captcha>
                              <div *ngIf="f.recaptchaReactive.touched && f.recaptchaReactive.invalid"
                                class="alert alert-danger">
                                {{'VIRTUALCARE.REGISTRATION.CAPTCHA_VALIDATION' | translate}}
                              </div>
                            </div>
                            <div class="form-check" *ngIf="allowLogin && !hasConsent">
                              <input class="form-check-input" type="checkbox" (change)="checkValue()" id="gridCheck">
                              <label class="form-check-label" for="gridCheck">
                                {{'VIRTUALCARE.REGISTRATION.TERMS_1' | translate}}
                                <a href="#">{{'VIRTUALCARE.REGISTRATION.TERMS_2' | translate}}</a>
                              </label>
                            </div>
                            <label class="form-check-label" for="gridCheck">
                              {{'VIRTUALCARE.REGISTRATION.LOGIN_MSG' | translate}}
                              <span class="link" (click)="loginClick()">{{'VIRTUALCARE.REGISTRATION.LOGIN' |
                                  translate}}</span>

                              <!-- <a>REGISTER </a>  -->
                              now
                            </label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <img src="/assets/images/mbc.png"
                            class="w-100 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-muted mob-footer">
        <!-- <div class="row">
          <div class="col-md-12">
            <button class="back">back </button>
            <button class="mob-register" (click)="register()">Register </button>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12">

            <button class="mob-register float-right" type="submit"
              [class.disabled]="!form.valid  || !termsChecked ||!consentChecked || isLoading"
              [disabled]="!form.valid || !termsChecked ||!consentChecked || isLoading">
              <img src="/assets/images/spinner-small.svg" *ngIf="isLoading" style="width:32px; height:32px" />
              {{'VIRTUALCARE.REGISTRATION.REGISTER' | translate}}
            </button>
            <button class="back float-right" (click)="backClick()"
              type="button">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
