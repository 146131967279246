import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.sass']
})
export class StepperComponent implements OnInit {
  @Input()
  bookingSteps: any[];
  @Input()
  isBackButton: boolean;
  @Input()
  count: number;
  @Input()
  step: string;
  @Input()
  bookingDetails: string[];
  @Input()
  showHeader: boolean;

  @Output()
  back: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  previousStep() {
    this.back.emit(true);
  }

}
