import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../shared/components/Base/base.component';
import { IVirtualEventRescheduleRequest, IVirtualservice, IVirtualserviceUser } from '../../../model/virtualservice';
import { VirtualcareService } from '../../../services/virtualcare.service';
import { BookingStep } from '../../../enum/booking-step.enum';
import { VirtualServiceType } from '../../../enum/virtual-service-type.enum';
import { Provider } from '../../../model/provider';
import { Moment } from 'moment-timezone';
import { ProviderService } from '../../../services/provider.service';
import { UserBookingInformation } from '../../../model/user-booking-information';
import { VirtualServiceBookingRequest } from '../../../model/virtual-service-booking-request';
import waveConfig from '../../../config/wave.config';
import { IWaveConfig } from '../../../config/IWaveConfig';
import { IBookingStepConfig } from '../../../config/IBookingStepConfig';
import * as moment from 'moment-timezone';
import { environmentConfig } from '../../../../environments/environment';


export interface MobileStepper {
  text: string,
  count: number
};

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.sass']
})
export class RescheduleComponent extends BaseComponent implements OnInit, OnChanges {

  public virtualServiceType = VirtualServiceType.PROVIDER_INITIATED;
  public bookingStepsEnum = BookingStep;
  public initialBookingStep: BookingStep;
  public currentBookingStep: BookingStep;
  public nextBookingStepConfig: IBookingStepConfig;
  public mobileStepperList: MobileStepper[];
  public mobileStepper: MobileStepper;
  public bookingDetails: any[] = [];
  public showHeader: boolean;
  public isOtherFlow: boolean = false;

  //TODO: assign controls dynamically in the config
  public bookingStepsConfig: Array<IBookingStepConfig> = [
    { step: BookingStep.selectTime, title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: 'Select Timeslot', class: "current" },
    { step: BookingStep.specialInstructions, title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: 'Next', class: "notdone" },
    { step: BookingStep.completed, title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: 'Finish Booking', class: "notdone" }
  ];

  public virtualServices: IVirtualservice[] = [];
  public selectedService: IVirtualservice;
  public selectedUser: IVirtualserviceUser;
  public virtualServiceProviders: Provider[] = [];
  public selectedProvider: Provider;
  public selectedTimeslot: Moment;
  public specialInstructions: string;
  public bookingCompletedUserName: string;
  public bookingCompletedConfirmation: string;
  public bookingCompletedSuccessMessage: string;
  public selectedAppointmentId: string;

  public isLoading: boolean = false;
  public hasErrorBooking: boolean = false;
  public isFrenchProgram: boolean = false;

  public description = "";
  public serviceName = "";
  public duration: number;
  public serviceId: number;
  public hasTerms: boolean;
  public hasConsent: boolean;
  public isProvider: boolean;
  public hasAccount: boolean = false;

  public stepperStatus = "stepper__list__item stepper__list__item--current"

  public programRoute: string;
  public programConfig: IWaveConfig;
  public displayCost: boolean = true;

  public isReschedule: boolean = false;
  public rescheduleSignature: string;

  constructor(
    public sanitizer: DomSanitizer,
    private virtualCareService: VirtualcareService,
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) { super(); }

  ngOnInit(): void {

    this.isLoading = true;
    this.showHeader = true;
    this.route.queryParams.subscribe(param => {
      if (param['signature']) {
        //load reschedule
        this.isReschedule = true;
        this.rescheduleSignature = param['signature'];
        this.mobileStepperList = this.bookingStepsConfig.map((item, index) => {
          return {
            count: index + 1,
            text: item.title
          }
        })

        this.mobileStepper = this.mobileStepperList[0];
        this.loadRescheduleData();
      } else {

        this.router.navigate(['/virtual-care/provider']);

      }
    });



  }

  ngOnChanges() {
    this.mobileStepper = this.mobileStepperList[1];
  }

  public bookingStatusUpdate(event) {
    if (this.bookingDetails.length === 0) {
      this.bookingDetails = event;
    } else {
      event.forEach(item => this.bookingDetails.push(item))
    }

  }

  public switchLoginRegister(event) {
    this.hasAccount = event;
  }

  public getNextStepShortTitle() {
    const currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step === this.currentBookingStep);
    return this.bookingStepsConfig[currentStepIndex + 1].shortTitle;
  }


  private loadRescheduleStep() {

    if (this.bookingStepsConfig?.length > 0) {
      this.currentBookingStep = this.bookingStepsConfig[0].step;
      this.nextBookingStepConfig = this.bookingStepsConfig[1];
    }
  }

  private loadRescheduleData() {
    this.isLoading = true;

    this.addSubscription(
      this.virtualCareService.getGuestUserAppointmentData(this.rescheduleSignature
      ).subscribe(data => {
        console.log('data from reschedule', data);
        let serviceData: IVirtualservice = {
          _id: data.appointment._id,
          bookingSystemName: data.appointment.serviceName,
          createdDate: data.appointment.createdDate,
          bookingSystemId: data.appointment.appointmentTypeId,
          displayName: data.appointment.serviceName,
          description: this.formatTranslation(data.appointment.serviceName, data.appointment.serviceName),
          isAvailable: true,
          isRegistrationRequired: true,
          icon: '',
          bookingPath: '',
          groupName: '',
          duration: data.service.duration,
          price: 10,
          serviceType: VirtualServiceType.PROVIDER_INITIATED,
          categoryType: '',
          category: ''
        };
        this.selectedAppointmentId = data.appointment.appointmentId;
        let userData: IVirtualserviceUser = {
          firstName: data.appointment.userInfo.firstName,
          lastName: data.appointment.userInfo.lastName,
          email: data.appointment.userInfo.email,

        }
        this.selectedTimeslot = moment(data.appointment.startDate);

        if(this.selectedTimeslot.isBefore(moment().add(environmentConfig.appointmentMinimumTimeHour,'hour'))){
          this.isOtherFlow = true;
          this.isLoading = false;
          console.log('error','past evcent')
          this.bookingCompletedSuccessMessage = 'This event is too close to happen or a past event!';
          return;
        }
        let caregiver = new Provider(data.appointment.caregiver.providerId, data.appointment.caregiver.name, '', '', '');
        this.selectedService = serviceData;
        this.selectedUser = userData;
        this.duration = data.service.duration;
        this.setServiceSession(serviceData);
        this.selectedProvider = caregiver;
        this.loadRescheduleStep();
        this.isLoading = false;


      }, error => {
        this.isOtherFlow = true;
        this.isLoading = false;
        console.log('error',error)
        this.bookingCompletedSuccessMessage = 'Bookings not found!';
      })
    );
  }


  setServiceSession(virtual: IVirtualservice) {
    this.serviceId = +virtual.bookingSystemId;
    this.description = virtual.description[0].value;
    this.serviceName = virtual.displayName[0].value;
    this.duration = +virtual.duration;
  }

  serviceSelected(selectedService: IVirtualservice) {

    this.selectedService = selectedService;
    this.setServiceSession(selectedService);
    this.next();
  }

  formatTranslation = (englishText: string, frenchText: string) => {
    return [
      { lang: 'en', value: englishText },
      { lang: 'fr', value: frenchText }
    ];
  };

  public providerSelected(selectedProvider: { provider: Provider, service: IVirtualservice }) {
    // console.log('provider:' + JSON.stringify(selectedProvider));
    this.selectedProvider = selectedProvider.provider;
    if (!this.selectedService) {
      this.selectedService = selectedProvider.service;
      this.setServiceSession(selectedProvider.service);
    }

    this.next();
  }

  public timeslotSelected(selectedTimeslot: Moment) {
    this.selectedTimeslot = selectedTimeslot;
    this.next();
  }

  public specialInstructionsEntered(specialInstructions: string) {
    this.specialInstructions = specialInstructions;
    this.completeReschedule();
  }

  public completeReschedule() {
    this.isLoading = true;
    this.bookingCompletedUserName = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;
    // this.bookingCompletedUserName = this.selectedProvider.firstName.toUpperCase();
    this.bookingCompletedSuccessMessage = "VIRTUALCARE.SUCCESS.RESCHEDULE_SUCCESS";
    this.bookingCompletedConfirmation = "VIRTUALCARE.SUCCESS.PROVIDER_CONFIRM";
    const bookingRequest: IVirtualEventRescheduleRequest = {
      email:this.selectedUser.email, 
      providerId:this.selectedProvider.id, 
      appointmentStartDate: this.selectedTimeslot.format(),
      token:this.rescheduleSignature 
    };
    this.addSubscription(
      this.virtualCareService.rescheduleVirtualEventForGuestUser(bookingRequest, parseInt(this.selectedAppointmentId)).subscribe(data => {
        this.isLoading = false;
        this.showHeader = false;
        this.next();

      }, error => {
        this.isLoading = false;
        switch (error.status) {
          case 409:
            this.hasErrorBooking = true;
            break;
          default:
            this.hasErrorBooking = true;

        }
      })
    );
  }
  public registrationEntered(userRegistration: UserBookingInformation) {
    this.isLoading = true;

    const bookingRequest: VirtualServiceBookingRequest = new VirtualServiceBookingRequest(
      userRegistration,
      Number(this.selectedService.bookingSystemId),
      this.selectedTimeslot.format(),
      this.specialInstructions
    );

    this.bookingCompletedUserName = userRegistration.firstName;
    // this.bookingCompletedUserName = this.selectedProvider.firstName.toUpperCase();
    this.bookingCompletedSuccessMessage = "VIRTUALCARE.SUCCESS.PROVIDER_SUCCESS";
    this.bookingCompletedConfirmation = "VIRTUALCARE.SUCCESS.PROVIDER_CONFIRM";

    this.addSubscription(
      this.virtualCareService.createVirtualEventForProvider(
        this.selectedProvider.id,
        bookingRequest
      ).subscribe(data => {
        this.isLoading = false;
        this.showHeader = false;
        this.next();

      }, error => {
        this.isLoading = false;
        switch (error.status) {
          case 409:
            this.hasErrorBooking = true;
            break;
          default:
            this.hasErrorBooking = true;

        }
      })
    );
  }

  public removeSelectedStepData(currentStep: BookingStep, previousStep: BookingStep) {
    switch (previousStep) {
      case this.bookingStepsEnum.selectTime:
        this.selectedTimeslot = undefined;
        break;
      case this.bookingStepsEnum.specialInstructions:
        this.specialInstructions = undefined;
        break;
      default:
        break;
    }
  }

  public previousStep(event?) {
    let currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step == this.currentBookingStep);

    if (currentStepIndex == -1) {
      console.log('not found');
      return;
    }

    this.nextBookingStepConfig = this.bookingStepsConfig[1];

    const s = this.mobileStepperList.findIndex(f => f === this.mobileStepper);

    if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS') {
      const l = this.bookingDetails.length;
      this.bookingDetails.splice(l - 2, 2);
    } else if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME') {
      if (s === 2) {
        this.bookingDetails.pop();
      } else {
        this.bookingDetails = [];
      }
    }

    this.mobileStepper = this.mobileStepperList[s - 1];
    // make the current step not done
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "notdone";
    const step = this.currentBookingStep;

    currentStepIndex = currentStepIndex - 1;
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex].step;
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "current";
    this.removeSelectedStepData(step, this.currentBookingStep);

    this.isLoading = false;
  }

  next(currentStep?: BookingStep) {
    const currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step === this.currentBookingStep);
    this.bookingStepsConfig[currentStepIndex].class = "active";
    this.bookingStepsConfig[currentStepIndex + 1].class = "current";

    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex + 1].step;
    this.nextBookingStepConfig = this.bookingStepsConfig[currentStepIndex + 2];
    const s = this.mobileStepperList.findIndex(f => f === this.mobileStepper)
    this.mobileStepper = this.mobileStepperList[s + 1];


  }



}
