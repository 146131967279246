<div class="row ">
  <div class="col-md-12">
    <div class="error-container  mt-3">
      <div class="error-inner  text-center" >
        <h3>Reschedule not completed</h3>
        <div class="hr"> </div>
        <p class="text-danger">
          some error happend error component
        </p>
      </div>
    </div>
  </div>
</div>