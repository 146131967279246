<div class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
  <div class="row ">
    <div class="col-md-12">
      <ngx-spinner type="ball-spin-clockwise"></ngx-spinner>
      <div class="choose-time-container">
        <div class="choose-date-section">
          <div class="choose-date-section-header">
            <div class="timeSection-header">
              <div class="choosetime-head">{{'VIRTUALCARE.TIME_SELECTION.CHOOSE_TIME' | translate}}</div>
              <div class="choosetime-head-provider" *ngIf="selectedProvider" id="timeslot_provider_name">
                with {{selectedProvider.firstName}} {{selectedProvider.lastName}}
              </div>
            </div>

            <div class="wave-logo">
              <img src="{{logo}}" />
            </div>

            <div class="choosedate">
              <button id="previousWeek" class="round-left" [disabled]="false" (click)="backwardClick($event)">
                <img src="/assets/images/arrow-left.svg">
              </button>
              <h4>{{weekLabel}}</h4>
              <button id="nextWeek" class="round-right" (click)="forwardClick($event)">
                <img src="/assets/images/arrow-right.svg">
              </button>
            </div>
          </div>

          <div class="date-table">
            <div class="row">
              <div class="table-responsive" *ngIf="timeslots">
                <div class="bs-timeslot-selection-slots-container-headers">
                  <div class="bs-timeslot-selection-slots-container-datecolumn" *ngFor="let day of timeslots">
                    <div class="bs-timeslot-selection-slots-container-datecolumn-day">
                      {{day.date.format("dddd")}}
                    </div>
                    <div class="bs-timeslot-selection-slots-container-datecolumn-date">
                      {{day.date.format("Do")}}
                    </div>
                  </div>
                </div>
                <div class="bs-timeslot-selection-slots-time-container">
                  <div class="bs-timeslot-selection-slots-time-container-column-container"
                    *ngFor="let slot of timeslots">
                    <div class="bs-timeslot-selection-slots-time-container-column-container-column">

                      <app-timeslot automationId="timeslot_{{i}}"
                        class="bs-timeslot-selection-slots-time-container-column-container-column__padding"
                        *ngFor="let time of slot.times; let i = index" [startTime]="time.time" [duration]="duration"
                        [isEnabled]="time.isEnabled" [isSelected]="time.isSelected" [selectedTime]="selectedTimeslot"
                        (timeSelected)="onTimeslotSelected($event)"></app-timeslot>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row pt-4 pb-1">
            <div class="col-md-4">
              <p class="prime-color">{{'VIRTUALCARE.TIME_SELECTION.SELECTED_SERVICE' | translate}}:
                <span id="timeslot_service_name" class="font-weight-bold"> {{serviceName}} </span>
              </p>
            </div>
            <div class="col-md-4">
              <p class="prime-color">{{'VIRTUALCARE.TIME_SELECTION.SELECTED_TIME' | translate}}:
                <span id="timeslot_selected_time" class="font-weight-bold"> {{selectedTime}} </span>
              </p>
            </div>

            <div class="col-md-4">
              <div class="bottom-buttons">
                <button class="back" *ngIf="!isReschedule"
                  (click)="backClick($event)">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
                <button class="next" (click)="completeBookingStep($event)" [disabled]="!selectedTimeslot || !loaded"
                  [class.disabled]="!selectedTimeslot || !loaded">{{'VIRTUALCARE.BAYSHORE_SERVICE.NEXT'|translate}}</button>
                <h4></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!---Mobile table -->
<div class="d-block d-sm-none d-md-none d-lg-none d-xl-none">
  <!-- <div class="row mt-4 mb-2">
      <div class="col-12">
        <h3 class="mobile-tab-line">
          <span class="count"> 2</span>Select a time
        </h3>
      </div>
    </div> -->
  <div class="card mobile-table full-radius">
    <div class="card-header half-radius">
      <div class="row">
        <div class="col-6">
          <h3 class="m-table-head">{{'VIRTUALCARE.TIME_SELECTION.SELECT_DATE' | translate}}</h3>
        </div>
        <div class="col-6 text-right">
          <p class="m-t-time-head mt-2"> {{duration}} Mins </p>
        </div>
      </div>
    </div>
    <div class="card-body">

      <div class="m-t-nav">
        <div class="row">
          <div class="col-3">
            <button id="mobPreviousWeek" class="m-t-btn-left" [disabled]="false" (click)="backwardClick($event)">
              <img src="/assets/images/arrow-left.svg">
            </button>
          </div>
          <div class="col-6 text-center">
            <h3 class="m-t-date">{{weekLabel}}</h3>
          </div>
          <div class="col-3">
            <button id="mobNextWeek" class="m-t-btn-right" (click)="forwardClick($event)">
              <img src="/assets/images/arrow-right.svg">
            </button>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="row">
          <div class="col-12">
            <ul class="m-t-date-list pl-0 d-flex">
              <li id="day_{{i}}" *ngFor="let day of timeslots; let i = index"
                [ngClass]="{'m-t-active-date': daySlots && day.date === daySlots.date}"
                (click)="onDaySelected($event, day)">
                <span>{{day.date.format("ddd")}}</span>
                <span>{{day.date.format("Do")}}</span>
              </li>
            </ul>
          </div>
        </div>
        <h5 class="m-t-choose-time">{{'VIRTUALCARE.TIME_SELECTION.CHOOSE_TIME' | translate}}
          <span class="text-danger"> *</span>
        </h5>
        <p *ngIf="!isAvailable">
          {{'VIRTUALCARE.TIME_SELECTION.NO_TIME' | translate}}
        </p>
        <div class="row" *ngIf="daySlots">
          <div class="col-6" *ngFor="let time of daySlots.times; let j = index">
            <app-timeslot automationId="timeslot_{{j}}" [startTime]="time.time" [duration]="duration"
              [isEnabled]="time.isEnabled" [isSelected]="time.isSelected" (timeSelected)="onTimeslotSelected($event)">
            </app-timeslot>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer mob-footer">
      <div class="row">
        <div class="col-12">
          <p class="card-text mb-3">
            {{'VIRTUALCARE.TIME_SELECTION.SELECTED_TIME' | translate}} : {{selectedTime}}
          </p>
        </div>
        <div class="col-12">
          <button class="m-t-btn-next" (click)="completeBookingStep($event)"
            [disabled]="!selectedTimeslot || !loaded">{{'VIRTUALCARE.BAYSHORE_SERVICE.NEXT'|translate}}
          </button>
          <button class="m-t-btn-back"
            (click)="backClick($event)">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
        </div>
      </div>
    </div>

  </div>
  <!---Mobile table -->
