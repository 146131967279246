import { System } from "./system";

export class Family {
  constructor(
    public clientId: string,
    public userIds: string,
    public systems: System[],
    public features: any
  ) {}

  static fromJson(json: any) {
    if (!json) {
      return undefined;
    }
    return new Family(
      json.userCognitoId,
      json.userIds,
      json.systems ? json.systems.map((s: any) => System.fromJson(s)) : [],
      json.features
    );
  }
}
