import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { CognitoUser } from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { SettingsService } from '../services/settings.service';

import * as moment from 'moment';
import { AuthService } from '../../services/auth.service';
import { values } from '../constants/constant';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private static lastRefresh = moment().subtract(1, 'year');
  constructor(
    private settings: SettingsService,
    private auth: AuthService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const session: any = JSON.parse(sessionStorage.getItem('token'));
    const session = this.auth.getSession() as any;
    if (session) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${session?.accessToken?.jwtToken}`,
          'Identity': `Identity ${session?.idToken?.jwtToken}`,
          'Accept-Language': this.settings.getLanguage()
        }
      });
      if (moment().diff(AuthInterceptor.lastRefresh, 'minutes') > values.COGNITO_TOKEN_REFRESH_INTERVAL) {
        const user = this.auth.getCurrentUserAccount() as CognitoUser;
        if (user) {
          AuthInterceptor.lastRefresh = moment();
          // user.refreshSession(session.getRefreshToken(), () => {
          //   console.info('refreshed auth session')
          // });
        }
      }
    }
    return next.handle(request);
  }
}
