import { Injectable } from '@angular/core';
import { api } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class BaseService {
  protected apiBase = api.basename;

  constructor(protected authService?: AuthService) {

  }

  protected handleError(error: any) {
    if (error.status === 401) {
      this.authService?.signOut(true).subscribe(
        {
          error: e => {
            console.warn('Error while logging out user', e);
            // this.authService.reset();

          },
          complete: () => {
            //this.authService.goToLogoutPage();
          }
        }
      );
    } else {
      console.warn('SERVICE ERROR:', error);
    }
  }

}
