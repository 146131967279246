import * as moment from "moment-timezone";

export const defaults = {
  language: 'en',
  toastTimeout: 5000,
  paginationLimit: 10
};

export const aws = {
    USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
    NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
    UNEXPECTED_LAMBDA_EXCEPTION: 'UnexpectedLambdaException',
    INVALID_PASSWORD_EXCEPTION: 'InvalidPasswordException',
    INVALID_PARAMETER_EXCEPTION: 'InvalidParameterException',
    EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
    CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
    LIMIT_EXCEEDED_EXCEPTION: 'LimitExceededException'
};

export const values = {
    NEW_FEATURE_RELEASE_TIMESTAMP: moment('2020-03-01T10:20:30Z').unix(),
    COGNITO_TOKEN_REFRESH_INTERVAL: 30,
    CHAT_KEEP_ALIVE: 60000,
    WELLNESS_SCORE: 'wellness_score',
    AVERAGE_SCORE: 5,
    AWS_NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    DASHBOARD_MODAL_TYPE_SERVICE: 'SERVICE',
    DASHBOARD_MODAL_TYPE_SCHEDULE: 'SCHEDULE',
    NOTIFICATION: {
        EMAIL: 'email',
        SMS: 'sms',
        ALL: 'all'
    },
    PERSONAL_EVENT_NAME: 'Personal Event',
    AWS_USER_ATTRIBUTE_NAME: 'sub',
    AWS_USER_ATTRIBUTE_EMAIL: 'email',
    AWS_USER_LANGUAGE_ATTRIBUTE: 'custom:preferred_language',
    AWS_USER_ATTRIBUTE_LAST_LOGIN: 'custom:last_login',
    SYSTEM_FUNDER_TYPE: 'system',
    PRIVATE_FUNDER_TYPE: 'private',
    MARKETPLACE_CART_LOCALSTORAGE_KEY: 'marketplace_cart',
    MARKETPLACE_EMAIL_LOCALSTORAGE_KEY: 'marketplace_email',
    MAX_CREDIT_CARD_EXPIRY_DATE_OFFSET: 20,
    GIVEN_NAME: 'given_name',
    FAMILY_NAME: 'family_name',
    VOICE_TAB_ID: 'voice_tab',
    CHAT_TAB_ID: 'chat_tab',
    CHAT_WITH_CARE_TEAM_TAB_ID: 'care_team_chat_tab',
    CHAT_WITH_BRANCH_TAB_ID: 'branch_chat_tab'
};

export const Routes = {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout'
};

export interface ReCaptchaConfig {
    theme?: 'dark' | 'light';
    type?: 'audio' | 'image';
    size?: 'compact' | 'normal';
    tabindex?: number;
};
