import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Moment } from 'moment';
import { BaseComponent } from '../../../shared/components/Base/base.component';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.sass']
})
export class TimeslotComponent extends BaseComponent implements OnInit {

  @Input()
  public automationId: string;
  @Input()
  public startTime: Moment;
  @Input()
  public duration: any;
  @Input()
  public isSelected: boolean;
  @Input()
  public isEnabled: boolean;
  @Input()
  public selectedTime: any;

  public slotLabel: string = "";

  @Output("timeSelected") timeSelected: EventEmitter<any> = new EventEmitter();

  constructor() { super(); }

  ngOnInit(): void {
    if (this.startTime) {
      this.slotLabel = `${this.startTime.format("H:mm")} - ${this.startTime.clone().add("minutes", this.duration).format("H:mm")}`;
      //this.isEnabled = true;
    } else {
      this.automationId = "";
      this.slotLabel = "No Times Available";
      //this.isEnabled = false;
    }
  }


  timeSelectedClick(event) {
    //this.isSelected = true;
    this.timeSelected.emit(this.startTime);
  }

}

