import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { defaults } from '../constants/constant';

import { Language } from '../enums/language.enum';

import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public onLanguageChanged: Subject<Language> = new Subject();

  constructor(
    private languageService: LanguageService
  ) { }

  getLanguage(): string {
    if (localStorage) {
      return localStorage.language || defaults.language;
    }
    return defaults.language;
  }

  setLanguage(language: Language) {
    if (localStorage) {
      localStorage.language = language;
    }
    this.onLanguageChanged.next(language);
    //this.languageService.setLanguagePreference(language).subscribe();
  }
}