import { UserBookingInformation } from "./user-booking-information";

export class VirtualServiceBookingRequest {
  constructor(
    public userRegistration: UserBookingInformation,
    public appointmentTypeId: number,
    public appointmentStartDate: string,
    public notes: string,
    public providerId?: string
  ) { }

  public toRequestJson() {
    return {
      user: {
        firstName: this.userRegistration.firstName,
        lastName: this.userRegistration.lastName,
        email: this.userRegistration.email,
        phoneNumber: this.userRegistration.phoneNumber,
        languagePreference: this.userRegistration.languagePreference || "en",
        timezone: this.userRegistration.timezone
      },
      appointment: {
        appointmentTypeId: this.appointmentTypeId,
        appointmentStartDate: this.appointmentStartDate,
        notes: this.notes,
        providerId: this.providerId,
        termsChecked: this.userRegistration.termsChecked,
        consentChecked:this.userRegistration.consentChecked
      }
    };
  }
}