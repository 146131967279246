import { Component, Input, OnInit } from '@angular/core';
import { api } from 'src/environments/environment';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.sass']
})
export class SuccessMessageComponent implements OnInit {
  @Input()
  public userName: string;
  @Input()
  public confirmation: string;
  @Input()
  public successMessage: string;
  @Input()
  public providerFlow: boolean = false;
  @Input()
  public providerName: string;

  @Input()
  public isOtherFlow: boolean = false;


  constructor() { }

  ngOnInit(): void {
    if (this.providerFlow) {
      this.providerName = this.providerName.toUpperCase();
    }


  }

  toHomePage() {
    window.location.href = api.homeUrl;
  }

  login() {

  }
}
