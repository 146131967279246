import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Moment } from 'moment';
import { BaseComponent } from '../../../../shared/components/Base/base.component';
import { MobileStepper } from '../../../../model/interface/IMobileStepper';
import { UserBookingInformation } from '../../../../model/user-booking-information';
import { VirtualServiceBookingRequest } from '../../../../model/virtual-service-booking-request';
import { IVirtualservice } from '../../../../model/virtualservice';
import { VirtualcareService } from '../../../../services/virtualcare.service';
import { BookingStep } from '../../../../enum/booking-step.enum';
import { TimezoneService } from '../../../../services/timezone.service';
import * as moment from 'moment-timezone';
import { VirtualServiceType } from 'src/app/enum/virtual-service-type.enum';
import waveConfig from '../../../../config/wave.config'
import { IBookingStepConfig } from 'src/app/config/IBookingStepConfig';
import { logoSrc, programs } from 'src/app/enum/logo-src';

@Component({
  selector: 'app-public-schedule-appointment',
  templateUrl: './public-schedule-appointment.component.html',
  styleUrls: ['./public-schedule-appointment.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PublicScheduleAppointmentComponent extends BaseComponent implements OnInit {
  @ViewChild('longContent') longContent: any;

  public currentBookingStep: BookingStep;
  public bookingStepsEnum = BookingStep;
  public isLoading: boolean;
  public bookingUrl: string;
  public serviceId: number;
  public duration: number;
  public description: string;
  public serviceName: string;
  public showHeader: boolean = true;
  public mobileStepperList: MobileStepper[] = [];
  public mobileStepper: MobileStepper;
  public bookingDetails: any[] = [];
  public programRoute: string;

  public selectedService: IVirtualservice;
  public selectedTimeSlot: Moment;
  public specialInstructions: string;
  public nextStepName: string;

  public bookingCompletedUserName: string;
  public bookingCompletedSuccessMessage: string;
  public bookingCompletedConfirmation: string;
  public hasErrorBooking: boolean = false;
  public hasAccount: boolean = false;
  public logo: string;
  public isInternal: boolean = false;

  virtualServices: IVirtualservice[] = [];
  public timezone = moment.tz.guess();

  public bookingStepsConfig: Array<{ step: BookingStep, title: string, class: string }> = [
    { step: BookingStep.chooseService, title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", class: "current" },
    { step: BookingStep.selectTime, title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", class: "notdone" },
    { step: BookingStep.specialInstructions, title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", class: "notdone" },
    { step: BookingStep.loginRegister, title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", class: "notdone" },
    { step: BookingStep.completed, title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", class: "notdone" }
  ];

  constructor(
    private _virtualservice: VirtualcareService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private timezoneService: TimezoneService
  ) {
    super();
  }

  ngOnInit(): void {
    // this.openModal()
    

    this.logo = logoSrc.EXPRESS;
    let programRoute = this.route.snapshot.params.programRoute ? this.route.snapshot.params.programRoute : 'bayshore-express';
    console.log('programRoute',this.route.snapshot)
    if(programRoute=='home-care'){
      this.logo = logoSrc.HOME;
    }
    this.route.queryParams.subscribe(param => {
      if (param['internal']) {
        this.isInternal = true;
      }
    });
    if(programRoute == programs.HOME_CARE){
      this.logo = logoSrc.HOME;
    }
    this.loadProgramingConfig(programRoute);
    this.nextStepName = 'VIRTUALCARE.TIME_SELECTION.CHOOSE_TIME';
    this.loadIntialStep();

    
    // this.currentBookingStep = this.bookingStepsConfig[0].step;
    // this.mobileStepperList = this.bookingStepsConfig.map((item, index) => {
    //   return {
    //     count: index + 1,
    //     text: item.title
    //   }
    // });

    // this.mobileStepper = this.mobileStepperList[0];

  }
  getServiceList(program: string) {
    this.addSubscription(
      this._virtualservice.getVirtualServices(VirtualServiceType.PUBLIC, program).subscribe(data => {

        this.virtualServices = data;
        const bookingUrl = this.route.snapshot.params.bookingUrl;

        if (bookingUrl) {
          this.selectedService = this.virtualServices.find(f => f.bookingSystemName === bookingUrl);
        }

        this.isLoading = false;
      })
    );
  }

  loadProgramingConfig(programRoute: string) {
    let programConfig = waveConfig.find(c => c.program === programRoute);
    if (programConfig) {
      this.bookingStepsConfig = programConfig.bookingStepsConfig?.stepOrder?.map(r => {
        const c: IBookingStepConfig = {
          step: r.step as BookingStep,
          title: r.title,
          shortTitle: r.shortTitle,
          class: r.class
        };
        return c;
      });

      this.isLoading = true;
      this.getServiceList(programRoute);
    }

  }

  loadIntialStep() {
    if (this.bookingStepsConfig?.length > 0) {
      this.currentBookingStep = this.bookingStepsConfig[0].step;
      this.mobileStepperList = this.bookingStepsConfig.map((item, index) => {
        return {
          count: index + 1,
          text: item.title
        }
      });

      this.mobileStepper = this.mobileStepperList[0];
    }

  }

  public bookingStatusUpdate(event) {
    if (this.bookingDetails.length === 0) {
      this.bookingDetails = event;
    } else {
      event.forEach(item => this.bookingDetails.push(item))
    }
  }



  changeService(virtual: IVirtualservice) {
    this.serviceId = +virtual.bookingSystemId;
    this.description = virtual.description[0].value;
    this.serviceName = virtual.displayName[0].value;
    this.duration = +virtual.duration;
  }

  serviceSelected(service: IVirtualservice) {
    this.selectedService = service;
    this.changeService(this.selectedService);
    this.next();
  }

  timeSelected(timeSlot: Moment) {
    this.selectedTimeSlot = timeSlot;
    this.next()
  }

  specialInstructionsEntered(Instructions: string) {
    this.specialInstructions = Instructions;
    this.next()
  }

  switchLoginRegister(event) {
    this.hasAccount = event;
  }

  next(currentStep?: BookingStep) {
    const currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step === this.currentBookingStep);
    this.bookingStepsConfig[currentStepIndex].class = "active";
    this.bookingStepsConfig[currentStepIndex + 1].class = "current";
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex + 1].step;
    const s = this.mobileStepperList.findIndex(f => f === this.mobileStepper)
    this.mobileStepper = this.mobileStepperList[s + 1];
  }

  previousStep(event?) {
    let currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step == this.currentBookingStep);
    if (currentStepIndex == -1) {
      console.log('not found');
      return;
    }
    // mobile stepper
    const s = this.mobileStepperList.findIndex(f => f === this.mobileStepper)

    if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS') {
      const l = this.bookingDetails.length;
      this.bookingDetails.splice(l - 2, 2);
      console.log(this.bookingDetails);
    } else if (this.mobileStepper.text === "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME") {
      this.bookingDetails = [];
    }

    this.mobileStepper = this.mobileStepperList[s - 1];
    // make the current step not done
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "notdone";
    this.removeSelectedStepData(this.currentBookingStep);

    currentStepIndex = currentStepIndex - 1;
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex].step;
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "current";
    this.isLoading = false;
  }

  public removeSelectedStepData(currentStep: BookingStep) {
    switch (currentStep) {
      case this.bookingStepsEnum.chooseService:
        this.selectedTimeSlot = null;
        break;
      case this.bookingStepsEnum.selectTime:
        this.specialInstructions = null;
        break;
      case this.bookingStepsEnum.specialInstructions:
        break;
      case this.bookingStepsEnum.loginRegister:
        break;
      case this.bookingStepsEnum.completed:
      default:
        break;
    }
  }

  registrationEntered(userRegistration: UserBookingInformation) {
    if (userRegistration) {
      this.isLoading = true;
      this.showHeader = false;

      const bookingRequest: VirtualServiceBookingRequest = new VirtualServiceBookingRequest(
        userRegistration,
        Number(this.selectedService.bookingSystemId),
        this.selectedTimeSlot.format(),
        this.specialInstructions,
        '0'
      );

      this.addSubscription(
        this._virtualservice.createVirtualEventForNewUser(
          bookingRequest
        ).subscribe(data => {
          this.isLoading = false;
          this.bookingCompletedUserName = userRegistration.firstName.toUpperCase();
          this.bookingCompletedSuccessMessage = 'VIRTUALCARE.SUCCESS.SUCCESS_MESSAGE';
          if (this.hasAccount) {
            this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_EXISTING';
          } else {
            this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_NEW';
          }

          this.next();
        }, error => {
          this.isLoading = false;
          switch (error.status) {
            case 409:
              this.hasErrorBooking = true;
              break;
            default:
              this.hasErrorBooking = true;
          }
        })
      );
    }
  }

  public registrationEnteredExisting(userRegistration: UserBookingInformation,) {
    if (userRegistration) {
      this.isLoading = true;
      this.showHeader = false;
      const bookingRequest: VirtualServiceBookingRequest = new VirtualServiceBookingRequest(
        userRegistration,
        Number(this.selectedService.bookingSystemId),
        this.selectedTimeSlot.format(),
        this.specialInstructions,
        '0'
      );

      if (userRegistration.clientId) {
        this.addSubscription(
          this._virtualservice.createVirtualEventForCareUser(
            bookingRequest
          ).subscribe(data => {
            this.isLoading = false;
            this.bookingCompletedUserName = userRegistration.firstName.toUpperCase();
            this.bookingCompletedSuccessMessage = 'VIRTUALCARE.SUCCESS.SUCCESS_MESSAGE';
            if (this.hasAccount) {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_EXISTING';
            } else {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_NEW';
            }

            this.next();

          }, error => {
            this.isLoading = false;
            switch (error.status) {
              case 409:
                this.hasErrorBooking = true;
                break;
              default:
                this.hasErrorBooking = true;

            }
          })
        );
      } else {
        this.addSubscription(
          this._virtualservice.createVirtualEventForNonCareUser(
            bookingRequest
          ).subscribe(data => {
            this.isLoading = false;
            this.bookingCompletedUserName = userRegistration.firstName.toUpperCase();
            this.bookingCompletedSuccessMessage = 'VIRTUALCARE.SUCCESS.SUCCESS_MESSAGE';
            if (this.hasAccount) {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_EXISTING';
            } else {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_NEW';
            }

            this.next();

          }, error => {
            this.isLoading = false;
            switch (error.status) {
              case 409:
                this.hasErrorBooking = true;
                break;
              default:
                this.hasErrorBooking = true;

            }
          })
        );
      }
    }
  }

}
