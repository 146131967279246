import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../../shared/components/Base/base.component';
import { IVirtualservice } from '../../../../model/virtualservice';
import { VirtualcareService } from '../../../../services/virtualcare.service';
import { BookingStep } from '../../../../enum/booking-step.enum';
import { VirtualServiceType } from '../../../../enum/virtual-service-type.enum';
import { Provider } from '../../../../model/provider';
import { Moment } from 'moment-timezone';
import { UserBookingInformation } from '../../../../model/user-booking-information';
import { VirtualServiceBookingRequest } from '../../../../model/virtual-service-booking-request';
import { logoSrc } from 'src/app/enum/logo-src';
import waveConfig from '../../../../config/wave.config';
import { IBookingStepConfig } from 'src/app/config/IBookingStepConfig';


export interface MobileStepper {
  text: string,
  count: number
};

@Component({
  selector: 'app-pharmacy-schedule-appointment',
  templateUrl: './pharmacy-schedule-appointment.component.html',
  styleUrls: ['./pharmacy-schedule-appointment.component.sass']
})

export class PharmacyScheduleAppointmentComponent extends BaseComponent implements OnInit {
  public bookingStepsEnum = BookingStep;
  public currentBookingStep: BookingStep;
  public mobileStepper: MobileStepper;
  public mobileStepperList: MobileStepper[];
  public bookingDetails: any[] = [];
  public showHeader: boolean = true;
  public serviceTypeEnum: any;

  //TODO: assign controls dynamically in the config
  public bookingStepsConfig: Array<{ step: BookingStep, title: string, class: string }> = [
    { step: BookingStep.chooseService, title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", class: "current" },
    { step: BookingStep.chooseProvider, title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", class: "notdone" },
    { step: BookingStep.selectTime, title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", class: "notdone" },
    { step: BookingStep.specialInstructions, title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", class: "notdone" },
    { step: BookingStep.loginRegister, title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", class: "notdone" },
    { step: BookingStep.completed, title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", class: "notdone" }
  ];

  public virtualServices: IVirtualservice[] = [];
  public selectedService: IVirtualservice;
  public virtualServiceProviders: Provider[] = [];
  public selectedProvider: Provider;
  public selectedTimeslot: Moment;
  public specialInstructions: string;
  public bookingCompletedUserName: string;
  public bookingCompletedConfirmation: string;
  public bookingCompletedSuccessMessage: string;

  public isLoading: boolean = false;
  public hasErrorBooking: boolean = false;
  public hasAccount: boolean = false;

  //className = "togglehead";
  //serviceId = 0;
  description = ""
  public serviceName = "";
  public duration: number;
  public serviceId: number;

  public stepperStatus = "stepper__list__item stepper__list__item--current"

  public bookingUrl: string;
  public logo: string;
  public isInternal: boolean = false;
  public programRoute: string;
  public programConfig: any;

  constructor(
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private virtualServicesService: VirtualcareService,
    private route: ActivatedRoute,
    private router: Router
  ) { super(); }

  ngOnInit(): void {
    this.logo = logoSrc.PHARMACY;
    this.isLoading = true;
    this.serviceTypeEnum = VirtualServiceType.PHARMACY;
    this.currentBookingStep = BookingStep.chooseService;
    this.route.params.subscribe(params => {

      if (params['bookingUrl']) {
        this.bookingUrl = params['bookingUrl'];
      }
    });

    this.route.queryParams.subscribe(param => {
      if (param['internal']) {
        this.isInternal = true;
      }
    });

    this.programRoute = this.route.snapshot.params.programRoute
    if (this.programRoute) {
      if (this.loadProgramFromConfig(this.programRoute)) {
        this.addSubscription(
          this.virtualServicesService.getVirtualServices(this.serviceTypeEnum, this.programRoute).subscribe(data => {
            this.isLoading = false;
            this.virtualServices = data;
          })
        );
      } else {
        this.router.navigateByUrl(`virtual-care/services`)
      }
    } else {
      this.addSubscription(
        this.virtualServicesService.getVirtualServices(this.serviceTypeEnum).subscribe(data => {
          this.isLoading = false;
          let unique_arr : Array<any> = data.filter((v,i,a)=>a.findIndex(v2=>(v2.bookingSystemName==v.bookingSystemName))===i)
          this.virtualServices = unique_arr;
        })
      );
    }

    this.mobileStepperList = this.bookingStepsConfig.map((item, index) => {
      return {
        count: index + 1,
        text: item.title
      }
    })

    this.mobileStepper = this.mobileStepperList[0];
  }

  loadProgramFromConfig(program: string): boolean {
    let isConfigFound = false;
    this.programConfig = waveConfig.find(c => c.program === program);

    if (this.programConfig) {
      this.bookingStepsConfig = this.programConfig.bookingStepsConfig?.stepOrder.map(
        (item: IBookingStepConfig) => {
          const c: IBookingStepConfig = {
            step: item.step as BookingStep,
            title: item.title,
            shortTitle: item.shortTitle,
            class: item.class
          }

          return c;
        }
      )

      if (this.bookingStepsConfig) {
        isConfigFound = true
      }
    }

    return isConfigFound;
  }


  changeService(virtual: IVirtualservice) {
    this.serviceId = +virtual.bookingSystemId;
    this.description = virtual.description[0].value;
    this.serviceName = virtual.displayName[0].value;
    this.duration = +virtual.duration;

    //this.next(BookingStepEnum.chooseService);
  }

  public bookingStatusUpdate(event) {
    if (this.bookingDetails.length === 0) {
      this.bookingDetails = event;
    } else {
      event.forEach(item => this.bookingDetails.push(item))
    }
  }

  onPreviousBookingStep() {
    const i = this.bookingStepsConfig.findIndex(f => f.step === this.currentBookingStep);
    this.currentBookingStep = this.bookingStepsConfig[i - 1].step;
  }

  serviceSelected(selectedService: IVirtualservice) {
    this.selectedService = selectedService;
    this.next();
  }

  public providerSelected(selectedProvider: { provider: Provider, service: IVirtualservice }) {
    this.selectedProvider = selectedProvider.provider;
    this.next();
  }

  public timeslotSelected(selectedTimeslot: Moment) {
    this.selectedTimeslot = selectedTimeslot;
    this.next();
  }

  public specialInstructionsEntered(specialInstructions: string) {
    this.specialInstructions = specialInstructions;
    this.next();
  }
  public switchLoginRegister(event) {
    this.hasAccount = event;
  }

  public registrationEnteredNew(userRegistration: UserBookingInformation) {
    if (userRegistration) {
      this.showHeader = false;
      this.isLoading = true;

      const bookingRequest: VirtualServiceBookingRequest = new VirtualServiceBookingRequest(
        userRegistration,
        Number(this.selectedService.bookingSystemId),
        this.selectedTimeslot.format(),
        this.specialInstructions,
        this.selectedProvider.id
      );

      // //sessionStorage.setItem("finalList", JSON.stringify(this.subList))

      this.addSubscription(
        this.virtualServicesService.createVirtualEventForNewUser(
          bookingRequest
        ).subscribe(data => {
          this.isLoading = false;
          this.bookingCompletedUserName = userRegistration.firstName.toUpperCase();
          this.bookingCompletedSuccessMessage = 'VIRTUALCARE.SUCCESS.SUCCESS_MESSAGE';
          if (this.hasAccount) {
            this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_EXISTING';
          } else {
            this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_NEW';
          }

          this.next();

        }, error => {
          this.isLoading = false;
          switch (error.status) {
            case 409:
              this.hasErrorBooking = true;
              break;
            default:
              this.hasErrorBooking = true;

          }
        })
      );
    }
  }

  public registrationEnteredExisting(userRegistration: UserBookingInformation,) {
    if (userRegistration) {
      this.isLoading = true;
      this.showHeader = false;
      const bookingRequest: VirtualServiceBookingRequest = new VirtualServiceBookingRequest(
        userRegistration,
        Number(this.selectedService.bookingSystemId),
        this.selectedTimeslot.format(),
        this.specialInstructions,
        this.selectedProvider.id
      );

      if (userRegistration.clientId) {
        this.addSubscription(
          this.virtualServicesService.createVirtualEventForCareUser(
            bookingRequest
          ).subscribe(data => {
            this.isLoading = false;
            this.bookingCompletedUserName = userRegistration.firstName.toUpperCase();
            this.bookingCompletedSuccessMessage = 'VIRTUALCARE.SUCCESS.SUCCESS_MESSAGE';
            if (this.hasAccount) {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_EXISTING';
            } else {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_NEW';
            }

            this.next();

          }, error => {
            this.isLoading = false;
            switch (error.status) {
              case 409:
                this.hasErrorBooking = true;
                break;
              default:
                this.hasErrorBooking = true;

            }
          })
        );
      } else {
        this.addSubscription(
          this.virtualServicesService.createVirtualEventForNonCareUser(
            bookingRequest
          ).subscribe(data => {
            this.isLoading = false;
            this.bookingCompletedUserName = userRegistration.firstName.toUpperCase();
            this.bookingCompletedSuccessMessage = 'VIRTUALCARE.SUCCESS.SUCCESS_MESSAGE';
            if (this.hasAccount) {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_EXISTING';
            } else {
              this.bookingCompletedConfirmation = 'VIRTUALCARE.SUCCESS.CONFIRMATION_MESSAGE_NEW';
            }

            this.next();

          }, error => {
            this.isLoading = false;
            switch (error.status) {
              case 409:
                this.hasErrorBooking = true;
                break;
              default:
                this.hasErrorBooking = true;

            }
          })
        );
      }
    }
  }

  next(currentStep?: BookingStep) {
    const currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step === this.currentBookingStep);
    this.bookingStepsConfig[currentStepIndex].class = "active";
    this.bookingStepsConfig[currentStepIndex + 1].class = "current";
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex + 1].step;
    this.mobileStepper = this.mobileStepperList[currentStepIndex + 1];

  }

  public previousStep(event?) {
    let currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step == this.currentBookingStep);
    if (currentStepIndex == -1) {
      console.log('not found');
    }
    // mobile stepper
    if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS') {
      const l = this.bookingDetails.length;
      this.bookingDetails.splice(l - 2, 2);
    } else if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME') {
      this.bookingDetails.pop();
    } else if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER') {
      this.bookingDetails = [];
    }
    this.mobileStepper = this.mobileStepperList[currentStepIndex - 1];

    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = 'notdone'
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex - 1].step;
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "current";
    this.isLoading = false;


    this.isLoading = false;
  }
}
