import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppFooterComponent } from '../public/app-footer/app-footer.component';
import { LanguageToggleComponent } from "./components/language-toggle/language-toggle.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ReCaptchaDirective } from "./directives/re-captcha.directive";




const components = [
  LanguageToggleComponent,
  ReCaptchaDirective,
  AppFooterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: components,
  exports: [TranslateModule, ...components],
})
export class SharedModule { }
