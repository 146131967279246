export class Contact {
  constructor(
    public contactId?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public phone?: string,
    public language?: string,
    public enabled?: boolean
  ) {}

  static fromJson(json: any) {
    if (!json) {
      return undefined;
    }
    return new Contact(
      json.contactId,
      json.firstName,
      json.lastName,
      json.email,
      json.phone,
      json.language,
      json.familyPortalEnabled
    );
  }
}
