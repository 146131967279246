
<div class="modal-header" >
    <h4 class="modal-title"> <span *ngIf="!isCancelled">{{hasError ? errorMessage: 'Are you sure want to cancel this appointment?'}}</span></h4>
    <a href="#" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="material-icons bs-language-toggle-icon">
            close
          </i>
        </a>
</div>
<div class="modal-body" *ngIf="!isCancelled && !hasError">
    <div class="row mb-10 service-section">
        <div class="col-md-12"><h5>Service: {{selecetdServiceName}}</h5></div>
        <div class="col-md-12"><h5>Selecetd Date: {{selecetdDate}}</h5></div>
    </div>
    <div class="row mb-10" *ngIf="reasonFlag">
        <div class="col-md-12">
            <div class="cancel-form">
                <label for="email">Cancel reason*</label>
                <textarea name="cancelReason" class="form-control" rows="5" [ngClass]="{'error': cancelReasonError}"
                    [(ngModel)]="cancelReason"></textarea>
                <div class="cancel-form-error" *ngIf="!cancelReason && formSubmitted">
                    <span class="cancel-form-error--msg">Reason field is required  </span>
                </div>
                <div class="cancel-form-error" *ngIf="cancelErrorMessage && formSubmitted">
                    <span class="cancel-form-error--msg">{{cancelErrorMessage}}  </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <button class="btn cancel-button" (click)="submitCancel()" *ngIf="reasonFlag">Cancel</button>
            <button class="btn cancel-button"  (click)="showReason()" *ngIf="!reasonFlag">Yes, Cancel appointment</button>
        </div>
        <div class="col-md-4">
            <a (click)="bsModalRef.hide()" class="dont-cancel-link">No, don't cancel</a>
        </div>
    </div>

</div>
<div class="modal-body" *ngIf="isCancelled">
    <div class="">
        <div class="cancel-form ">
            <app-success-message [successMessage]="'Appointment has been Cancelled!'" [isOtherFlow]="true"></app-success-message>
        </div>
    </div>
</div>