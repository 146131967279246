<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
<div class="bs-virtualcare-home">
  <div class="bs-virtualcare-home-banner">
    <dxp-text class="bs-virtualcare-home-banner__text">{{'VIRTUALCARE.BANNER.BANNER'|translate}}</dxp-text>&nbsp;
    <div class="bs-virtualcare-home-banner__link" (click)="scrollToElement(covid19)">
      {{'VIRTUALCARE.BANNER.BANNER_LINK'|translate}}
    </div>
  </div>
  <div class="bs-virtualcare-home-language">
    <app-language-toggle [inverted]="true"></app-language-toggle>
  </div>
  <div class="bs-virtualcare-home-logo">
    <img src="/assets/images/wave_logo.svg">
  </div>
  <div class="bs-virtualcare-home-banner__services" (click)="scrollToElement(services)">

    {{'VIRTUALCARE.BANNER.SERVICES'|translate}}

  </div>
  <div class="bs-virtualcare-home-background">
    VIRTUAL
  </div>
  <div class="bs-virtualcare-home-content">
    <div class="bs-virtualcare-home-content-panel">
      <div class="bs-virtualcare-home-content-panel__title">{{'VIRTUALCARE.HOME.VIRTUAL_CARE_HEADER' | translate}}</div>
      <div class="bs-virtualcare-home-content-panel__virtualcare">{{'VIRTUALCARE.HOME.VIRTUAL_CARE' | translate}}</div>
      <div class="bs-virtualcare-home-content-panel__buttons">
        <dxp-link [greedy]="false" class="bs-virtualcare-home-content-panel__buttons-book"
          [routerLink]="['/virtual-care/services']">
          {{'VIRTUALCARE.HOME.BOOK_APPOINTMENT' | translate}}
        </dxp-link>

        <button (click)="scrollToElement(learnmore)" class="bs-virtualcare-home-content-panel__buttons-learnmore">
          {{'VIRTUALCARE.HOME.LEARN_MORE' | translate}}
        </button>
      </div>
    </div>
    <div class="bs-virtualcare-home-content-logo d-none d-lg-block">
      &nbsp;
    </div>
  </div>
</div>

<div class="bs-virtualcare-learnmore" #learnmore>
  <div class="bs-virtualcare-learnmore-content">
    <div class="bs-virtualcare-learnmore-content-logo d-none d-lg-block">
      <img src="/assets/images/Mobile_image.png" width="560px">
    </div>
    <div class="bs-virtualcare-learnmore-content-panel">
      <div class="bs-virtualcare-learnmore-content-panel__title">
        {{'VIRTUALCARE.LEARN_MORE.VIRTUAL_CARE_TITLE' | translate}}
      </div>
      <div class="bs-virtualcare-learnmore-content-panel__description">
        {{'VIRTUALCARE.LEARN_MORE.VIRTUAL_CARE_DESCRIPTION' | translate}}
      </div>
      <div class="bs-virtualcare-learnmore-content-panel__list">
        <div class="bs-virtualcare-learnmore-content-panel__list-start">
          <div class="bs-virtualcare-learnmore-content-panel__list-icon">
          </div>
          <div class="bs-virtualcare-learnmore-content-panel__list-text">
            {{'VIRTUALCARE.LEARN_MORE.BOOK_APPOINTMENTS' | translate}}
          </div>
        </div>
        <div class="bs-virtualcare-learnmore-content-panel__list-start">
          <div class="bs-virtualcare-learnmore-content-panel__list-icon">
          </div>
          <div class="bs-virtualcare-learnmore-content-panel__list-text">
            {{'VIRTUALCARE.LEARN_MORE.WAIT_tIMES' | translate}}
          </div>
        </div>
        <div class="bs-virtualcare-learnmore-content-panel__list-start">
          <div class="bs-virtualcare-learnmore-content-panel__list-icon">
          </div>
          <div class="bs-virtualcare-learnmore-content-panel__list-text">
            {{'VIRTUALCARE.LEARN_MORE.TRAVEL_TIME' | translate}}
          </div>
        </div>
        <div class="bs-virtualcare-learnmore-content-panel__list-start">
          <div class="bs-virtualcare-learnmore-content-panel__list-icon">
          </div>
          <div class="bs-virtualcare-learnmore-content-panel__list-text">
            {{'VIRTUALCARE.LEARN_MORE.CONDITION' | translate}}
          </div>
        </div>
      </div>
      <div class="bs-virtualcare-learnmore-content-panel__buttons">
        <dxp-link [greedy]="false" [inverted]="true" class="bs-virtualcare-learnmore-content-panel__buttons-book"
          [routerLink]="['/virtual-care/services']">
          {{'VIRTUALCARE.HOME.BOOK_APPOINTMENT' | translate}}
        </dxp-link>
      </div>
    </div>
  </div>
</div>
<div id="services" #services>
  <app-virtualcare-services></app-virtualcare-services>
</div>
<div id="covid19" #covid19>
  <app-covid19-awareness></app-covid19-awareness>
</div>
<app-footer></app-footer>