import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VirtualServiceType } from '../enum/virtual-service-type.enum';
import { IVirtualService } from '../model/interface/IVirtualService';
import { ICaremanagerservice } from '../model/caremanager';
import { Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { Moment } from 'moment';
import * as moment from 'moment';
import { BaseService } from './base.service';
import { TimeSlots } from '../model/timeslot';
import { VirtualServiceBookingRequest } from '../model/virtual-service-booking-request';
import { IVirtualEventRescheduleRequest } from '../model/virtualservice';

@Injectable({
  providedIn: 'root'
})
export class VirtualcareService extends BaseService {

  private serviceJsonURL: string = "assets/JSONData/virtualservice.JSON";
  private careManagerJsonURL: string = "assets/JSONData/caremanager.JSON"

  constructor(
    private http: HttpClient
  ) {
    super();
  }
  //{ serviceType = VirtualServiceType.PUBLIC, program }: { serviceType?: string; program?: string; } = {}
  public getVirtualServices(serviceType: VirtualServiceType = VirtualServiceType.PUBLIC, program?: string): Observable<IVirtualService[]> {

    let params: any;
    if (program) {
      params = new HttpParams().set('serviceType', serviceType).set('program', program);
    } else {
      params = new HttpParams()
        .set('serviceType', serviceType);
    }

    return this.http.get<IVirtualService[]>(`${this.apiBase}/virtual-services`, { params }).pipe(
      map(json => json ? (json as any[]).map(j => j) : []),
      //tap({ error: (error) =>  }),
      shareReplay(1)
    );
  }

  // getCareManager(): Observable<ICaremanagerservice[]> {
  //   return this.http.get<ICaremanagerservice[]>(this.careManagerJsonURL)
  // }

  getTimeSlot(bookingSystemId: string, startDate: Moment, endDate: Moment, providerId?: string): Observable<TimeSlots[]> {
    const timezone = moment.tz.guess();
    let params = new HttpParams()
      .set('timezone', timezone)
      .set('startDate', startDate.format("YYYY-MM-DD"))
      .set('endDate', endDate.format("YYYY-MM-DD"));

    if (providerId) {
      params = params.set('providerId', providerId);
    }

    const url = `${this.apiBase}/virtual-services/` + bookingSystemId.toString() + `/booking-slots`;

    return this.http.get(url, { params }).pipe(
      map(json => json ? (json as any[]).map(j => TimeSlots.fromJson(j)) : [])
      // tap({ error: (error) => this.handleError(error) })
    );

    // return this.http.get<TimeSlotService[]>(`${api.basename}/virtual-services/` + sessionStorage.getItem("bookingSystemId") + `/booking-slots?startDate=` + sessionStorage.getItem("startDate") + `&endDate=` + sessionStorage.getItem("finalDate"))
  }

  getCareManager(): Observable<ICaremanagerservice[]> {
    return this.http.get<ICaremanagerservice[]>(this.careManagerJsonURL)
  }

  postFinal() {
    return this.http.post(`${this.apiBase}/virtual-events`, JSON.parse(sessionStorage.getItem("finalList")))
  }

  public createVirtualEventForNewUser(bookingRequest: VirtualServiceBookingRequest) {
    return this.http.post(`${this.apiBase}/virtual-events`, bookingRequest.toRequestJson());
  }

  public createVirtualEventForProvider(providerId: string, bookingRequest: VirtualServiceBookingRequest) {
    return this.http.post(`${this.apiBase}/virtual-providers/${providerId}/virtual-events`, bookingRequest.toRequestJson());
  }

  public createVirtualEventForCareUser(bookingRequest: VirtualServiceBookingRequest) {
    return this.http.post(`${this.apiBase}/${bookingRequest.userRegistration.system}/clients/${bookingRequest.userRegistration.clientId}/virtual-events`, bookingRequest.toRequestJson());
  }
  public createVirtualEventForNonCareUser(bookingRequest: VirtualServiceBookingRequest) {
    return this.http.post(`${this.apiBase}/portal-users/${bookingRequest.userRegistration.cognitoId}/virtual-events`, bookingRequest.toRequestJson())
  }
  createEventForCareUser(system, clientId) {
    return this.http.post(`${this.apiBase}/${system}/clients/${clientId}/virtual-events`, JSON.parse(sessionStorage.getItem("finalList")))
  }
  createEventForNonCareUser(cognitoId) {
    return this.http.post(`${this.apiBase}/portal-users/${cognitoId}/virtual-events`, JSON.parse(sessionStorage.getItem("finalList")))
  }
  
  /**
   * cancelVirtualEvent
   */
  public cancelVirtualEvent(appointmentId:any, signature:string, cancelReason:string) {
    let bookingRequest = {signature, cancelReason};
    return this.http.put(`${this.apiBase}/guest/virtual-events/${appointmentId}/cancel`, bookingRequest);
   
  }

  /**
   * getGuestUserAppointmentData
   */
  public getGuestUserAppointmentData(signature:string) {
    return this.http.get(`${this.apiBase}/guest/virtual-events?token=${signature}`).pipe(
      map(json => json as any)
    );
  }

  /**
   * rescheduleVirtualEventForGuestUser
   */
  public rescheduleVirtualEventForGuestUser(
    bookingRequest: IVirtualEventRescheduleRequest,
    appointmentId:Number) {
    return this.http.put(`${this.apiBase}/guest/virtual-events/${appointmentId}`, bookingRequest);
  }
}
