import { Moment } from 'moment';
import * as moment from 'moment';

export class TimeSlot {
  constructor(
    public time: Moment | undefined,
    public isSelected: boolean,
    public isEnabled: boolean
  ) { }

  public static fromJson(time) {
    return new TimeSlot(
      time ? moment(time) : undefined,
      false,
      !!time
    )
  }
};

export class TimeSlots {
  constructor(
    public date: Moment,
    public times: TimeSlot[]
  ) { }

  public static fromJson(slot) {
    if (!slot) {
      return null;
    }

    return new TimeSlots(
      moment(slot.date),
      slot.times && slot.times.length > 0 ? slot.times.map(time => TimeSlot.fromJson(time.datetime)) : [new TimeSlot(undefined, false, false)]
    );
  }
}