import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { TermsAndConditionsComponent } from './public/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { AccessibilityComponent } from './public/accessibility/accessibility.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'accessibility', component: AccessibilityComponent },
  { path: 'virtual-care', loadChildren: () => import('./virtual-care/virtual-care.module').then(m => m.VirtualCareModule) }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
