import { OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';

// TODO: Add Angular decorator.
@Component({
  template: ''
})
export class BaseComponent implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  protected addSubscription(subscription: Subscription) {
    if (!this.subscriptions) {
      this.subscriptions = subscription;
    } else {
      this.subscriptions.add(subscription);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  protected decodeHTMLEntities(text: string): string {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  //TODO: pass in the form
  // public getError(field, errorMessages) {
  //   return getErrorMessageByReactiveFormError(this.accountDetailsForm.get(field), errorMessages);
  // }
}
