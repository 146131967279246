import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualCareRoutingModule } from './virtual-care-routing.module';
import { VirtualCareComponent } from './virtual-care.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicModule } from '@bayshore-healthcare/dxp-angular-lib';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { SharedModule } from '../shared/shared.module';
import { BayshoreServicesComponent } from './components/bayshore-services/bayshore-services.component';
import { ProviderSelectionComponent } from './components/provider-selection/provider-selection.component';
import { TimeslotSelectionComponent } from './components/timeslot-selection/timeslot-selection.component';
import { TimeslotComponent } from './components/timeslot/timeslot.component';
import { SpecialInstructionComponent } from './components/special-instruction/special-instruction.component';
import { BookingDetailsComponent } from './components/booking-details/booking-details.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TextMaskModule } from 'angular2-text-mask';
import { PharmacyScheduleAppointmentComponent } from './intake-pathways/pharmacy/pharmacy-schedule-appointment/pharmacy-schedule-appointment.component';
import { PublicScheduleAppointmentComponent } from './intake-pathways/public/public-schedule-appointment/public-schedule-appointment.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ProviderScheduleAppointmentComponent } from './intake-pathways/provider-initiated/provider-schedule-appointment/provider-schedule-appointment.component';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { ServiceListComponent } from './intake-pathways/service-list/service-list.component';
import { UserConsentComponent } from './components/user-consent/user-consent.component';
import { AppointmentCancelComponent } from './components/appointment-cancel/appointment-cancel.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { RescheduleComponent } from './intake-pathways/reschedule/reschedule.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';


const globalSettings: RecaptchaSettings = { siteKey: '6Lc_o_cUAAAAAJAb08B-h3GB2pJGcf-kWNtfnyRj', size: 'normal' };

@NgModule({
  declarations: [
    PharmacyScheduleAppointmentComponent,
    ProviderScheduleAppointmentComponent,
    PublicScheduleAppointmentComponent,
    VirtualCareComponent,
    BayshoreServicesComponent,
    ProviderSelectionComponent,
    TimeslotSelectionComponent,
    TimeslotComponent,
    SpecialInstructionComponent,
    BookingDetailsComponent,
    LoginComponent,
    RegistrationComponent,
    SuccessMessageComponent,
    StepperComponent,
    ServiceListComponent,
    UserConsentComponent,
    AppointmentCancelComponent,
    RescheduleComponent,
    ErrorMessageComponent
  ],
  imports: [
    CommonModule,
    VirtualCareRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSpinnerModule,
    TextMaskModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule,
    HttpClientModule,
    BasicModule,
    SharedModule,
    ModalModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    BsModalService
  ],
})
export class VirtualCareModule { }
