<!-- Normal Screens -->
<div class="stp-container">
  <div class="stp-item {{s.class}}" *ngFor="let s of bookingSteps">
    <span class="stp-item__text">
      {{s.title | translate}}
    </span>
  </div>
</div>
<!-- Mobile Screens -->
<div class="mobile-header" *ngIf="showHeader">
  <div class="mobile-stepper" *ngIf="step">
    <div class="mobile-stepper__icon" (click)="previousStep()" *ngIf="isBackButton">
      <img src='../../../../../../../assets/images/arrow-left.svg' />
    </div>
    <div class="mobile-stepper__status">
      <div class="mobile-stepper__status--count">{{count}}</div>
      <div class="mobile-stepper__status--text">{{step | translate}}</div>
    </div>
  </div>
  <div *ngIf="isBackButton">
    <app-booking-details [stepsCompleted]="bookingDetails"></app-booking-details>
  </div>
</div>
