<div class="row">
  <div class="col-md-12">
    <div class="booking-card">
      <div class="booking-card-logo">
        <img src="{{logo}}" />
      </div>
      <h3 [ngClass]="logo?.length > 0 ? 'booking-card-header-left' : 'booking-card-header-center'">
        {{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.YOUR_BOOKING_DETAILS'|translate}}
      </h3>
      <div class="provider-flow-container" *ngIf="!isPublicFlow">
        <div class="provider-details">
          <div class="provider-details__icon">
            {{selectedProvider.firstName.slice(0, 1)}}{{selectedProvider.lastName.slice(0, 1)}}
          </div>
          <div id="booking_provider_specialty" class="provider-details__main">
            {{selectedProvider.specialty}}
          </div>
          <div id="booking_provider_name" class="provider-details__main">
            {{selectedProvider.firstName}} {{selectedProvider.lastName}}
          </div>
          <div id="booking_provider_description" class="provider-details__desc">
            {{selectedProvider.description}}
          </div>
        </div>
        <div class="service-details">
          <div class="service-details__service">
            <div class="service-details__service__title">
              {{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.BAYSHORE_SERVICE'|translate}}
            </div>
            <div id="booking_service_name" class="service-details__service__name">{{selectedService.bookingSystemName}}
            </div>
          </div>
          <div class="service-details__slot">
            <div class="service-details__slot__title">
              {{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.SELECTED_TIME'|translate}}</div>
            <div id="booking_timeslot_day" class="service-details__slot__time">
              <img src="/assets/images/calander.svg">{{selectedDay}}
            </div>
            <div id="booking_timeslot_time" class="service-details__slot__time">
              <img src="/assets/images/time.svg">{{selectedTimeslot}}
            </div>
          </div>
        </div>
      </div>
      <div class="public-flow-container">
        <div class="public-flow-container-box" *ngIf="isPublicFlow">
          <div class="col-md-6" style="white-space: pre-line;">
            <div class="pro-details-left">
              <h4 class="text-left">
                {{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.BAYSHORE_SERVICE'|translate}}</h4>
              <p class="text-left " id="booking_service_name">{{selectedService?.bookingSystemName}}</p>
            </div>
          </div>
          <div class="col-md-6" style="white-space: pre-line;">
            <div class="pro-details-right">
              <h4 class="text-left ">
                {{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.SELECTED_TIME'|translate}}</h4>
              <p class="mb-2 text-left " id="booking_timeslot_day">
                <img src="/assets/images/calander.svg">{{selectedDay}}
              </p>
              <p class="text-left " id="booking_timeslot_time">
                <img src="/assets/images/time.svg">{{selectedTimeslot}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div class="booking-mobile">
      <div class="booking-mobile__card">
        <div class="booking-mobile__card--item" *ngFor="let step of stepsCompleted">
          <div class="booking-mobile__card--item--text" id="stepper_{{step}}">{{step}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
