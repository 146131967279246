<div class="bs-virtualcare">
    <div class="bs-virtualcare-header">
        <div class="bs-virtualcare-header-logo">
            <img src="/assets/images/wave_logo.svg" class="header-logo-img">
        </div>
        <div class="bs-virtualcare-header-language">
            <app-language-toggle [inverted]="true"></app-language-toggle>
        </div>
    </div>
    <div class="bs-virtualcare-content">
        <div class="privacy-content">
            <h1 class="privacy-policy-h1">Privacy Policy</h1>
            <h2 class="privacy-policy-h2">Privacy Commitment</h2>
            <p>Bayshore Home Health (&ldquo;Bayshore&rdquo;) is and always has been committed to ensuring the
                confidentiality
                and protection of personal information in its possession.</p>
            <p>Bayshore&rsquo;s Privacy Management Program is governed by the information handling practices of the
                Personal
                Information Protection and Electronic Documents Act (PIPEDA), Personal Health Information Protection Act
                (PHIPA), and applicable provincial and professional standards. Bayshore has also adopted the European
                Union&rsquo;s General Data Protection Regulation (GDPR).</p>
            <p>Bayshore collects, uses, discloses, retains and destroys personal information for the sole purpose of
                providing
                you high-quality health care services. Bayshore employees can expect the same confidentiality and
                privacy
                of their personal information as our clients and customers.</p>
            <h2 class="privacy-policy-h2">We Are Accountable</h2>
            <p>At Bayshore, all employees are accountable and committed to ensuring that your personal information is
                held in
                the strictest confidence. Bayshore&rsquo;s Privacy Officer is responsible for the overall compliance to
                the requirements of all privacy legislation and Bayshore privacy practices. However, all Bayshore
                employees are accountable for maintaining the privacy and confidentiality of all information they have
                access
                to in their course of their work with us. To help ensure this, all Bayshore staff are trained in our
                Privacy Management Program and acceptable privacy practices.</p>
            <p>As required, Bayshore will amend this document and its Privacy Management Program when changes to privacy
                legislation or best practices are known.</p>
            <h2 class="privacy-policy-h2">What We Collect and Why</h2>
            <p>Bayshore staff will only ask you about information that we feel is necessary for the purpose of providing
                your
                care and service. We do not indiscriminately collect information, and constantly review our practices to
                ensure this remains true.</p>
            <p>For example, when you request or require home support or personal support services, we collect
                information
                regarding your general health status and ability to perform activities of daily living, so that we are
                best able to evaluate how we can support you. For nursing services, we conduct a more thorough health
                assessment to the degree appropriate to your condition and level of care required, and in keeping with
                the
                standards of provincial professional licensing bodies.</p>
            <p>For all services, we may ask you questions specifically to help us determine how you are managing at home
                with
                your current health status. This is to help us put a care plan or resources in place to allow you to
                remain independent in your home.</p>
            <p>If at any time we require more or different information, or your information is required for a purpose
                other than
                the care and service discussed with you, you will be contacted and your consent will be obtained.</p>
            <p>Our staff will endeavor to ensure that all personal information is accurate, complete and up-to- date, so
                that we
                can provide you with the appropriate care and services. We need your assistance in doing this; however,
                please let us know if any information relevant to your health care needs to be changed. Your information
                is not kept up-to-date after you have been discharged from service.</p>
            <h2 class="privacy-policy-h2">Your Permission</h2>
            <p>At the start of your care, we will ask you to sign a Client Consent, giving us your expressed written
                consent for
                all aspects of the service. This may include such things as conducting an assessment, providing your
                care
                and asking your permission regarding release of information. Please let us know if there is anyone with
                whom you do not want your information, in part or in whole to be released to, and we will make note of
                this. All of this will be discussed with you by our supervisor or nurse.</p>
            <p>Your consent is considered valid through to the end of your service, provided the reasons for the consent
                haven&rsquo;t changed. You may withdraw your permission or consent in part or in whole, however, at any
                time during your care and service. Should this occur, Bayshore staff will inform you of the implications
                of that withdrawal.</p>
            <p>For those individuals who are unable to provide consent, e.g., minors, those who are seriously ill or
                mentally
                handicapped persons, consent will be obtained by an authorized authority, e.g., legal guardian, power of
                attorney or the executor of your estate.</p>
            <p>Bayshore will seek your consent to disclose personal information outside of the multidisciplinary team or
                &ldquo;circle of care&rdquo; when appropriate access requests are received. Bayshore documents personal
                information disclosures that are made to third parties which would not require your information for
                routine, day-to-day purposes.</p>
            <p>Please contact your location Bayshore office at any time if you wish to withdraw or change the conditions
                of your
                consent.</p>
            <h2 class="privacy-policy-h2">How We Use Your Personal Information</h2>
            <p>Your personal information is only used for the purpose of providing your health care services.
                Information is
                only shared with those individuals involved in your care, and excludes anyone you request. Our nurse
                will
                review this with you at the start of care and will document your wishes on our Client Consent form.
                Finally, your information may be reviewed during auditing and quality improvement activities. Where
                appropriate,
                these activities are done without reference to specific individuals. This is an important aspect of our
                Quality Management System and allows us to continually monitor our performance and improve your
                services.
                Client names are NEVER shared with any individual or organization for the purposes of commercial
                activity,
                such as selling, trading and/or renting your personal information.</p>
            <p>During the course of your service, Bayshore will maintain your personal information as accurately as is
                reasonably necessary for the purpose of providing your service. We need your assistance in this,
                however.
                Please notify us of any relevant changes in your information as soon as possible.</p>
            <h2 class="privacy-policy-h2">Who We Share Your Personal Information With</h2>
            <p>When you start service with Bayshore, and you sign the Client Consent, we discuss with you who we will
                likely
                share your information with &ndash; who are those individuals that we consider the members of your
                &ldquo;multidisciplinary care team&rdquo; or &ldquo;circle of care.&rdquo; All employees of Bayshore do
                not have access to your personal information, even though they are employees of Bayshore and you are a
                client of Bayshore. Information is only shared with individuals involved in your care. At the start of
                care, or anytime during your service, you may inform us of anyone whom you do not want receiving your
                information (in part or in whole).</p>
            <p>In rare cases, we may be required by law or other lawful authority to release personal information, such
                as when
                we receive a court order or subpoena. In such cases, Bayshore will verify the lawful authority prior to
                release the information, and will endeavour to release only what is necessary for the stated purpose of
                the request.</p>
            <h2 class="privacy-policy-h2">How Long We Keep Your Personal Information</h2>
            <p>Bayshore will continue to have access to your information even after your services have been
                discontinued. We
                retain all discontinued client records for a minimum of 10 years, or longer if required by provincial
                regulation. We retain children&rsquo;s records until the child reaches the age of majority plus 10
                years.
                These records, whether at our offices or off-site, are stored in a manner that ensures ongoing security.
                Bayshore uses off-site storage facilities that cite privacy practices which are in compliance with
                relevant privacy legislation and only after a Confidentiality Agreement has been signed with the
                facility.
                Records are kept of the location of files retained, so that we may retrieve them as required. At the end
                of the 10 year period, all information that we have, regardless of format, is destroyed in a
                confidential
                manner.</p>
            <p>In certain circumstances, records created by Bayshore are retained by other health care organizations
                (&ldquo;health information custodian&rdquo;) such as a government care program, medical facility or
                clinical trail group. In these cases, these organizations will be applying their own retention schedule
                to
                these records and access requests will be redirected to them.</p>
            <h2 class="privacy-policy-h2">Safeguards</h2>
            <p>Bayshore considers your health information extremely confidential, and has put many safeguards in place
                to ensure
                its security. Our security measures strive to adhere to PIPEDA and PHIPA requirements in all aspects of
                the work we do for our clients and customers.</p>
            <p>Information is found in many different formats at Bayshore, due to the nature of our work. Your
                information is
                stored electronically, in paper files and in the knowledge of our staff.</p>
            <p>All employees are orientated and trained on privacy practices at the time of hire and sign a
                Confidentiality and
                Conflict of Interest Agreement and Conditions of Employment Agreement at that time. Training occurs
                routinely throughout our staff&rsquo;s employment with Bayshore and information is available about our
                privacy practices to everyone through our staff website.</p>
            <p>The knowledge and information our staff have is considered private and confidential, and is only used for
                the
                purpose of doing their work with Bayshore and is not shared with anyone. At any time, if a caregiver
                receives a request to share information or is asked about any client of Bayshore, he/she will not
                provide
                any information and will forward the request to their supervisor.</p>
            <p>Electronic information is protected by computer firewalls, user names and passwords according to assigned
                authorization levels.</p>
            <p>In order to keep the Personal Information secure, we restrict unauthorized access through protective
                policies,
                procedures, and technical measures, including:</p>
            <p>a) providing reasonable physical and electronic safeguards with regard to the storage of Personal
                Information;
            </p>
            <p>b) limiting access to your Personal Information to those employees or contractors who we reasonably
                believe need
                to come into contact with that information to provide products or services to you or in order to do
                their
                jobs; and</p>
            <p>c) governing employees and other contractors by strict standards and policies to ensure that Personal
                Information
                is secure and treated with the utmost care and respect.</p>
            <p>Hard copy files are protected by being stored in appropriate locations in the branch office, where access
                is
                permitted only to staff with the necessary authority. After hours, these files are kept in a locked,
                secured area.</p>
            <p>Faxes are sent via pre-programmed fax numbers to reduce the possibility of sending information to the
                wrong place
                and a cover letter is sent with a Confidentiality Statement that tells a receiver what to do if they
                receive something in error.</p>
            <p>In addition, email signatures include a confidentiality statement explaining what to do if an email is
                received
                in error. In the case of either a misdirected fax or email, the direction is to contact Bayshore&rsquo;s
                Privacy Officer.</p>
            <p>Bayshore is not responsible for the privacy practices of organizations with links on our website.</p>
            <h2 class="privacy-policy-h2">Your Right To See Your Personal Information</h2>
            <p>If you would like to see your personal information at any time, you may do so by putting your request in
                writing
                and forwarding it to your local Bayshore office. As your file may contain words that are particular to
                the
                medical and nursing community, we will invite you to meet with one of our nurse managers when you review
                your file, so that they may be available to answer any of your questions. Similarly, you may request a
                copy of your file, in part or in whole, if required, by submitting a request in writing to your local
                Bayshore branch. We will assist any individual who needs assistance in preparing a written request for
                access to their personal information.</p>
            <p>If you have a sensory disability, please let us know so that we can endeavour to provide you access to
                information in an alternate format. In addition, translators will be made available, as required</p>
            <p>Please note that we may need to collect additional personal information to validate the identity and
                authority of
                the individual making the access request. This is to ensure that only individuals authorized to access
                your information can do so. This additional information will only be used for the purpose of validating
                the information request.</p>
            <p>A reasonable fee may be charged for retrieval and copying of records, based on the nature of the request.
                Please
                contact us if you have a concern regarding the reasonableness of the fee.</p>
            <p>Bayshore endeavors to respond to all requests for access to personal information within 30 days. Some
                requests
                for access to information may be subject to limitations or refusal. Reasons for refusal may include: the
                proprietary nature of the material; the information may infringe on the privacy of another individual;
                the
                law prohibits its release; and/or if we exercise our discretion to withhold or limit the information
                because we believe harm may result from the disclosure.</p>
            <p>Bayshore&rsquo;s privacy policy also adheres to the European Union General Data Protection Regulation
                (GDPR) on
                data portability. You have the right to obtain and reuse your personal data for your own purposes across
                different services. You have the right to move, copy or transfer personal data easily from one IT
                environment to another in a safe and secure manner without impacting usability. This right applies only
                to the
                information you have provided to Bayshore.</p>
            <p>When requests for access to information are refused, you will be notified of the reason(s) for refusal,
                including
                recourses available to you.</p>
            <h2 class="privacy-policy-h2">Your Right To Correct Your Personal Information</h2>
            <p>You may contact Bayshore to request a correction of an error or omission to your personal information.
                This
                request must be in writing. As appropriate, Bayshore will make the correction as soon as is reasonably
                possible. If a correction cannot be made, Bayshore will notify you in writing as to why the correction
                cannot be made. Both the request for correction and our response will be retained in the record.</p>
            <p>Bayshore&rsquo;s privacy policy also adheres to the European Union General Data Protection Regulation
                (GDPR) on
                erasure of personal data. You have the right to request erasure of personal data without undue delay and
                the personal data will be erased without undue delay if the personal data is no longer necessary in
                relation to the purposes for which it was collected or otherwise processed; consent is withdrawn by you
                on
                which the processing is based on your objection to the processing pursuant to Article 21(1) of the GDPR
                and
                there are no overriding legitimate grounds for the processing; where the personal data was unlawfully
                processed; and the personal data is to be erased for compliance with a legal obligation in Union or
                Member
                State law to which Bayshore is subject.</p>
            <h2 class="privacy-policy-h2">If You Need More Information, or Have a Question or Concern About Bayshore and
                its Privacy Practices</h2>
            <p>Anyone may contact Bayshore with any complaint, question, concern or compliment relating to
                Bayshore&rsquo;s
                information handling practices. All inquires will be investigated thoroughly, and if found to be
                justified, appropriate corrective measures will be taken, up to and including making revisions to
                current
                policy and practices. Any such corrective actions will be included in our response to the individual
                making the
                inquiry.</p>
            <p>All Bayshore employees are responsible for ensuring the privacy and confidentiality of your personal
                information.
                If you have any questions or concerns about your personal information, or if you would like more
                information about Bayshore&rsquo;s policies and practices for managing and handling personal
                information,
                you are invited to contact your local Bayshore office. If your privacy concerns cannot be addressed to
                your
                satisfaction, or you wish further information, your inquiry will be forwarded to Bayshore&rsquo;s
                Privacy
                Officer. You may contact Bayshore&rsquo;s Privacy Officer directly by email at
                privacyofficer@bayshore.ca,
                by calling 1-800-668-9490 or in writing to 2101 Hadwen Rd., Mississauga, Ontario L5K 2L3.</p>
            <p>You are also entitled to contact your provincial Privacy Commissioner&rsquo;s Office if you feel this is
                necessary. While we would appreciate the opportunity to do what we can to address your concerns, we
                would
                also assist you in contacting your Privacy Commissioner&rsquo;s Office if you believe this is warranted.
            </p>
            <h2 class="privacy-policy-h2">Google Analytics</h2>
            <p>We make use of Google Analytics to monitor and analyze the use of our website. You can opt out from the
                use of
                Google Analytics by downloading and installing the Google Analytics Opt-out Browser Add On.
                Google&rsquo;s
                Privacy Policy is available here:</p>
        </div>
    </div>
    <app-footer></app-footer>
</div>

<div class="bs-virtualcare-floating">
    <div class="bs-virtualcare-floating-banner">
        <dxp-text class="bs-virtualcare-floating-banner__text">{{'VIRTUALCARE.BANNER.BANNER'|translate}}
        </dxp-text>&nbsp;
        <dxp-link [routerLink]="['/virtualcare']" [fragment]="'covid19'" [underline]="true" [greedy]="false"
            [inverted]="true" class="bs-virtualcare-floating-banner__text">
            {{'VIRTUALCARE.BANNER.BANNER_LINK'|translate}}
        </dxp-link>
    </div>

</div>