import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { environmentConfig } from '../../environments/environment';

@Component({
  selector: 'app-virtual-care',
  templateUrl: './virtual-care.component.html',
  styleUrls: ['./virtual-care.component.sass']
})
export class VirtualCareComponent  extends BaseComponent implements OnInit {

  public isLoading: boolean = false;
  public envColor:string = 'none';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { super(); }

  ngOnInit(): void {
    this.envColor = environmentConfig.measureColor
  }

  
}
