import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseComponent } from '../../../shared/components/Base/base.component';
import { VirtualcareService } from '../../../services/virtualcare.service';
import { TimeSlot, TimeSlots } from '../../../model/timeslot';
import { Moment } from 'moment';
import * as moment from 'moment';
import { IVirtualservice } from '../../../model/virtualservice';
import { Provider } from '../../../model/provider';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-timeslot-selection',
  templateUrl: './timeslot-selection.component.html',
  styleUrls: ['./timeslot-selection.component.sass']
})
export class TimeslotSelectionComponent extends BaseComponent implements OnInit {
  @Input()
  public selectedService: IVirtualservice;
  @Input()
  public selectedProvider: Provider;
  @Input()
  public logo: string;

  @Input()
  public isReschedule: boolean;

  @Input()
  public alreadySelectedTimeslot: Moment;

  @Output("next") next: EventEmitter<any> = new EventEmitter();
  @Output("back") back: EventEmitter<any> = new EventEmitter();
  @Output()
  onPreviousBookingStep: EventEmitter<any> = new EventEmitter();
  @Output()
  onBookingStepCompleted: EventEmitter<any> = new EventEmitter();
  @Output()
  @Output() onSelectionComplete: EventEmitter<any> = new EventEmitter();

  public selectedTimeslot: Moment;
  public stepperList: any[] = [];

  dayList = [];
  dateList = [];
  monthList: any = [];
  mobDateList: any = [];
  interval = "";
  // serviceName = "";
  // duration = "";
  mobTimeList = [];
  isMobileTimeSelected: boolean = false;
  backwardClickDisable = 0;
  selectedDateMob = "";
  fullDate = "";
  firstDateList = [];
  isAvailable: boolean = true;
  isLoading: boolean = false;

  @Input()
  public serviceId: string;

  @Input()
  public serviceName: string;

  @Input()
  public duration: string;

  selectedTimeMob;// = sessionStorage.getItem("selectedTime");
  selectedTimeValue;// = sessionStorage.getItem("selectedTimeValue");
  selectedTime;// = sessionStorage.getItem("selectedTime");
  selectedMobTimeValue;// = sessionStorage.getItem("selectedTimeValue");
  public timeslots: TimeSlots[];
  public daySlots: TimeSlots;
  loaded: boolean = false;
  public today: Moment;
  public startOfWeek: Moment;
  public endOfWeek: Moment;
  public weekLabel: string;



  constructor(
    private _virtualservice: VirtualcareService,
    private spinner: NgxSpinnerService
  ) {
    super();
    moment.updateLocale("en", {
      week: {
        dow: 1,
        doy: 7
      }
    });
  }

  public setWeekLabel() {
    this.weekLabel = `${this.startOfWeek.format("Do")} ${this.startOfWeek.format("MMMM")} - ${this.endOfWeek.format("Do")} ${this.endOfWeek.format("MMMM")}`
  }

  public setSelectedTimeLabel(selectedDate: Moment | undefined) {
    if (selectedDate) {
      if (this.isReschedule) {
        this.selectedTime = `${selectedDate.format("DD-MM-yyyy")}  ${selectedDate.format("LT")} - ${selectedDate.clone().add(this.duration, 'minutes').format("LT")}`;
      } else {
        this.selectedTime = `${selectedDate.format("LT")} - ${selectedDate.clone().add(this.duration, 'minutes').format("LT")}`;
      }
      this.stepperList.length === 0 ? this.stepperList.push(this.selectedTime) : this.stepperList.splice(1, 1, this.selectedTime);
    } else {
      this.selectedTime = "";
    }
  }

  ngOnInit() {

    this.today = moment();
    this.startOfWeek = this.today.clone().startOf('week');
    this.endOfWeek = this.today.clone().endOf('week');
    this.serviceName = this.selectedService.bookingSystemName;
    this.serviceId = this.selectedService.bookingSystemId;
    this.duration = this.selectedService.duration;
    // this.loadValuesFromSessionStorage();
    this.setWeekLabel();
    if (this.alreadySelectedTimeslot) {
      this.setSelectedTimeLabel(moment(this.alreadySelectedTimeslot));
      this.today = moment(this.alreadySelectedTimeslot);
      this.startOfWeek = this.today.clone().startOf('week');
      this.endOfWeek = this.today.clone().endOf('week');
    }
    this.loadTimeSlots(
      this.serviceId.toString(),
      this.startOfWeek,
      this.endOfWeek,
      this.selectedProvider?.id
    );


  }

  private loadTimeSlots(bookingSystemId: string, startDate, endDate, providerId?: string) {

    this.timeslots = [];
    this.spinner.show();

    this._virtualservice.getTimeSlot(bookingSystemId, startDate, endDate, providerId)
      .subscribe(data => {

        this.spinner.hide();
        this.loaded = true;
        this.timeslots = data;
      },
        error => console.log(error));
  }


  public forwardClick(event) {
    this.selectedTime = null;
    this.selectedTimeslot = null;
    this.startOfWeek.add(7, 'days');
    this.endOfWeek.add(7, 'days');
    this.setWeekLabel();
    this.loadTimeSlots(this.serviceId.toString(), this.startOfWeek, this.endOfWeek, this.selectedProvider?.id);
  }

  public backwardClick(event) {
    this.selectedTime = null;
    this.selectedTimeslot = null;
    this.startOfWeek.subtract(7, 'days');
    this.endOfWeek.subtract(7, 'days');
    this.setWeekLabel();
    this.loadTimeSlots(this.serviceId.toString(), this.startOfWeek, this.endOfWeek, this.selectedProvider?.id);
  }


  nextClick(event) {
    this.next.emit(2);
  }

  backClick(event) {
    this.onPreviousBookingStep.emit();
    this.back.emit(2);
  }

  clearSelectedTimeslot() {
    const timeslot = this.timeslots.find(t => t.times.find(tt => tt.isSelected === true));
    if (timeslot) {
      const slot = timeslot.times.find(t => t.isSelected === true);

      slot.isSelected = false;
    }
  }

  onTimeslotSelected(event) {
    const selectedDate = event as Moment;

    this.clearSelectedTimeslot();

    const timeslot = this.timeslots.find(t => t.times.find(tt => tt.time === selectedDate));
    if (timeslot) {
      const slot = timeslot.times.find(t => t.time === selectedDate);

      slot.isSelected = true;

      this.selectedTimeslot = selectedDate;
      this.stepperList.length === 0 ? this.stepperList.push(selectedDate.format("LL")) : this.stepperList.splice(0, 1, selectedDate.format("LL"))
      this.setSelectedTimeLabel(selectedDate);

    }
  }

  onDaySelected(event, day) {
    this.daySlots = day;

    if (this.daySlots.times.length === 1) {
      const slot = this.daySlots.times[0];
      if (!slot.time) {
        this.isAvailable = false;
      }
    } else {
      this.isAvailable = true;
    }
  }

  //public onPreviousBookingStep
  public completeBookingStep(event) {
    this.nextClick(event);
    if (this.selectedTimeslot) {
      this.onSelectionComplete.emit(this.stepperList);
      this.onBookingStepCompleted.emit(this.selectedTimeslot);
    } else {
      alert("Please select a time slot!")
    }
  }
}
