<div class="row">
    <div class="col-md-12">
      <div class="booking-card" *ngIf="!isFrenchProgram">
        <h3 [ngClass]="'booking-card-header-center'">
          {{'VIRTUALCARE.CONSENT.HEADER'|translate}}
        </h3>
        <div class="provider-flow-container" (scroll)="onScroll($event)">
           <p>{{'VIRTUALCARE.CONSENT.TERMS'|translate}}</p> <br>
            <div style="padding-left: 20px;">
                <ul>
                    <li>{{'VIRTUALCARE.CONSENT.POINT1'|translate}}</li>
                    <li>{{'VIRTUALCARE.CONSENT.POINT2'|translate}}</li>
                    <li>{{'VIRTUALCARE.CONSENT.POINT3'|translate}}</li>
                    <li>{{'VIRTUALCARE.CONSENT.POINT4'|translate}}</li>
                    <li>{{'VIRTUALCARE.CONSENT.POINT5'|translate}}</li>
                </ul>
            </div> 
        <br> 
        <p>{{'VIRTUALCARE.CONSENT.TERMS2'|translate}}</p>  <br>     
        
        <p>{{'VIRTUALCARE.CONSENT.TERMS3'|translate}}</p><br>
        <p>{{'VIRTUALCARE.CONSENT.TERMS4'|translate}}</p><br>
        <p>{{'VIRTUALCARE.CONSENT.TERMS5'|translate}}</p>
        </div>
        <div class="booking-card-footer">
            <div class="form-check consent-check" >
                <input class="form-check-input" type="checkbox" id="gridCheck" [attr.disabled]="isScrollCompleted ? null : true" (change)="onConsentChange()" [(ngModel)]="consentValue"/>
                <label class="form-check-label" for="gridCheck">
                  {{'VIRTUALCARE.CONSENT.TERMS_CHECK_LABEL'|translate}}
                </label>
              </div>
              <div class="form-check consent-check" *ngIf="hasTerms">
                <input class="form-check-input" type="checkbox" id="gridCheck1" (change)="onTermsChange()" [(ngModel)]="termsValue"/>
                <label class="form-check-label" for="gridCheck1">
                  {{'VIRTUALCARE.REGISTRATION.TERMS_1' | translate}}
                  <a href="https://www.bayshore.ca/terms-of-use/"
                    target="_blank">{{'VIRTUALCARE.REGISTRATION.TERMS_2' | translate}}</a>
                </label>
              </div>
        </div>
      </div>
      <div class="booking-card" *ngIf="isFrenchProgram">
        <h3 [ngClass]="'booking-card-header-center'">
          Consentement
        </h3>
        <div class="provider-flow-container" (scroll)="onScroll($event)">
           <p>Je soussigné(e), autorise le pharmacien de Bayshore à procéder à un examen de mes médicaments, à recueillir, à utiliser et à divulguer les renseignements personnels suivants sur ma santé:</p> <br>
            <div style="padding-left: 20px;">
                <ul>
                    <li>les détails de ma consultation virtuelle,</li>
                    <li>les observations de toute combinaison de médicaments prescrits,</li>
                    <li>des suggestions sur l’utilisation d’un ou plusieurs autres médicaments prescrits à envisager,</li>
                    <li>des suggestions concernant le moment ou la manière de prendre mon ou mes medicaments prescrits,</li>
                    <li>des informations globales au cours des soins dispensés par les pharmaciens de Bayshore.</li>
                </ul>
            </div> 
        <br> 
        <p>à mon équipe de soins de santé si cela est approprié et nécessaire.</p>  <br>     
        
        <p>Je comprends le but de la divulgation de ces renseignements personnels sur la santé à mon équipe de soins de santé, le cas échéant. Je comprends qu’il s’agit d’une évaluation volontaire et que je ne suis pas obligé(e) de signer ce consentement, mais je reconnais que l’évaluation ne peut pas se poursuivre sans celui-ci, car l’objectif ne peut pas être atteint.</p><br>
        <p>La Pharmacie Bayshore conservera les renseignements personnels/les renseignements médicaux personnels (RP/RMP) de manière à protéger la vie privée et la confidentialité. La pharmacie Bayshore ne divulguera pas, ne communiquera pas et ne transmettra pas les renseignements médicaux protégés ainsi que les détails de la consultation obtenue dans le cadre des examens de médicaments Manuvie – Bayshore à un tiers, y compris Manuvie, à quelque fin que ce soit, sans le consentement écrit préalable du (de la) patient(e).</p><br>
        <p>En y consentant, j’accepte de procéder à l’examen des médicaments effectué par le pharmacien de Bayshore.</p>
        </div>
        <div class="booking-card-footer">
            <div class="form-check consent-check" >
                <input class="form-check-input" type="checkbox" id="gridCheck" [attr.disabled]="isScrollCompleted ? null : true" (change)="onConsentChange()" [(ngModel)]="consentValue"/>
                <label class="form-check-label" for="gridCheck">
                  J'ai lu et j'accepte de consentir ci-dessus
                </label>
              </div>
              <div class="form-check consent-check" *ngIf="hasTerms">
                <input class="form-check-input" type="checkbox" id="gridCheck1" (change)="onTermsChange()" [(ngModel)]="termsValue"/>
                <label class="form-check-label" for="gridCheck1">
                  J'ai lu et j'accepte
                  <a href="https://www.bayshore.ca/terms-of-use/"
                    target="_blank">termes et conditions</a>
                </label>
              </div>
        </div>
      </div>
    </div>
  </div>
  