import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { Covid19AwarenessComponent } from './public/covid19-awareness/covid19-awareness.component';
import { HomeComponent } from './public/home/home.component';
import { VirtualcareServicesComponent } from './public/virtualcare-services/virtualcare-services.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { BasicModule } from '@bayshore-healthcare/dxp-angular-lib';
import { VirtualCareModule } from './virtual-care/virtual-care.module';
import { SharedModule } from './shared/shared.module';
import { TermsAndConditionsComponent } from './public/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { AccessibilityComponent } from './public/accessibility/accessibility.component';

registerLocaleData(localeFr);
registerLocaleData(localeEn);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    Covid19AwarenessComponent,
    HomeComponent,
    VirtualcareServicesComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AccessibilityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule,
    HttpClientModule,
    BasicModule,
    VirtualCareModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
