<app-stepper *ngIf="!isOtherFlow" [bookingSteps]="bookingStepsConfig" [isBackButton]="currentBookingStep !== bookingStepsConfig[0].step"
  [count]="mobileStepper.count" [step]="mobileStepper.text" [bookingDetails]="bookingDetails" [showHeader]="showHeader"
  (back)="previousStep($event)">
</app-stepper>
<!-- Content Goes Here -->
<div [ngSwitch]="currentBookingStep">
  <div *ngSwitchCase="bookingStepsEnum.selectTime">
    <app-timeslot-selection [selectedService]="selectedService" [selectedProvider]="selectedProvider" [alreadySelectedTimeslot]="selectedTimeslot" [duration]="selectedService.duration" [isReschedule]="true" (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="timeslotSelected($event)"
      (onSelectionComplete)="bookingStatusUpdate($event)">
    </app-timeslot-selection>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.specialInstructions">
    <app-special-instruction (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="specialInstructionsEntered($event)" [selectedProvider]="selectedProvider"
      [selectedService]="selectedService" [selectedTimeslot]="selectedTimeslot" [isLoading]="isLoading">
    </app-special-instruction>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.completed">
    <app-success-message [userName]="bookingCompletedUserName"  [confirmation]="bookingCompletedConfirmation"
      [successMessage]="bookingCompletedSuccessMessage" [providerFlow]="isProvider"
      [providerName]="selectedProvider.firstName">
    </app-success-message>
  </div>
</div>
<app-success-message *ngIf="isOtherFlow" [isOtherFlow]="isOtherFlow" [successMessage]="bookingCompletedSuccessMessage"></app-success-message>

<!-- <app-error-message></app-error-message> -->