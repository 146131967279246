// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableAutomation: true
};

export const api = {
  basename: 'https://dev-wave-api.mybayshorecare.ca/api/v1',
  notificationServiceApiGatewayBase: "https://pe7zdzmyk2.execute-api.ca-central-1.amazonaws.com/dev/api/v1",
  homeUrl: 'https://dev.mybayshorecare.ca/auth/login',
  nylasServiceUrl : 'http://localhost:8081/api/v1'
  // basename: 'http://localhost:8080/api/v1',
};

export const nylas = {
  nylasCallBackUrl: 'https://dev-wave.mybayshorecare.ca/private-scheduler/login-callback',
  clientId: "cpg73h6enu047zegjci292iio",
  authUrl: 'https://api.nylas.com/oauth/authorize'
};

export const environmentConfig = {
  termsUrl: 'https://dev-wave.mybayshorecare.ca/terms-and-conditions',
  measureColor:'#2cb0d894',
  appointmentMinimumTimeHour:24


};

export const aws = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    // REQUIRED - Amazon Cognito Region
    region: "ca-central-1",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ca-central-1_fyvoAonKN",
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "12ej0ua8inu5nucaa4g0qqjbei",
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    // domain: '.yourdomain.com',
    // OPTIONAL - Cookie path
    // path: '/',
    // OPTIONAL - Cookie expiration in days
    // expires: 365,
    // OPTIONAL - Cookie secure flag
    // secure: true
    // },
    // OPTIONAL - customized storage object
    // storage: new MyStorage(),
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
};

export const blacklisted = {
  domain: ["example.com", "spam.net", "fake.org"]
};