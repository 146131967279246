import { Systems } from "../enum/systems.enum";

export class System {
  constructor(
    public id: string,
    public name: Systems
  ) {}

  static fromJson(json: any) {
    if (!json) {
      return undefined;
    }
    return new System(
      json.id,
      json.name
    );
  }
}
