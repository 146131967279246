import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, from, EMPTY, Subject, of } from 'rxjs';
import {
  tap,
  finalize,
  catchError,
  take,
  mergeMap,
  distinctUntilChanged,
  publishReplay,
  refCount,
  map
} from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { api } from '../../environments/environment';
import { values, Routes } from '../shared/constants/constant';

import { UserService } from './user.service';
import { clearLocalStorage } from '../shared/helpers/helpers';
import { Account } from '../shared/models/account';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUser: any;
  private currentSession: any;
  private currentUserAttributes: any;

  private isLoggedInSubject: Subject<boolean> = new Subject();
  private sessionCall: any;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {

  }

  public onAuthStateChanged(): Observable<boolean> {
    return this.isLoggedInSubject.pipe(
      distinctUntilChanged()
    );
  }

  public setLoggedInState(isLoggedIn: boolean) {
    this.isLoggedInSubject.next(isLoggedIn);
  }
  public setSession(session) {
    this.currentSession = session;
  }

  public getSession(): object {
    return this.currentSession;
  }

  public getToken(): string {
    if (!this.currentSession || !this.currentSession.idToken) {
      return '';
    }
    return this.currentSession.idToken.payload;
  }

  public getJwtToken(): string {
    if (!this.currentSession || !this.currentSession.idToken) {
      return '';
    }
    return this.currentSession.idToken.jwtToken;
  }

  public isLoggedIn(): boolean {
    return !!this.currentSession && !!this.currentSession.accessToken;
  }

  public getCognitoId(): string {
    if (!this.currentUserAttributes) {
      return '';
    }
    const attribute = this.currentUserAttributes.find(a => a.Name === values.AWS_USER_ATTRIBUTE_NAME);
    if (!attribute) {
      return '';
    }
    return attribute.Value;
  }

  public doesUserAlreadyExist(email: string): Observable<any> {
    return this.http.get(`${api.basename}/portal-users?email=${email}`);
  }

  public getEmail(): string {
    if (!this.currentUserAttributes) {
      return '';
    }
    const attribute = this.currentUserAttributes.find(a => a.Name === values.AWS_USER_ATTRIBUTE_EMAIL);
    if (!attribute) {
      return '';
    }
    return attribute.Value;
  }

  private checkAccountName(emailAddress: string): Promise<Account> {
    return this.http.post(
      `${api.basename}/operations/account-name-check`,
      {
        accountEmailAddress: emailAddress
      }
    ).pipe(
      map(json => Account.fromAccountCheckJson(json)),
      tap({ error: (error) => console.warn('Error checking email:', error) })
    ).toPromise();
  }

  public signIn(username: string, password: string): Observable<any> {
    return from(this.checkAccountName(username))
      .pipe(
        map((r) => r.email),
        mergeMap(email => {
          const properCaseEmail: string = email || username;
          
          return from(Auth.signIn(properCaseEmail, password)).pipe(
            mergeMap(user => {
              this.sessionCall = undefined;
              this.currentUser = user;

              return forkJoin(
                of(user).pipe(take(1)),
                user.challengeName === values.AWS_NEW_PASSWORD_REQUIRED ?
                  of(undefined) :
                  this.refreshSessionInfo().pipe(
                    mergeMap(() =>
                      this.userService.getUser(this).pipe(
                        take(1)
                      )
                    ),
                    take(1)
                  )
              );
            }),
            tap(([cognitoUser, user]) => this.isLoggedInSubject.next(!!user))
          );
        })
      );
  }

  public signOut(isGlobal: boolean): Observable<any> {
    if (this.currentUser) {
      return forkJoin(
        this.http.post(`${api.basename}/logout`, {}),
        from(Auth.signOut({ global: isGlobal }))
      ).pipe(
        catchError(() => {
          localStorage.clear();
          return EMPTY;
        }),
        finalize(() => {
          this.reset();
        })
      );
    }
    return EMPTY;
  }

  public completeNewPassword(
    newPassword: string,
    givenName: string,
    lastName: string
  ): Observable<any> {
    return from(
      Auth.completeNewPassword(this.currentUser, newPassword, {
        given_name: givenName,
        family_name: lastName
      })
    );
  }

  public forgotPassword(username: string) {
    return this.http.post(
      `${api.basename}/operations/reset-password`,
      {
        clientEmailAddress: username
      }
    );
  }

  public resetPassword(
    username: string,
    tempPassword: string,
    password: string
  ): Observable<any> {
    return from(Auth.forgotPasswordSubmit(username, tempPassword, password));
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<any> {
    return from(
      Auth.currentAuthenticatedUser().then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
    );
  }

  public getCurrentUserAccount(): object {
    return this.currentUser;
  }

  public getAttributeByName(name: string): { Name, Value } {
    if (!this.currentUserAttributes) {
      return undefined;
    }
    return this.currentUserAttributes.find(a => a.Name === name);
  }

  public setAttribute(attributes: any): Observable<any> {
    return from(
      Auth.currentAuthenticatedUser().then(user => {
        return Auth.updateUserAttributes(user, attributes);
      })
    ).pipe(
      mergeMap(
        () => {
          this.sessionCall = undefined;
          return this.refreshSessionInfo();
        }
      )
    );
  }

  public reset(): void {
    this.currentUser = undefined;
    this.currentUserAttributes = undefined;
    this.currentSession = undefined;
    this.userService.reset();
    clearLocalStorage();
    this.isLoggedInSubject.next(false);

  }

  public updateAuthToken(): Observable<any> {
    this.sessionCall = undefined;
    return from(
      Auth.currentAuthenticatedUser()
    ).pipe(
      mergeMap(
        () => {
          this.isLoggedInSubject.next(true);
          return this.refreshSessionInfo();
        }
      )
    );
  }

  public refreshSessionInfo(): Observable<any> {
    if (!this.sessionCall) {
      this.sessionCall = forkJoin(
        from(Auth.currentAuthenticatedUser()).pipe(
          take(1),
          catchError(() => of(undefined))
        ),
        from(Auth.currentSession()).pipe(
          take(1),
          catchError(() => of(undefined)),
          tap(
            session => {
              this.currentSession = session;
            }
          )
        ),
        from(Auth.currentAuthenticatedUser()).pipe(
          take(1),
          catchError(() => of(undefined)),
          mergeMap(user => {
            if (user) {
              this.currentUser = user;
              return from(Auth.userAttributes(user))
                .pipe(
                  take(1),
                  catchError(() => of(undefined)),
                  tap(attributes => {
                    this.currentUserAttributes = attributes;
                  })
                );
            }
            return of(undefined);
          })
        )
      ).pipe(
        mergeMap(([user, session, attributes]) => {
          this.userService.reset();
          return forkJoin(
            of(user),
            of(session),
            of(attributes),
            this.userService.getUser(this).pipe(
              take(1),
              tap(() => this.isLoggedInSubject.next(this.isLoggedIn())),
              catchError(() => of(undefined))
            )
          )
        }),
        publishReplay(1),
        refCount()
      );
      setTimeout(() => this.sessionCall = undefined, 5000);
    }
    return this.sessionCall;
  }
}

