<div class="bs-virtualcare">
  <div class="bs-virtualcare-header">
    <div class="bs-virtualcare-header-logo">
      <img src="/assets/images/wave_logo.svg" class="header-logo-img">
    </div>
    <div class="bs-virtualcare-header-language">
      <app-language-toggle [inverted]="true"></app-language-toggle>
    </div>
  </div>
  <div class="bs-virtualcare-content">
    <div class="accessibility-content">
      <h1 class="accessibility-h1">Accessibility</h1>
      <h2 class="accessibility-h2">
        Bayshore HealthCare is committed to providing accessible services and information to all individuals requiring it.
      </h2>
      <p>
        Bayshore's goal is to make safe accessible employment an opportunity for anyone that requires assistance or accommodation and to ensure that our services are also available to our clients that require assistance or accommodation. Employees and candidates for employment may obtain more information about accommodation by contacting their local recruitment representative to discuss requirements. Accessibility for customers and the public can be arranged by providing accessible formats and communication support upon request and by discussing other accessibility requirements with your local Bayshore location.
      </p>
      <p>
        Further changes to accessibility are under way. As we continue to improve our website, we will reflect any changes here within our accessibility statement.
      </p>
      <p>For any concerns or feedback, please email us at <a
          href="accessibilityfeedback@bayshore.ca">accessibilityfeedback@bayshore.ca</a> </p>

      <p>To read our multi-year accessibility plan <a href="#">click here</a>

      <p>To read our Accessibility Standard for Employment and Customer Service <a href="#">click here</a></p>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<div class="bs-virtualcare-floating">
  <div class="bs-virtualcare-floating-banner">
    <dxp-text class="bs-virtualcare-floating-banner__text">{{'VIRTUALCARE.BANNER.BANNER'|translate}}
    </dxp-text>&nbsp;
    <dxp-link [routerLink]="['/virtualcare']" [fragment]="'covid19'" [underline]="true" [greedy]="false"
      [inverted]="true" class="bs-virtualcare-floating-banner__text">
      {{'VIRTUALCARE.BANNER.BANNER_LINK'|translate}}
    </dxp-link>
  </div>

</div>