<app-stepper [bookingSteps]="bookingStepsConfig">
</app-stepper>

<div class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
  <div class="toggle">
    <div class="box-shadow">
      <div class="head-section">
        <!-- <div class="loader" *ngIf="isLoading">
            <img src="/assets/images/custom-spinner.svg">
          </div> -->

        <div class="head">
          <h3>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.SELECT_SERVICE' | translate}}</h3>
        </div>
        <div class="" *ngFor="let service of serviceList">
          <div id="wave_svc" class="togglehead" [routerLink]="[service.bookingLink]" [queryParams]="{internal : true}">
            <div class="icon-sec">
              <img src={{service.logo}}>
            </div>
            <div class="name-sec">
              {{service.serviceName}}
            </div>
          </div>
        </div>

      </div>
      <div class="desc-section">

        <div class="first-section">
          <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</p>
          <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</p>
          <img src="/assets/images/component.png">
        </div>
      </div>
    </div>
  </div>
</div>
