import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';
import { User } from '../model/user';
import { AuthService } from './auth.service';
import { api } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: User;
  private sessionCall: any;

  constructor(
    private http: HttpClient
  ) {

  }

  public reset() {
    this.sessionCall = undefined;
  }

  public getUser(authService: AuthService): Observable<User> {
    if (!this.sessionCall) {
      
      this.sessionCall = this.http.get(`${api.basename}/portal-users/${authService.getCognitoId()}`).pipe(
        tap({ error: (error) => this.handleError(authService, error) }),
        map(json => User.fromJson(json)),
        tap(user => this.user = user),
        publishReplay(1),
        refCount()
      );
    }
    return this.sessionCall;
  }

  public doesUserHaveCareReceiver(): boolean {
    if (this.user) {
      const currentFamily = this.getCurrentFamily(this.user);
      return !!currentFamily && currentFamily.systems && currentFamily.systems.length > 0;
    } else {
      return false;
    }
  }

  public getCurrentFamily(user: User = this.user) {
    if (user && user.families && user.families[0]) {
      return user.families[0];
    }
    return undefined;
  }

  private handleError(authService: AuthService, error: any) {
    if (error.status === 401) {
      authService.signOut(true).subscribe(
        {
          error: e => {
            console.warn('Error while logging out user', e);
            // authService.reset();

          },
          complete: () => {
            // authService.goToLogoutPage();
          }
        }
      );
    } else {
      console.warn('SERVICE ERROR:', error);
    }
  }
}
