<!-- Services without Province (Category) -->
<div class="d-none d-sm-block d-md-block d-lg-block d-xl-block" *ngIf="!hasCategory">
  <div class="toggle">
    <div class="box-shadow">
      <div class="head-section">
        <div class="loader" *ngIf="isLoading">
          <img src="/assets/images/custom-spinner.svg">
        </div>

        <div class="head">
          <div class="head-back" *ngIf="logo" (click)="previousPage()">
            <img src="../../../../assets/images/arrow-left.svg" />
          </div>

          <h3>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.SELECT_SERVICE' | translate}}</h3>
          <div class="head-logo" *ngIf="logo">
            <img src="{{logo}}" />
          </div>
        </div>
        <div id="wave_svc_{{i}}" *ngFor="let virtual of virtualServices; let i = index"
          (click)="serviceSelection(virtual)" class="togglehead"
          [ngClass]="{'toggle-active': virtual.bookingSystemId==serviceId}">
          <div class="icon-sec">
            <img src="/assets/images/qa.png">
          </div>
          <div class="name-sec">
            <h3>{{virtual.displayName[0].value}}</h3>
            <p *ngIf="!isIcs"> {{virtual.duration}} minutes</p>
          </div>
        </div>
      </div>
      <div class="desc-section">
        <!-- <div class="first-section d-none"> -->
        <div class="{{displayFirstSection}}" >
          <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</p>
          <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</p>
          <img src="/assets/images/component.png">
        </div>
        <div class="{{displaySecondSection}}">
          <h3>{{serviceName}}</h3>
          <p> {{description}}</p>

          <button id="btn_schedule_svc" class="btn schedule-btn float-right" [disabled]="!bookingSystemId"
            (click)="completeBookingStep()">{{nextStepName | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile Design for Services without Province -->
<div class="d-block d-sm-none d-md-none d-lg-none d-xl-none" *ngIf="!hasCategory">
  <div class="row {{displayHeading}}">
    <div class="col-12">
      <p class="mt-4 mb-4">
        <small>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</small>
      </p>
      <p class="mt-4 mb-4"><small>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</small>
      </p>
    </div>
  </div>
  <div class="card acc-card">
    <h3 class="main-head {{displayHeading}}">
      {{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.SELECT_SERVICE' | translate}}</h3>
    <div id="accordion" class="mobile-accordian">
      <div class="card" (click)="serviceSelection(virtual)" id="mob_wave_svc_{{i}}"
        *ngFor="let virtual of virtualServices; let i = index">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button class="btn btn-link w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
              aria-controls="collapseOne">
              <div class="row ">
                <div class="col-3">
                  <div class="acc-img">
                    <img src="/assets/images/qa.png">
                  </div>
                </div>
                <div class="col-9 text-left">
                  <h3 class="acchead home-care"> {{virtual.displayName[0].value}} </h3>
                  <p class="home-care-time " *ngIf="!isIcs">{{virtual.duration}} minutes</p>
                </div>
              </div>
            </button>
          </h5>
        </div>

        <div id="collapseOne"
          [ngClass]="{'d-block':virtual.bookingSystemId==serviceId,'d-none':virtual.bookingSystemId!=serviceId}"
          aria-labelledby="headingOne" *ngIf="virtual === selectedService">
          <div class="card-body">
            <p class="time text-right">{{virtual.displayName[0].value}}</p>
            <small> {{virtual.description[0].value}} </small>
            <div class="row">
              <div class="col-md-12">
                <button id="btn_schedule_svc" class="btn-mobile-schedule float-right"
                  (click)="completeBookingStep()">{{nextStepName | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Services with Province (category) -->
<div class="virt-services-cat-container" *ngIf="hasCategory">
  <div class="virt-services-cat-container-content">
    <div class="virt-services-cat-container-content-services">
      <!-- <img src="/assets/images/custom-spinner.svg" class="loader {{spinnerStatus}}"> -->
      <div class="loader" *ngIf="isLoading">
        <img src="/assets/images/custom-spinner.svg" *ngIf="logo">
      </div>
      <div class="virt-services-cat-container-content-services-header">
        <div class="virt-services-cat-container-content-services-header-back" *ngIf="logo && isInternal"
          (click)="previousPage()">
          <img src="../../../../assets/images/arrow-left.svg" />
        </div>

        <h3>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.SELECT_SERVICE' | translate}}</h3>
        <div class="virt-services-cat-container-content-services-header-logo" *ngIf="logo">
          <img src="{{logo}}">
        </div>
      </div>
      <div class="virt-services-cat-container-content-services-list">
        <div id="wave_svc_{{i}}" *ngFor="let virtual of groupedVirtualServices; let i = index"
          class="virt-services-cat-container-content-services-list-row" (click)="selectServiceGroup(virtual)"
          [ngClass]="{'toggle-group-active': virtual === selectedServiceGroup, 'hovereffect': virtual.description[0].value != ''}">
          <div class="virt-services-cat-container-content-services-list-row-title">
            <div class="virt-services-cat-container-content-services-list-row-title__icon">
              <img src="/assets/images/qa.png">
            </div>
            <div class="virt-services-cat-container-content-services-list-row-title__text">
              {{virtual.displayName[0].value}}
            </div>
            <div class="virt-services-cat-container-content-services-list-row-title__duration" *ngIf="!isIcs">
              {{virtual.duration}} minutes
            </div>
          </div>
          <div class="virt-services-cat-container-content-services-list-row-description"
            [ngClass]="{'hidden': virtual!=selectedServiceGroup, 'flex': virtual == selectedServiceGroup}">
            <div class="virt-services-cat-container-content-services-list-row-description__iconplaceholder">
              &nbsp;
            </div>
            <div class="virt-services-cat-container-content-services-list-row-description__text">
              {{virtual.description[0].value}}
            </div>
            <div class="virt-services-cat-container-content-services-list-row-description__price" *ngIf="displayCost">
              ${{virtual.price}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="virt-services-cat-container-content-about">
      <div class="{{displayFirstSection}}">
        <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</p>
        <p>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</p>
        <img src="/assets/images/component.png">
      </div>
      <div class="{{displaySecondSectionGroup}}" *ngIf="selectedServiceGroup">
        <div class="virt-services-cat-container-content-categories-header">
          {{'VIRTUALCARE.BAYSHORE_SERVICE.PROVINCE' | translate}}
        </div>
        <div class="virt-services-cat-container-content-categories-container">
          <div id="wave_svc_cat_{{j}}" class="virt-services-cat-container-content-categories-category"
            [ngClass]="{'active': c.bookingSystemId == bookingSystemId}"
            *ngFor="let c of selectedServiceGroup.categories; let j = index" (click)="onCategoryClick(c)">
            <div class="virt-services-cat-container-content-categories-category__text">
              {{c.category}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="virt-services-cat-container-content-actions">
    <button id="btn_schedule_svc" *ngIf="selectedServiceGroup" style="margin-right:35px"
      class="btn schedule-btn float-right" [disabled]="!bookingSystemId || !selectedProvince"
      [class.disabled]="!bookingSystemId || !selectedProvince"
      (click)="completeBookingStep()">{{nextStepName | translate}}</button>
  </div>
</div>
<!-- Mobile Designs for Province-->
<div class="virt-services-mobile" *ngIf="hasCategory">
  <div class="virt-services-mobile__instructions" *ngIf="!showProvince">
    <div class="">{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS' | translate}}</div>
    <div class="">{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.INSTRUCTIONS2' | translate}}</div>
  </div>
  <div class="virt-services-cat-container-content-services" *ngIf="!showProvince">
    <div class="virt-services-cat-container-content-services-header">
      <h3>{{'VIRTUALCARE.SCHEDULING_PUBLIC.SELECT_SERVICE.SELECT_SERVICE' | translate}}</h3>
    </div>
    <div class="virt-services-cat-container-content-services-list">
      <div id="mob_svc_{{i}}" *ngFor="let virtual of groupedVirtualServices; let i = index"
        class="virt-services-cat-container-content-services-list-row" (click)="selectServiceGroup(virtual)">
        <div class="virt-services-cat-container-content-services-list-row-title">
          <div class="virt-services-cat-container-content-services-list-row-title__icon">
            <img src="/assets/images/qa.png">
          </div>
          <div class="virt-services-cat-container-content-services-list-row-title__text">
            {{virtual.displayName[0].value}}
          </div>
          <div class="virt-services-cat-container-content-services-list-row-title__duration" *ngIf="!isIcs">
            {{virtual.duration}} minutes
          </div>
        </div>
        <div class="virt-services-cat-container-content-services-list-row-description"
          *ngIf="virtual === selectedServiceGroup">
          <div class="virt-services-cat-container-content-actions">
            <button id="mob_svc_cat_select" class="btn schedule-btn float-right"
              (click)="openProvince()">{{'VIRTUALCARE.BAYSHORE_SERVICE.PROVINCE' | translate}}</button>
          </div>
          <div class="virt-services-cat-container-content-services-list-row-description__iconplaceholder">
            &nbsp;
          </div>
          <div class="virt-services-cat-container-content-services-list-row-description__text">
            {{virtual.description[0].value}}
          </div>
          <div class="virt-services-cat-container-content-services-list-row-description__price" *ngIf="displayCost">
            ${{virtual.price}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="virt-services-mobile_province" *ngIf="showProvince">
    <div class="{{displaySecondSectionGroup}}">
      <div class="virt-services-cat-container-content-categories-header">
        {{'VIRTUALCARE.BAYSHORE_SERVICE.PROVINCE' | translate}}</div>
      <div id="mob_svc_cat_{{k}}" class="virt-services-cat-container-content-categories-category"
        *ngFor="let c of selectedServiceGroup.categories; let k = index"
        [ngClass]="{'active': c.bookingSystemId == bookingSystemId}" (click)="onCategoryClick(c)">
        <div class="virt-services-cat-container-content-categories-category__text">
          {{c.category}}
        </div>
      </div>
    </div>
    <div class="virt-services-cat-container-content-actions">
      <button class="back" (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
      <button id="mob_btn_schedule_svc" class="btn schedule-btn float-right" (click)="completeBookingStep()"
        [disabled]="!selectedProvince">{{nextStepName | translate}}</button>
    </div>
  </div>
</div>
