<footer>
    <div class="bs-footer-main">
        <div class="bs-footer-logo">
            <img src="/assets/images/wave_logo.svg">
        </div>
        <div class="bs-footer-links">
            <div class="bs-footer-link" >
                <a href="{{'VIRTUALCARE.FOOTER.PRIVACY_URL'|translate}}" target="_blank" class="bs-footer-link-a"> {{'VIRTUALCARE.FOOTER.PRIVACY'|translate}}</a>
            </div>
            <div class="bs-footer-link">
                <a (click)="navigateToPrivacy()" class="bs-footer-link-a"> {{'VIRTUALCARE.FOOTER.TERMS'|translate}}</a>
            </div>
            <div class="bs-footer-link">
                <a href="{{'VIRTUALCARE.FOOTER.ACCESSIBILITY_URL'|translate}}" target="_blank" class="bs-footer-link-a"> {{'VIRTUALCARE.FOOTER.ACCESSIBILITY'|translate}}</a>
            </div>
            <div class="bs-footer-link">
                <a href="{{'VIRTUALCARE.FOOTER.CONTACT_US_URL'|translate}}" target="_blank" class="bs-footer-link-a">{{'VIRTUALCARE.FOOTER.CONTACT_US'|translate}}</a>
            </div>
        </div>
    </div>
    <div class="bs-footer-devider"></div>

    <div class="bs-brand-logo">
        <img src="/assets/images/brand-logo.svg">
    </div>

    <div class="bs-footer-note">
        <p>
            {{'VIRTUALCARE.FOOTER.COPYRIGHT_TEXT'|translate}} <br> {{'VIRTUALCARE.FOOTER.TRADEMARK_TEXT'|translate}}
        </p>

    </div>

</footer>