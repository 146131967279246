
export default {
  "zones": [
    {
      "countryCode": "CA",
      "zoneName": "America/Toronto",
      "gmtOffset": "-5:00(EST) / -4:00(EDT)",
      "dst": 1,
      "timestamp": 1629401385,
      "abbreviation": "ET"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Vancouver",
      "gmtOffset": "-8:00(PST) / -7:00(PDT)",
      "dst": 1,
      "timestamp": 1629390585,
      "abbreviation": "PT"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Whitehorse",
      "gmtOffset": "-7:00",
      "dst": 0,
      "timestamp": 1629390585,
      "abbreviation": "MST"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Edmonton",
      "gmtOffset": "-6:00",
      "dst": 1,
      "timestamp": 1629394185,
      "abbreviation": "MDT"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Regina",
      "gmtOffset": "-6:00",
      "dst": 0,
      "timestamp": 1629394185,
      "abbreviation": "CST"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Winnipeg",
      "gmtOffset": "-5:00",
      "dst": 1,
      "timestamp": 1629397785,
      "abbreviation": "CDT"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Blanc-Sablon",
      "gmtOffset": "-4:00",
      "dst": 0,
      "timestamp": 1629401385,
      "abbreviation": "AST"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/Halifax",
      "gmtOffset": "-3:00",
      "dst": 1,
      "timestamp": 1629404985,
      "abbreviation": "ADT"
    },
    {
      "countryCode": "CA",
      "zoneName": "America/St_Johns",
      "gmtOffset": "-3:30(NST) / -2:30(NDT)",
      "dst": 1,
      "timestamp": 1629406785,
      "abbreviation": "NT"
    }
  ]
}


  // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Atikokan",
    //   "gmtOffset": -18000,
    //   "dst": 0,
    //   "timestamp": 1629397785
    // },

    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Cambridge_Bay",
    //   "gmtOffset": -21600,
    //   "dst": 1,
    //   "timestamp": 1629394185
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Creston",
    //   "gmtOffset": -25200,
    //   "dst": 0,
    //   "timestamp": 1629390585
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Dawson",
    //   "gmtOffset": -25200,
    //   "dst": 0,
    //   "timestamp": 1629390585
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Dawson_Creek",
    //   "gmtOffset": -25200,
    //   "dst": 0,
    //   "timestamp": 1629390585
    // },

    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Fort_Nelson",
    //   "gmtOffset": -25200,
    //   "dst": 0,
    //   "timestamp": 1629390585
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Glace_Bay",
    //   "gmtOffset": -10800,
    //   "dst": 1,
    //   "timestamp": 1629404985
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Goose_Bay",
    //   "gmtOffset": -10800,
    //   "dst": 1,
    //   "timestamp": 1629404985
    // },

    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Inuvik",
    //   "gmtOffset": -21600,
    //   "dst": 1,
    //   "timestamp": 1629394185
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Iqaluit",
    //   "gmtOffset": -14400,
    //   "dst": 1,
    //   "timestamp": 1629401385
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Moncton",
    //   "gmtOffset": -10800,
    //   "dst": 1,
    //   "timestamp": 1629404985
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Nipigon",
    //   "gmtOffset": -14400,
    //   "dst": 1,
    //   "timestamp": 1629401385
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Pangnirtung",
    //   "gmtOffset": -14400,
    //   "dst": 1,
    //   "timestamp": 1629401385
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Rainy_River",
    //   "gmtOffset": -18000,
    //   "dst": 1,
    //   "timestamp": 1629397785
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Rankin_Inlet",
    //   "gmtOffset": -18000,
    //   "dst": 1,
    //   "timestamp": 1629397785
    // },

    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Resolute",
    //   "gmtOffset": -18000,
    //   "dst": 1,
    //   "timestamp": 1629397785
    // },

    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Swift_Current",
    //   "gmtOffset": -21600,
    //   "dst": 0,
    //   "timestamp": 1629394185
    // },
    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Thunder_Bay",
    //   "gmtOffset": -14400,
    //   "dst": 1,
    //   "timestamp": 1629401385
    // },




    // {
    //   "countryCode": "CA",
    //   "zoneName": "America/Yellowknife",
    //   "gmtOffset": -21600,
    //   "dst": 1,
    //   "timestamp": 1629394185
    // }

