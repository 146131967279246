import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../shared/components/Base/base.component';
import { Provider } from '../../../model/provider';
import { IVirtualservice } from '../../../model/virtualservice';
import { ProviderService } from '../../../services/provider.service';
import { VirtualcareService } from '../../../services/virtualcare.service';

@Component({
  selector: 'app-provider-selection',
  templateUrl: './provider-selection.component.html',
  styleUrls: ['./provider-selection.component.sass']
})
export class ProviderSelectionComponent extends BaseComponent implements OnInit {
  @Input()
  public virtualServiceList: IVirtualservice[];
  @Input()
  public selectedService: any;
  @Input()
  public providerList: Provider[];
  @Input()
  public logo: string;

  @Output()
  public onPreviousBookingStep: EventEmitter<any> = new EventEmitter();
  @Output()
  onBookingStepCompleted: EventEmitter<any> = new EventEmitter();
  @Output()
  onSelectionComplete: EventEmitter<any> = new EventEmitter();

  @Input()
  public isLoading: boolean;
  public selectedProvider: Provider;
  public displayFirstSection = "provider-services-container-content-providers-description-first-section";
  public displaySecondSection = "provider-services-container-content-providers-description-second-section d-none";
  public stepperList: any[] = [];
  public defaultServiceDescription = "";

  constructor(
    public providersService: ProviderService,
    public virtualServiceService: VirtualcareService
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.selectedService)
    if (!this.providerList) {
      this.isLoading = true;
      if (this.selectedService) {
        this.addSubscription(
          this.providersService.getProvidersByService(this.selectedService.bookingSystemId)
            .subscribe(providers => {
              this.providerList = providers;
              this.isLoading = false;
            })
        );
      }
    } else {
      //this.isLoading = false;

    }
    // for now assume there's only injection training, 
  }

  public selectProvider(provider: Provider) {
    this.selectedProvider = provider;
    if (this.stepperList.length === 0) {
      this.stepperList.push(this.selectedProvider.firstName)
    } else {
      this.stepperList.splice(0, 1, this.selectedProvider.firstName)
    }
  }

  public showServicesForProvider(provider: Provider) {
    this.selectedProvider = provider;
    console.log(this.virtualServiceList)
    if (this.stepperList.length === 0) {
      this.stepperList.push(this.selectedProvider.firstName);
      this.stepperList.push(this.virtualServiceList[0].displayName[0].value)
    } else {
      this.stepperList.splice(0, 1, this.selectedProvider.firstName)
    }
    this.displayFirstSection = "provider-services-container-content-providers-description-first-section d-none";
    this.displaySecondSection = "provider-services-container-content-providers-description-second-section";
    this.defaultServiceDescription = `To schedule an ${this.selectedService?.displayName[0].value} appointment with ${provider.firstName}, please click the Schedule Time button below.`;
  }

  backClick() {
    this.onPreviousBookingStep.emit();
  }

  public completeBookingStep() {
    if (this.selectedProvider) {
      this.onSelectionComplete.emit(this.stepperList)
      this.onBookingStepCompleted.emit({ provider: this.selectedProvider, service: this.selectedService });
    } else {
      alert("Please select a provider!")
    }
  }

  public completeBookingStepProvider() {
    if (this.selectedProvider) {
      this.onSelectionComplete.emit(this.stepperList)
      this.onBookingStepCompleted.emit({ provider: this.selectedProvider, service: this.virtualServiceList[0] });
    } else {
      alert("Please select a provider!")
    }
  }
}

