<div class="d-none d-sm-block d-md-block d-lg-block d-xl-block">
  <div class="row ">
    <div class="col-md-12">
      <div class="booking-confirmed-login-container">
        <div class="booking-confirmed-login">
          <div class="row">
            <div class="col-md-5 order-last order-sm-first order-md-first order-lg-first order-xl-first">
              <h3 class="head mb-4">{{'VIRTUALCARE.SCHEDULING_PUBLIC.SPECIAL_INSTRUCTIONS.BOOKING_DETAILS'|translate}}
              </h3>
              <app-booking-details [selectedProvider]="selectedProvider" [selectedService]="selectedService"
                [selectedTimeslot]="selectedTimeslot" [logo]="logo"></app-booking-details>
            </div>
            <div class="col-md-7">
              <div class="complete-registration">
                <h3 class="head mb-4"> {{'VIRTUALCARE.LOGIN.LOGIN_TO_BAYSHORE'|translate}}</h3>
                <p>{{'VIRTUALCARE.LOGIN.LOGIN_CONTENT'|translate}}</p>
                <div class="row">
                  <div class="col-md-8">
                    <div class="form">
                      <div class="row">
                        <div class="col-12">
                          <div *ngIf="isError" class="alert alert-danger">
                            <div>Email id is already registered. Please login</div>
                          </div>
                          <form class="form" (submit)="onLogin($event)" novalidate #form>
                            <div class="login-form">
                              <!-- email -->
                              <div class="login-form-section">
                                <label for="email">{{'VIRTUALCARE.LOGIN.USERNAME' | translate}}</label>
                                <input name="username" class="login-input email-icon" [(ngModel)]="loginInfo.username"
                                  [placeholder]="'VIRTUALCARE.LOGIN.USERNAME' | translate" type="text"
                                  [ngClass]="{'error': passwordError}" />
                                <div class="login-form-error" *ngIf="usernameError">
                                  <span class="login-form-error--msg">{{usernameError | translate}}</span>
                                </div>
                              </div>

                              <!-- password -->
                              <div class="login-form-section">
                                <label for="password">{{'VIRTUALCARE.LOGIN.PASSWORD' | translate}}</label>
                                <div class="input-icon-wrap">
                                  <input name="password" class="login-input pass-icon" [(ngModel)]="loginInfo.password"
                                    [placeholder]="'VIRTUALCARE.LOGIN.PASSWORD' | translate" type={{visibilityValue}}
                                    [ngClass]="{'error': passwordError}" />
                                  <span class="input-icon visibility" (click)="visibilityPassword()">
                                    <span class="material-icons">visibility</span>
                                  </span>
                                </div>
                                <div class="login-form-error" *ngIf="passwordError">
                                  <span class="login-form-error--msg">{{passwordError | translate}}</span>
                                </div>
                              </div>
                              <div class="login-form-section">
                                <app-user-consent *ngIf="hasConsent" (onConsent)="getConsentValue($event)" [hasTerms]="false" [isFrenchProgram]="isFrenchProgram"></app-user-consent>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12">
                                <button class="back" type="button"
                                  (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
                                <button type="submit" class="login mt-3 mb-3" class="login" 
                                  [class.disabled]="!consentChecked || isLoading"
                                  [disabled]="!consentChecked || isLoading">
                                  <img src="/assets/images/spinner-small.svg" *ngIf="isLoading"
                                    style="width:32px; height:32px" />
                                  {{'VIRTUALCARE.LOGIN.LOGIN' | translate}}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="form" *ngIf="!isLoading" id="register-link">
                        <label class="form-check-label" for="gridCheck" (click)="registerClick()">
                          {{'VIRTUALCARE.LOGIN.REGISTER_MSG' | translate}}
                          <span class="link">{{'VIRTUALCARE.REGISTRATION.REGISTER' | translate}}</span>
                          now
                        </label>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <img src="/assets/images/mbc.png" class="w-100 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Mobile-->
<div class="d-block d-sm-none d-md-none d-lg-none d-xl-none">
  <div class="card mobile-login">
    <div class="card-header">
      <div class="row">
        <div class="col-6">
          <h3>{{'VIRTUALCARE.LOGIN.LOGIN' | translate}}</h3>
        </div>
      </div>
    </div>
    <form class="form" (submit)="onLogin($event)" novalidate #form>
      <div class="card-body">
        <div class="row ">
          <div class="col-md-12">
            <div class="booking-confirmed-login-container">
              <div class="booking-confirmed-login">
                <div class="row">
                  <div class="col-md-7">
                    <div class="complete-registration">
                      <p>{{'VIRTUALCARE.LOGIN.LOGIN_CONTENT'|translate}}</p>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form">
                            <div class="login-form">
                              <!-- email -->
                              <div class="login-form-section">
                                <label for="username">{{'VIRTUALCARE.LOGIN.USERNAME' | translate}}</label>
                                <input name="username" class="login-input email-icon" [(ngModel)]="loginInfo.username"
                                  [placeholder]="'VIRTUALCARE.LOGIN.USERNAME' | translate" type="text"
                                  [ngClass]="{'error': passwordError}" />
                                <div class="login-form-error" *ngIf="usernameError">
                                  <span class="login-form-error--msg">{{usernameError | translate}}</span>
                                </div>
                              </div>

                              <!-- password -->
                              <div class="login-form-section">
                                <label for="password">{{'VIRTUALCARE.LOGIN.PASSWORD' | translate}}</label>
                                <div class="input-icon-wrap">
                                  <input name="password" class="login-input pass-icon" [(ngModel)]="loginInfo.password"
                                    [placeholder]="'VIRTUALCARE.LOGIN.PASSWORD' | translate" type={{visibilityValue}}
                                    [ngClass]="{'error': passwordError}" />
                                  <span class="input-icon visibility" (click)="visibilityPassword()">
                                    <span class="material-icons">visibility</span>
                                  </span>
                                </div>
                                <div class="login-form-error" *ngIf="passwordError">
                                  <span class="login-form-error--msg">{{passwordError | translate}}</span>
                                </div>
                              </div>

                              <div class="login-form-section">
                                <app-user-consent *ngIf="hasConsent" (onConsent)="getConsentValue($event)" [hasTerms]="false" [isFrenchProgram]="isFrenchProgram"></app-user-consent>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer mob-footer">
        <div class="row">
          <div class="col-md-12">
            <!-- <button class="back float-right">Back</button> -->
            <!-- <button class="mob-login float-right" (click)="login()">Login </button> -->
            <button type="submit" class="mob-login float-right" [class.disabled]="!consentChecked || isLoading"
            [disabled]="!consentChecked || isLoading">
              <img src="/assets/images/spinner-small.svg" *ngIf="isLoading" style="width:32px; height:32px" />
              {{'VIRTUALCARE.LOGIN.LOGIN' | translate}}
            </button>
            <button class="back float-right"
              (click)="backClick()">{{'VIRTUALCARE.BAYSHORE_SERVICE.BACK'|translate}}</button>
          </div>
          <div class="col-md-12 register">
            <label class="form-check-label mt-3" for="gridCheck">
              {{'VIRTUALCARE.LOGIN.REGISTER_MSG' | translate}}
              <span class="link" (click)="registerClick()">{{'VIRTUALCARE.REGISTRATION.REGISTER' | translate}}</span>

              <!-- <a>REGISTER </a>  -->
              now
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
