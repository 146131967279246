<!-- Stepper Component Goes Here -->
<app-stepper [bookingSteps]="bookingStepsConfig" [isBackButton]="currentBookingStep !== bookingStepsConfig[0].step"
  [count]="mobileStepper.count" [step]="mobileStepper.text" [bookingDetails]="bookingDetails" [showHeader]="showHeader"
  (back)="previousStep($event)">
</app-stepper>
<!-- Content Goes Here -->
<div [ngSwitch]="currentBookingStep">
  <div *ngSwitchCase="bookingStepsEnum.chooseService">
    <app-bayshore-services [virtualServices]="virtualServices" [isLoading]="isLoading"
      (onBookingStepCompleted)="serviceSelected($event)" (onSelectionComplete)="bookingStatusUpdate($event)"
      [nextStepName]="nextStepName" [serviceGroup]="selectedService" [displayCost]="false" [logo]="logo"
      [isInternal]="isInternal">
    </app-bayshore-services>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.selectTime">
    <app-timeslot-selection [selectedService]="selectedService" (onPreviousBookingStep)="previousStep($event)"
      (onSelectionComplete)="bookingStatusUpdate($event)" (onBookingStepCompleted)="timeSelected($event)" [logo]="logo">
    </app-timeslot-selection>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.specialInstructions">
    <app-special-instruction (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="specialInstructionsEntered($event)" [selectedService]="selectedService"
      [selectedTimeslot]="selectedTimeSlot" [logo]="logo">
    </app-special-instruction>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.loginRegister">
    <app-registration [isDuplicate]="hasErrorBooking" [isLoading]="isLoading"
      (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="registrationEntered($event)"
      [selectedService]="selectedService" [selectedTimeslot]="selectedTimeSlot"
      (onSwitchHasAccount)="switchLoginRegister($event)" *ngIf="!hasAccount" [logo]="logo">
    </app-registration>

    <app-login (onSwitchHasAccount)="switchLoginRegister($event)" (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="registrationEnteredExisting($event)" *ngIf="hasAccount"
      [selectedService]="selectedService" [selectedTimeslot]="selectedTimeSlot" [instructions]="specialInstructions"
      [logo]="logo">
    </app-login>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.completed">
    <app-success-message [userName]="bookingCompletedUserName" [confirmation]="bookingCompletedConfirmation"
      [successMessage]="bookingCompletedSuccessMessage">
    </app-success-message>
  </div>
</div>