import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Moment } from 'moment';
import { VirtualcareService } from '../../../services/virtualcare.service';

@Component({
  selector: 'app-special-instruction',
  templateUrl: './special-instruction.component.html',
  styleUrls: ['./special-instruction.component.sass']
})
export class SpecialInstructionComponent implements OnInit {

  careManagerDetails: any = {}

  selectedTime = "";
  selectedDay = "";
  serviceName = "";
  @Input() selectedProvider: any;
  @Input() selectedService: any;
  @Input() selectedTimeslot: Moment;
  @Input() logo: string;
  @Input() isLoading: boolean=false;

  public notes: string;
  @Output("next") next: EventEmitter<any> = new EventEmitter();
  @Output("back") back: EventEmitter<any> = new EventEmitter();
  @Output()
  public onPreviousBookingStep: EventEmitter<any> = new EventEmitter();
  @Output()
  public onBookingStepCompleted: EventEmitter<any> = new EventEmitter();

  constructor(
    private _virtualservice: VirtualcareService
  ) { }

  ngOnInit(): void {
    // this._virtualservice.getCareManager().subscribe(data => {
    //   this.careManagerDetails = data
    // })
  }
  nextClick() {
    this.onBookingStepCompleted.emit(this.notes);
    this.next.emit(3);
  }

  backClick() {
    this.onPreviousBookingStep.emit();
    this.back.emit(3);
  }
}

