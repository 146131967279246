import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Language } from '../../enums/language.enum';
import { LanguageService } from '../../services/language.service';


@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.sass']
})
export class LanguageToggleComponent implements OnInit {

  public language: Language;
  public Language = Language;
  showSkip: boolean = false;
  
  @Input()
  @HostBinding('class.bs-inverted')
  public inverted: boolean = false;
  
  constructor(
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.language = <Language>this.languageService.getLanguage();
    console.log('language: ' + this.language);
  }

  changeLanguage() {
    const newLanguage = this.language === Language.English ? Language.French : Language.English;
    this.languageService.setLanguage(newLanguage);
    this.language = <Language>this.languageService.getLanguage();
  }
}
