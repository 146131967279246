import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'src/app/base.component';
import { BookingStep } from 'src/app/enum/booking-step.enum';
import { VirtualServiceType } from 'src/app/enum/virtual-service-type.enum';
import { VirtualServiceBookingRequest } from 'src/app/model/virtual-service-booking-request';
import { IVirtualservice } from 'src/app/model/virtualservice';
import { VirtualcareService } from 'src/app/services/virtualcare.service';
import { AppointmentCancelComponent } from '../../components/appointment-cancel/appointment-cancel.component';
import * as moment from 'moment';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.sass']
})
export class ServiceListComponent  extends BaseComponent implements  OnInit {

  serviceList: any[] = [];

  public bookingStepsConfig: Array<{ step: BookingStep, title: string, class: string }> = [
    { step: BookingStep.chooseService, title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_SERVICE", class: "current" },
    { step: BookingStep.selectTime, title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", class: "notdone" },
    { step: BookingStep.specialInstructions, title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", class: "notdone" },
    { step: BookingStep.loginRegister, title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", class: "notdone" },
  ];
  public selectedService: IVirtualservice;
  public modalRef: BsModalRef;
  public isCancel: boolean = false;
  public cancelSignature: string;
  public isLoading: boolean = false;
  public isCancelled: boolean = false;
  public selectedAppointmentId: string;
  public selectedTimeslot: string;


  constructor(private router: Router,private modalService: BsModalService,  private route: ActivatedRoute, private _virtualservice: VirtualcareService) { super();}

  ngOnInit(): void {
    this.serviceList = [
      { serviceName: 'Pharmacy', logo: 'assets/images/waves-pharmacy.svg', bookingLink: '/virtual-care/pharmacy/schedule-appointment' },
      { serviceName: 'Bayshore Express', logo: 'assets/images/waves-bayshore.svg', bookingLink: '/virtual-care/public/bayshore-express/schedule-appointment' },
      { serviceName: 'Home Care', logo: 'assets/images/waves-home.svg', bookingLink: '/virtual-care/public/home-care/schedule-appointment' }
    ];

    this.route.queryParams.subscribe(param => {
      if (param['signature']) {
        this.isCancel = true;
        this.cancelSignature = param['signature'];

        this.loadRescheduleData();
      }
    });
  }
  
  goToService(service: any) {
    this.router.navigate([service.bookingLink], { queryParams: { internal: true } })
  }

  openModal(hasError:boolean,errorMessage:string) {
    const initialState: ModalOptions = {
      initialState: {
        selectedService:this.selectedService,
        selectedServiceName: this.selectedService?.bookingSystemName,
        selecetdDate: this.selectedTimeslot,
        selectedAppointmentId : this.selectedAppointmentId,
        cancelSignature : this.cancelSignature,
        hasError:hasError,
        errorMessage:errorMessage
      },
      id: 1,
      class: 'modal-lg cancel-modal modal-dialog-centered mt-100',
      backdrop:'static', 
    };
    this.modalRef = this.modalService.show(AppointmentCancelComponent,initialState);
  }

  private loadRescheduleData() {
    this.isLoading = true;
      this._virtualservice.getGuestUserAppointmentData(this.cancelSignature
      ).subscribe(data => {
        console.log('data from reschedule', data);
        let serviceData: IVirtualservice = {
          _id: data.appointment._id,
          bookingSystemName: data.appointment.serviceName,
          createdDate: data.appointment.createdDate,
          bookingSystemId: data.appointment.appointmentTypeId,
          displayName: data.appointment.serviceName,
          description: [],
          isAvailable: true,
          isRegistrationRequired: true,
          icon: '',
          bookingPath: '',
          groupName: '',
          duration: data.service.duration,
          price: 10,
          serviceType: VirtualServiceType.PROVIDER_INITIATED,
          categoryType: '',
          category: ''
        };
        this.selectedAppointmentId = data.appointment.appointmentId;
        this.selectedTimeslot = moment(data.appointment.startDate).format('LLL');
       
        this.selectedService = serviceData;
        this.isLoading = false;
        this.openModal(false,'');

      }, error => {
        this.openModal(true,error.error);

        this.isLoading = false;
      })
    
  }

}
