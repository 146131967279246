import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { IVirtualservice } from 'src/app/model/virtualservice';
import { VirtualcareService } from 'src/app/services/virtualcare.service';

@Component({
  selector: 'app-appointment-cancel',
  templateUrl: './appointment-cancel.component.html',
  styleUrls: ['./appointment-cancel.component.sass']
})
export class AppointmentCancelComponent implements OnInit {

  @Output()
  public exit: EventEmitter<any> = new EventEmitter();

  @Output() onCancel = new EventEmitter<string>();

  public cancelReason: string;
  public reasonFlag: boolean = false;
  public cancelReasonError:boolean=false;
  public formSubmitted:boolean=false;
  public isCancelled:boolean=false;
  public hasError: boolean | any;
  public errorMessage : string | any;
  public selecetdServiceName : string | any;
  public selecetdDate : string | any;
  public cancelSignature: string | any;
  public selectedAppointmentId: string | any;
  public cancelErrorMessage: string;
  
  constructor(public bsModalRef: BsModalRef, public options: ModalOptions, private _virtualservice: VirtualcareService) { }

  ngOnInit(): void {
    this.hasError = this.options.initialState.hasError;
    this.errorMessage = this.options.initialState.errorMessage;
    this.selecetdServiceName = this.options.initialState.selectedServiceName;
    this.selecetdDate = this.options.initialState.selecetdDate;
    this.cancelSignature = this.options.initialState.cancelSignature;
    this.selectedAppointmentId = this.options.initialState.selectedAppointmentId;

  }

  onClick() {
    this.exit.emit();
  }
  showReason() {
    this.reasonFlag = true;
  }
  submitCancel(){
    this.formSubmitted = true;

    if(!this.cancelReason){
      this.cancelReasonError=true;
      return;
    }
    this._virtualservice.cancelVirtualEvent(this.selectedAppointmentId,this.cancelSignature, this.cancelReason).subscribe(data => {
      this.isCancelled = true;
          
      setTimeout(() => {
        this.exit.emit();

      }, 3000);
    }, error => {
      this.cancelErrorMessage = error.error.message;
      console.log('cancel error',error)
    })
    

  }
  
}
