<app-stepper [bookingSteps]="bookingStepsConfig" [isBackButton]="currentBookingStep !== bookingStepsConfig[0].step"
  [count]="mobileStepper.count" [step]="mobileStepper.text" [bookingDetails]="bookingDetails" [showHeader]="showHeader"
  (back)="previousStep($event)">
</app-stepper>
<!-- Content Goes Here -->
<div [ngSwitch]="currentBookingStep">
  <div *ngSwitchCase="bookingStepsEnum.chooseService">
    <app-bayshore-services [virtualServices]="virtualServices" [serviceType]="virtualServiceType" [isIcs]="isIcs"
      [isLoading]="isLoading" [displayCost]="displayCost" [nextStepName]="nextBookingStepConfig.title"
      (onBookingStepCompleted)="serviceSelected($event)" (onSelectionComplete)="bookingStatusUpdate($event)">
    </app-bayshore-services>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.chooseProvider">
    <app-provider-selection [providerList]="virtualServiceProviders" [virtualServiceList]="virtualServices"
      [isLoading]="isLoading" (onBookingStepCompleted)="providerSelected($event)"
      [selectedService]="selectedService ? selectedService : null" (onPreviousBookingStep)="previousStep($event)"
      (onSelectionComplete)="bookingStatusUpdate($event)">
    </app-provider-selection>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.selectTime">
    <app-timeslot-selection [selectedService]="selectedService" [selectedProvider]="selectedProvider"
      (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="timeslotSelected($event)"
      (onSelectionComplete)="bookingStatusUpdate($event)">
    </app-timeslot-selection>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.specialInstructions">
    <app-special-instruction (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="specialInstructionsEntered($event)" [selectedProvider]="selectedProvider"
      [selectedService]="selectedService" [selectedTimeslot]="selectedTimeslot">
    </app-special-instruction>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.loginRegister">
    <app-registration (onSwitchHasAccount)="switchLoginRegister($event)" [isDuplicate]="hasErrorBooking"
      [isLoading]="isLoading" [allowLogin]="hasTerms" [style.display]="hasAccount ? 'none' : 'block'"
      (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="registrationEntered($event)"
      [selectedProvider]="selectedProvider" [selectedService]="selectedService" [isFrenchProgram]="isFrenchProgram" [selectedTimeslot]="selectedTimeslot" [hasConsent]="hasConsent">
    </app-registration>

    <app-login (onSwitchHasAccount)="switchLoginRegister($event)" [isLoading]="isLoading" [allowLogin]="true"
      (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="registrationEntered($event)"
      *ngIf="hasAccount" [selectedProvider]="selectedProvider" [selectedService]="selectedService" [isFrenchProgram]="isFrenchProgram"
      [selectedTimeslot]="selectedTimeslot" [instructions]="specialInstructions" [hasConsent]="hasConsent">
    </app-login>

  </div>
  <div *ngSwitchCase="bookingStepsEnum.completed">
    <app-success-message [userName]="bookingCompletedUserName" [confirmation]="bookingCompletedConfirmation"
      [successMessage]="bookingCompletedSuccessMessage" [providerFlow]="isProvider"
      [providerName]="selectedProvider.firstName">
    </app-success-message>
  </div>
</div>
