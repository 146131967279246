<!-- Stepper Component Goes Here -->
<app-stepper [bookingSteps]="bookingStepsConfig" [isBackButton]="currentBookingStep !== bookingStepsConfig[0].step"
  [count]="mobileStepper.count" [step]="mobileStepper.text" [bookingDetails]="bookingDetails" [showHeader]="showHeader"
  (back)="previousStep($event)">
</app-stepper>
<!-- Content Goes Here -->
<div [ngSwitch]="currentBookingStep">
  <div *ngSwitchCase="bookingStepsEnum.chooseService">
    <app-bayshore-services [virtualServices]="virtualServices" [serviceType]="serviceTypeEnum" [isLoading]="isLoading"
      (onBookingStepCompleted)="serviceSelected($event)" (onSelectionComplete)="bookingStatusUpdate($event)"
      [logo]="logo" [isInternal]="isInternal">
    </app-bayshore-services>
  </div>

  <div *ngSwitchCase="bookingStepsEnum.chooseProvider">
    <app-provider-selection [selectedService]="selectedService" (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="providerSelected($event)" (onSelectionComplete)="bookingStatusUpdate($event)"
      [logo]="logo">
    </app-provider-selection>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.selectTime">
    <app-timeslot-selection [selectedService]="selectedService" [selectedProvider]="selectedProvider"
      (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="timeslotSelected($event)"
      *ngIf="selectedProvider" (onSelectionComplete)="bookingStatusUpdate($event)" [logo]="logo">
    </app-timeslot-selection>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.specialInstructions">
    <app-special-instruction (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="specialInstructionsEntered($event)" [selectedProvider]="selectedProvider"
      [selectedService]="selectedService" [selectedTimeslot]="selectedTimeslot" [logo]="logo">
    </app-special-instruction>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.loginRegister">
    <app-registration [allowLogin]="false" [isDuplicate]="hasErrorBooking" [allowLogin]="true" [isLoading]="isLoading"
      (onSwitchHasAccount)="switchLoginRegister($event)" (onPreviousBookingStep)="previousStep($event)"
      (onBookingStepCompleted)="registrationEnteredNew($event)" [style.display]="hasAccount ? 'none' : 'block'"
      [selectedProvider]="selectedProvider" [selectedService]="selectedService" [selectedTimeslot]="selectedTimeslot"
      [logo]="logo">
    </app-registration>

    <app-login (onSwitchHasAccount)="switchLoginRegister($event)" [isLoading]="isLoading"
      (onPreviousBookingStep)="previousStep($event)" (onBookingStepCompleted)="registrationEnteredExisting($event)"
      *ngIf="hasAccount" [selectedProvider]="selectedProvider" [selectedService]="selectedService"
      [selectedTimeslot]="selectedTimeslot" [instructions]="specialInstructions" [logo]="logo">
    </app-login>
  </div>
  <div *ngSwitchCase="bookingStepsEnum.completed">
    <app-success-message [userName]="bookingCompletedUserName" [confirmation]="bookingCompletedConfirmation"
      [successMessage]="bookingCompletedSuccessMessage">
    </app-success-message>
  </div>
</div>
