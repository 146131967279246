import { Component, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../../shared/components/Base/base.component';
import { IVirtualservice } from '../../../../model/virtualservice';
import { VirtualcareService } from '../../../../services/virtualcare.service';
import { BookingStep } from '../../../../enum/booking-step.enum';
import { VirtualServiceType } from '../../../../enum/virtual-service-type.enum';
import { Provider } from '../../../../model/provider';
import { Moment } from 'moment-timezone';
import { ProviderService } from '../../../../services/provider.service';
import { UserBookingInformation } from '../../../../model/user-booking-information';
import { VirtualServiceBookingRequest } from '../../../../model/virtual-service-booking-request';
import waveConfig from '../../../../config/wave.config';
import { IWaveConfig } from '../../../../config/IWaveConfig';
import { IBookingStepConfig } from '../../../../config/IBookingStepConfig';

export interface MobileStepper {
  text: string,
  count: number
};
@Component({
  selector: 'app-provider-schedule-appointment',
  templateUrl: './provider-schedule-appointment.component.html',
  styleUrls: ['./provider-schedule-appointment.component.sass']
})
export class ProviderScheduleAppointmentComponent extends BaseComponent implements OnInit, OnChanges {
  public virtualServiceType = VirtualServiceType.PROVIDER_INITIATED;
  public bookingStepsEnum = BookingStep;
  public initialBookingStep: BookingStep;
  public currentBookingStep: BookingStep;
  public nextBookingStepConfig: IBookingStepConfig;
  public mobileStepperList: MobileStepper[];
  public mobileStepper: MobileStepper;
  public bookingDetails: any[] = [];
  public showHeader: boolean;

  //TODO: assign controls dynamically in the config
  public bookingStepsConfig: Array<IBookingStepConfig> = [
    { step: BookingStep.chooseProvider, title: "VIRTUALCARE.BAYSHORE_SERVICE.CHOOSE_PROVIDER", shortTitle: 'Select Provider', class: "current" },
    { step: BookingStep.selectTime, title: "VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME", shortTitle: 'Select Timeslot', class: "notdone" },
    { step: BookingStep.specialInstructions, title: "VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS", shortTitle: 'Next', class: "notdone" },
    { step: BookingStep.loginRegister, title: "VIRTUALCARE.BAYSHORE_SERVICE.REGISTER", shortTitle: 'Next', class: "notdone" },
    { step: BookingStep.completed, title: "VIRTUALCARE.BAYSHORE_SERVICE.COMPLETED", shortTitle: 'Finish Booking', class: "notdone" }
  ];

  public virtualServices: IVirtualservice[] = [];
  public selectedService: IVirtualservice;
  public virtualServiceProviders: Provider[] = [];
  public selectedProvider: Provider;
  public selectedTimeslot: Moment;
  public specialInstructions: string;
  public bookingCompletedUserName: string;
  public bookingCompletedConfirmation: string;
  public bookingCompletedSuccessMessage: string;

  public isLoading: boolean = false;
  public hasErrorBooking: boolean = false;
  public isFrenchProgram: boolean = false;

  public description = "";
  public serviceName = "";
  public duration: number;
  public serviceId: number;
  public hasTerms : boolean;
  public hasConsent : boolean;
  public isProvider : boolean;
  public hasAccount: boolean = false;
  public isIcs: boolean = false;

  public stepperStatus = "stepper__list__item stepper__list__item--current"

  public programRoute: string;
  public programConfig: IWaveConfig;
  public displayCost: boolean = true;


  constructor(
    public sanitizer: DomSanitizer,
    private virtualCareService: VirtualcareService,
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) { super(); }

  ngOnInit(): void {

    this.isLoading = true;
    this.showHeader = true;
    this.programRoute = this.route.snapshot.params.programRoute;
    if (this.programRoute) {
      if (!this.loadProgramConfig(this.programRoute)) {

        this.router.navigate(['/virtual-care/provider']);
      }
    }

    this.mobileStepperList = this.bookingStepsConfig.map((item, index) => {
      return {
        count: index + 1,
        text: item.title
      }
    })

    this.mobileStepper = this.mobileStepperList[0];

    this.loadInitialStep();

  }

  ngOnChanges() {
    this.mobileStepper = this.mobileStepperList[0];
  }

  public bookingStatusUpdate(event) {
    if (this.bookingDetails.length === 0) {
      this.bookingDetails = event;
    } else {
      event.forEach(item => this.bookingDetails.push(item))
    }

  }

  public switchLoginRegister(event) {
    this.hasAccount = event;
  }

  public getNextStepShortTitle() {
    const currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step === this.currentBookingStep);
    return this.bookingStepsConfig[currentStepIndex + 1].shortTitle;
  }

  private loadProgramConfig(programRoute: string): boolean {

    let isConfigFound = false;
    this.programConfig = waveConfig.find(c => c.route === programRoute);

    try {
      this.hasTerms = this.programConfig.bookingStepsConfig.hasTerms ? this.programConfig.bookingStepsConfig.hasTerms : false;
      this.isProvider = this.programConfig.bookingStepsConfig.isProvider ? this.programConfig.bookingStepsConfig.isProvider : true;
      this.hasConsent = this.programConfig.bookingStepsConfig.hasConsent ? true : false;
      this.isIcs = this.programConfig.bookingStepsConfig.isIcs ? true : false;
    } catch (error) {
      this.hasTerms = false;
      this.hasConsent = false;
      this.isProvider = true;
    }

    if (this.programConfig) {
      this.bookingStepsConfig = this.programConfig.bookingStepsConfig?.stepOrder?.map(r => {
        const c: IBookingStepConfig = {
          step: r.step as BookingStep,
          title: r.title,
          shortTitle: r.shortTitle,
          class: r.class
        };
        return c;
      });

      this.displayCost = this.programConfig.paymentConfig.collectPayment;

    }
    if (this.programConfig && this.bookingStepsConfig) {
      isConfigFound = true;
    }


    return isConfigFound;
  }

  private loadInitialStep() {

    if (this.bookingStepsConfig?.length > 0) {
      this.initialBookingStep = this.currentBookingStep = this.bookingStepsConfig[0].step;
      this.nextBookingStepConfig = this.bookingStepsConfig[1];

      this.loadStepData(this.bookingStepsConfig[0].step);

    }
  }



  setServiceSession(virtual: IVirtualservice) {
    this.serviceId = +virtual.bookingSystemId;
    this.description = virtual.description[0].value;
    this.serviceName = virtual.displayName[0].value;
    this.duration = +virtual.duration;
  }

  serviceSelected(selectedService: IVirtualservice) {

    this.selectedService = selectedService;
    this.setServiceSession(selectedService);
    this.next();
  }

  public providerSelected(selectedProvider: { provider: Provider, service: IVirtualservice }) {
    // console.log('provider:' + JSON.stringify(selectedProvider));
    this.selectedProvider = selectedProvider.provider;
    if (!this.selectedService) {
      this.selectedService = selectedProvider.service;
      this.setServiceSession(selectedProvider.service);
    }

    this.next();
  }

  public timeslotSelected(selectedTimeslot: Moment) {
    this.selectedTimeslot = selectedTimeslot;
    this.next();
  }

  public specialInstructionsEntered(specialInstructions: string) {
    this.specialInstructions = specialInstructions;
    this.next();
  }

  public registrationEntered(userRegistration: UserBookingInformation) {
    this.isLoading = true;

    const bookingRequest: VirtualServiceBookingRequest = new VirtualServiceBookingRequest(
      userRegistration,
      Number(this.selectedService.bookingSystemId),
      this.selectedTimeslot.format(),
      this.specialInstructions
    );

    this.bookingCompletedUserName = userRegistration.firstName;
    // this.bookingCompletedUserName = this.selectedProvider.firstName.toUpperCase();
    this.bookingCompletedSuccessMessage = "VIRTUALCARE.SUCCESS.PROVIDER_SUCCESS";
    this.bookingCompletedConfirmation = "VIRTUALCARE.SUCCESS.PROVIDER_CONFIRM";

    this.addSubscription(
      this.virtualCareService.createVirtualEventForProvider(
        this.selectedProvider.id,
        bookingRequest
      ).subscribe(data => {
        this.isLoading = false;
        this.showHeader = false;
        this.next();

      }, error => {
        this.isLoading = false;
        switch (error.status) {
          case 409:
            this.hasErrorBooking = true;
            break;
          default:
            this.hasErrorBooking = true;

        }
      })
    );
  }

  public removeSelectedStepData(currentStep: BookingStep, previousStep: BookingStep) {
    switch (previousStep) {
      case this.bookingStepsEnum.chooseService:
        this.selectedService = undefined;
        this.selectedProvider = undefined;
        this.selectedTimeslot = undefined;
        break;
      case this.bookingStepsEnum.chooseProvider:
        this.selectedProvider = undefined;
        if (previousStep === this.bookingStepsConfig[0].step) {
          this.selectedService = undefined;
        }
        this.selectedTimeslot = undefined;
        break;
      case this.bookingStepsEnum.selectTime:
        this.selectedTimeslot = undefined;
        break;
      case this.bookingStepsEnum.specialInstructions:
        this.specialInstructions = undefined;
        break;

      default:
        break;
    }
  }

  public previousStep(event?) {
    let currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step == this.currentBookingStep);

    if (currentStepIndex == -1) {
      console.log('not found');
      return;
    }

    this.nextBookingStepConfig = this.bookingStepsConfig[1];

    const s = this.mobileStepperList.findIndex(f => f === this.mobileStepper);

    if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.INSTRUCTIONS') {
      const l = this.bookingDetails.length;
      this.bookingDetails.splice(l - 2, 2);
    } else if (this.mobileStepper.text === 'VIRTUALCARE.BAYSHORE_SERVICE.SELECT_TIME') {
      if (s === 2) {
        this.bookingDetails.pop();
      } else {
        this.bookingDetails = [];
      }
    }

    this.mobileStepper = this.mobileStepperList[s - 1];
    // make the current step not done
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "notdone";
    const step = this.currentBookingStep;

    currentStepIndex = currentStepIndex - 1;
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex].step;
    this.bookingStepsConfig.find(c => c.step === this.currentBookingStep).class = "current";
    this.removeSelectedStepData(step, this.currentBookingStep);

    this.isLoading = false;
  }

  next(currentStep?: BookingStep) {
    const currentStepIndex = this.bookingStepsConfig.findIndex(s => s.step === this.currentBookingStep);
    this.bookingStepsConfig[currentStepIndex].class = "active";
    this.bookingStepsConfig[currentStepIndex + 1].class = "current";
    this.currentBookingStep = this.bookingStepsConfig[currentStepIndex + 1].step;
    this.nextBookingStepConfig = this.bookingStepsConfig[currentStepIndex + 2];
    const s = this.mobileStepperList.findIndex(f => f === this.mobileStepper)
    this.mobileStepper = this.mobileStepperList[s + 1];

    this.loadStepData(this.currentBookingStep);

  }

  private loadStepData(bookingStep: BookingStep) {

    switch (bookingStep) {
      case this.bookingStepsEnum.chooseService:

        this.isLoading = true;
        this.addSubscription(
          this.virtualCareService.getVirtualServices(this.virtualServiceType, this.programConfig?.program).subscribe(data => {
            this.isLoading = false;
            this.virtualServices = data;

          },
            error => {
              this.isLoading = false;
            })
        );
        break;
      case this.bookingStepsEnum.chooseProvider:
        this.isLoading = true;
        if (this.selectedService) {
          this.addSubscription(
            this.providerService.getProvidersByService(this.selectedService.bookingSystemId)
              .subscribe(providers => {
                this.virtualServiceProviders = providers;

                if (!this.virtualServices || this.virtualServices.length === 0) {
                  this.addSubscription(
                    this.virtualCareService.getVirtualServices(this.virtualServiceType, this.programConfig?.program)
                      .subscribe(services => {
                        this.isLoading = false;
                        this.virtualServices = services;
                      },
                        error => {
                          this.isLoading = false;
                        })
                  );
                }
                this.isLoading = false;
              },
                error => {
                  this.isLoading = false;
                })
          );
        } else {
          this.addSubscription(
            this.providerService.getProvidersByServiceType(this.virtualServiceType, this.programConfig?.program)
              .subscribe(providers => {
                this.virtualServiceProviders = providers;

                if (!this.virtualServices || this.virtualServices.length === 0) {
                  this.addSubscription(
                    this.virtualCareService.getVirtualServices(this.virtualServiceType, this.programConfig?.program)
                      .subscribe(services => {
                        this.isLoading = false;
                        this.virtualServices = services;
                      },
                        error => {
                          this.isLoading = false;
                        })
                  );
                } else {
                  this.isLoading = false;
                }
              },
                error => {
                  this.isLoading = false;
                })
          );
        }
        break;
      case this.bookingStepsEnum.selectTime:

        break;
      case this.bookingStepsEnum.specialInstructions:

        break;
      case this.bookingStepsEnum.loginRegister:
        this.isFrenchProgram = this.checkFrenchProgram()? true:false;
        break;
      case this.bookingStepsEnum.completed:

      default:
        break;
    }
  }

  private checkFrenchProgram = () => {
    if(!this.programConfig.bookingStepsConfig.frenchPrograms || !this.selectedService.bookingSystemName){
      return false;
    }
    return this.programConfig.bookingStepsConfig.frenchPrograms.find(
      (frenchPrograms) => frenchPrograms.replace(/\s/g, '') === this.selectedService.bookingSystemName.replace(/\s/g, '')
    );
  }
}
