import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.sass']
})
export class AppFooterComponent implements OnInit {

  public currentYear: number = new Date().getFullYear();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToPrivacy(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.router.navigate(['/terms-and-conditions']);
  }

}
